// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../../packages/framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");

export const baseURL = require("../../../framework/src/config.js").baseURL;

export type DynamicFormElement = HTMLElement & {
  dynamicFieldList: any[];
  record: any;
  generalFormSettings: any;
  urlMapping: Record<string, string>;
  highlightInvalidFields: () => void;
  currentFormData: any;
  currentValidationStatus: "VALID" | "INVALID";
};

export interface Props extends WithStyles<any> {}

const url =
  "https://vitumarketplace1-511976-ruby.b511976.dev.eastus.az.svc.builder.cafe";
interface S {
  validationStatus: boolean;
  record: any;
  modalVisible: boolean;
  dynamicFieldMap: any;
  reviewResponseMap: any;
  validationEnabled: boolean;
}

interface SS {}

export default class AutoFormController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
      validationStatus: false,
      record: null,
      modalVisible: false,
      dynamicFieldMap: null,
      reviewResponseMap: null,
      validationEnabled: false
    };
    this.formContainerRef = { current: null };
    this.formRef = { current: null };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  subScribedMessages = [
    getName(MessageEnum.AccoutLoginSuccess),
    getName(MessageEnum.RestAPIResponceMessage),
  ];

  formContainerRef: React.MutableRefObject<HTMLFormElement | null>;
  formRef: React.MutableRefObject<DynamicFormElement | null>;

  urlOverrides = {
    "/Container/CountiesJson":
      url + "/bx_block_integrationwithapi1/api_integrations/get_counties",
    "/Container/ZipDecoderJson":
      url +
      "/bx_block_integrationwithapi1/api_integrations/get_container_zip_decoder",
    "/Interstate/ValidateAddress":
      url +
      "/bx_block_integrationwithapi1/api_integrations/get_validate_address",
    "/Interstate/MakeByContainsJson":
      url +
      "/bx_block_integrationwithapi1/api_integrations/get_make_by_contain",
    "/Interstate/MoCityTaxExist": url + "/Interstate/MoCityTaxExist",
    "/Interstate/VinDecodeJson": url + "/bx_block_posts/deals/decode_vin",
    "/Interstate/ZipDecoderJson":
      url + "/bx_block_integrationwithapi1/api_integrations/get_zip_decoder",
  };

  apiGetFormFields: string = "";
  apiPostReviewFields: string = "";
  apiPostSubmitFields: string = "";

  updateValidationEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      validationEnabled: event.target.checked
    })
  }
  getFormFields = () => {
    const fromState = localStorage.getItem("fromState") || 'CA';
    const toState = localStorage.getItem("toState") || 'LA';
    const webHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetFormFields = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_integrationwithapi1/api_integrations/get_form_fields?from_state=${fromState}&to_state=${toState}&service_type=FormsAndFees`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  reviewFields = () => {
    const webHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostReviewFields = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_integrationwithapi1/api_integrations/review_fields`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    const reviewFieldsData = {
      review_fields: {
        // record: {
          ...this.state.record,
        // },
      },
    };

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(reviewFieldsData)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  };

  onModalSubmit = (formList: any) => {
    this.setState({
      modalVisible: false,
    });

    this.submitFields(formList);
  };

  submitFields = (formList: any) => {
    const submitFieldsData= {
      'submit_fields': {
        'record': {
          ...this.state.record,
          forms: formList
        }
      }
    }
    const webHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostSubmitFields = webRequestMessage.messageId;

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_integrationwithapi1/api_integrations/submit_fields`
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(submitFieldsData)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  onModalCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  onSubmit = () => {
    if (this.state.validationEnabled && !this.state.validationStatus) {
      this.formRef.current?.highlightInvalidFields();
    }
    else {
      this.reviewFields();
    }
  };
  async componentDidMount() {
    this.getFormFields();
  }

  async receive(from: string, message: Message) {
    const apiId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiId === this.apiGetFormFields) {
      const { defaultValuesRecord, fieldList, generalSettings } = response.data;
      this.setState({
        dynamicFieldMap: fieldList,
      });
      const ref = this.formContainerRef;
      const recordChangeHandler = (event: Event) => {
        this.setState({ record: (event as CustomEvent<any>).detail });
      };
      const validationStatusChangeHandler = (event: Event) => {
        const isValid = (event as CustomEvent<any>).detail === "VALID";
        this.setState({ validationStatus: isValid });
      };
      if (ref) {
        const formElement = document.createElement(
          "dynamic-form"
        ) as DynamicFormElement;
        formElement.dynamicFieldList = fieldList;
        formElement.generalFormSettings = generalSettings;
        formElement.record = defaultValuesRecord;
        formElement.urlMapping = this.urlOverrides;

        this.setState({ record: defaultValuesRecord });
        
        formElement.addEventListener("recordChange", recordChangeHandler);
        formElement.addEventListener(
          "validationStatusChange",
          validationStatusChangeHandler
        );

        ref.current!.appendChild(formElement);
        this.formRef.current = formElement;

        return () => {
          this.formRef.current = null;
          formElement.remove();
          formElement.removeEventListener("recordChange", recordChangeHandler);
          formElement.removeEventListener(
            "validationStatusChange",
            validationStatusChangeHandler
          );
        };
      }
    }
    if (apiId === this.apiPostReviewFields) {
      this.setState(
        {
          reviewResponseMap: response.data,
        },
        () => {
          if(this.state.reviewResponseMap.formList){
            this.setState({
              modalVisible: true,
            });
          }
        }
      );
    }
    if (apiId === this.apiPostSubmitFields){
      console.log(response);
    }
  }
}
// Customizable Area End
