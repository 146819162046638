// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";


import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
}

interface S {
 
}

interface SS {
 
}

export default class ConfirmFeesDealerController extends BlockComponent<
  Props,
  S,
  SS
> {
    cardPanelInfos = {
        "ID: 328493": "Blue True",
        "CUSTOMER'S LAST NAME": "Smith",
        STATES: "CA" + " > " + "NY",
        APPLICATION: "Registration",
        'SERVICES': 'Full Service',
        'VEHICLE TYPE': 'Passenger'
    };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    console.log('componentDidMount func');
  }


  async receive(from: string, message: Message) {
    console.log('receive func');
  }
}
// Customizable Area End