import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
  InputBase,
} from "@material-ui/core";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearIcon from '@material-ui/icons/Clear';
// Customizable Area End

import ViewChatController, { configJSON, Props } from "./ViewChatController";

// Customizable Area Start
// Customizable Area End

export default class ViewChat extends ViewChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { state, props } = this;
    const { chatBoxType,chatDetails } = props;
    const { chatfloatingWindowMinimize } = state;
    return (
      <>
        <Box style={chatBoxType === "create_chat" ? webStyles.createChatBoxPreview as React.CSSProperties : webStyles.singleChatBoxPreview as React.CSSProperties}>
          <Box>
            <Box style={webStyles.chatHeadingLayout}>
              <Box>
                <Typography component={'h6'} style={webStyles.subHeadingText}>{chatDetails?.name}</Typography>
                <Typography component={'h6'} style={webStyles.headingText}>{chatDetails?.dealNumber}-{chatDetails?.dealName}</Typography>
              </Box>
              <Box>
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <Grid item >
                      {chatfloatingWindowMinimize ? <IconButton onClick={this.minimizeFloatingWindow} data-test-id="minimize_chat_window">
                        <ExpandMoreIcon style={webStyles.iconButton} />
                      </IconButton> :
                        <IconButton onClick={this.maximizeFloatingWindow} data-test-id="maxmize_chat_window">
                          <ExpandLessIcon style={webStyles.iconButton} />
                        </IconButton>}
                    </Grid>
                    <Grid item >
                      <IconButton onClick={this.closeFloatingChat} data-test-id="close_chat_window">
                        <ClearIcon style={webStyles.iconButton} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box style={webStyles.chatWindowContentLayout as React.CSSProperties}>
            <Box style={{
              position: "absolute",
              bottom: '0px',
              width: '100%'
            }}>
              <Divider />
              <Box style={{ height: '50px', display: 'flex', alignItems: 'center', width: '100%' }}>
                <Grid item xs={12}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid xs={3}>
                      <AttachFileIcon style={{ color: '#C5CBC9', cursor: 'pointer' }} />

                    </Grid>
                    <Grid xs={6}>
                      <InputBase
                        data-test-id="mesage_text"
                        value={this.state.chatMessage}
                        onChange={this.handleMessage}
                        placeholder={configJSON.sendMessagePlaceHolder}
                      />

                    </Grid>
                    <Grid xs={3} >
                      <Grid container justifyContent="flex-end">
                        <IconButton
                        data-test-id="send_message"

                          onClick={this.handleSendMessage}
                        >
                          <SendIcon style={{ color: '#C5CBC9', cursor: 'pointer',marginLeft:'-20px' }} />
                        </IconButton>
                      </Grid>
                    </Grid>

                  </Grid>

                </Grid>
              </Box>
            </Box>

          </Box>

        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  singleChatBoxPreview: {
    position: 'fixed',
    right: "430px",
    width: '420px',
    color: '#fff',
    zIndex: 99,
    bottom: '0px',
    boxShadow: "0px 8px 32px 0px #0000000F"
  },
  createChatBoxPreview: {
    position: 'fixed',
    right: "20px",
    width: '420px',
    color: '#fff',
    zIndex: 99,
    bottom: '0px',
    boxShadow: "0px 8px 32px 0px #0000000F"
  },
  chatHeadingLayout: {
    backgroundColor: "#013D4F",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '10px 10px 0px 0px',
  },
  chatWindowContentLayout: {
    background: '#fff',
    height: '300px',
    overflowY: 'auto',
    padding: "15px 10px",
    position: 'relative',
    overflowX: 'hidden',
    zIndex: 99999
  },
  headingText: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "28px",
    color: '#ffffff'

  },
  subHeadingText: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "28px",
    color: '#ffffff'

  },
  iconButton: {
    color: '#fff'
  },
  messageChatAvatar: {
    width: '57px',
    height: "57px"
  }
};
// Customizable Area End
