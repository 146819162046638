import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import React from "react";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
  dealNumber: string;
  dealName: string;
  userImage: string;
  lastMessageDate: string;
  senderName: string


}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];

    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}

export interface Root {
  chats: Chat[]
  count: number
  message: string
  new_messages: boolean
}

export interface Chat {
  id: number
  name: string
  deal_details: DealDetails
  last_message_details: LastMessageDetails
  service_provider_details: ServiceProviderDetails
  dealership_details: ServiceProviderDetails
  accounts_chats: AccountsChat[]
}

export interface DealDetails {
  deal_id: number
  customer_last_name: string
}

export interface LastMessageDetails {
  message: any
  time: any
  sender_name: any
}

export interface ServiceProviderDetails {
  name: string
  logo: string
}

export interface AccountsChat {
  id: number
  status?: string
  muted: boolean
  unread_count: number
  account_details: AccountDetails
}

export interface AccountDetails {
  account_id: number
  name: string
  role?: string
}




// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  openChats: boolean;
  minimizeChats: boolean;
  selectedChatId: string;
  chatWindow: boolean;
  chatWindowMinimize: boolean;
  selectedChat: IChat
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  getuserMessagesApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      openChats: false,
      minimizeChats: false,
      selectedChatId: "",
      chatWindow: false,
      chatWindowMinimize: false,
      selectedChat: {} as IChat
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async () => {
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllChats
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  searchChats = async () => {
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllChats}?filter_by=${this.state.chatName}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ chatName: event.target.value }, () => {
      if (this.state.chatName === "") {
        this.getChatList()
      } else {
        this.searchChats()
      }
    });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ token }, () => this.getChatList());
      }
    }
    if (apiRequestCallId === this.getChatListApiCallId) {
      const chatList = responseJson.chats;
      const results = chatList.map((item: Chat) => {
        return {
          id: item.id,
          name: item?.service_provider_details?.name || item?.dealership_details?.name || "",
          muted: item.accounts_chats[0].muted,
          unreadCount: item.accounts_chats[0].unread_count,
          lastMessage: item.last_message_details.message,
          dealName: item.deal_details.customer_last_name,
          dealNumber: item.deal_details.deal_id,
          userImage: item?.service_provider_details?.logo || item?.dealership_details?.logo || "",
          lastMessageDate: item.last_message_details.time,
          senderName: item.last_message_details.sender_name

        };
      });
      this.setState({
        chatList: results,
      });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createChatRoomApiCallId.length > 0 &&
      apiRequestCallId === this.createChatRoomApiCallId &&
      responseJson
    ) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList();
    }
  }

  openChats = () => {
    this.setState({ openChats: true }, () => {
      this.openChatsList('chatList')
      this.getChatList()
    })
  }

  closeChats = (windowKey: string) => {
      this.setState({ openChats: false })
  }

  openChatsList = (windowKey: string) => {
      this.setState({ minimizeChats: true })
  }
  closeChatsList = (windowKey: string) => {
      this.setState({ minimizeChats: false, })
  }

  handleSelectChat = (details: IChat) => {
    this.setState({ selectedChatId: details.id, chatWindow: true, chatWindowMinimize: true, selectedChat: details }, () => {
      this.getChatUserData()
    });
  };

  convertformatTime = (timestamp: string) => {
    const now = moment(); // Current time
    const time = moment(timestamp); // Convert timestamp to Moment object
    if (!timestamp) {
      return ''; // Return empty string if date is not available
    }
    if (time.isSame(now, 'day')) {
      return 'Today';
    } else if (time.isSame(now.clone().subtract(1, 'days'), 'day')) {
      return 'Yesterday';
    } else {
      return time.format('MMMM DD, YYYY'); // Format as "January 16, 2025"
    }
  }

  getChatUserData = async () => {
    const token = await getStorageData('authToken')
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getuserMessagesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.userChats}=${this.state.selectedChatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
