import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  formType: string;
  payNowAction?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isAgreed: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  handleChangeAgreeState = (newAgreeState: boolean) => {
    this.setState({isAgreed: newAgreeState})
  }
  navigateToDealDashboard = ()=> {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'ProjectTemplatesDealDashboard');
    this.send(msg);
  }
  navigateToTermsAndConditions = ()=> {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'TermsConditions');
    this.send(msg);
  }

  navigateToAutoForm = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'AutoForm');
    this.send(msg);
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}