import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/core";
import OrderSummaryCalculateRegistrationFeeController, {
  Props
} from "./OrderSummaryCalculateRegistrationFeeController";
import OrderSummary from "./OrderSummary.web";

// Customizable Area End

export class OrderSummaryCalculateRegistrationFee extends OrderSummaryCalculateRegistrationFeeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <OrderSummary id="" navigation={this.props.navigation} formType="calculateRegistration" payNowAction={this.navigateToRegistrationFee}/>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = createStyles({
  container: {}
});
// Customizable Area End

export default withStyles(styles)(OrderSummaryCalculateRegistrationFee);
