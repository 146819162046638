import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  styled,
  Avatar,
  Checkbox,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  // Customizable Area End
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Customizable Area Start
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";
import { dislikeButton, dislikeButtonChecked, emptyCheckbox, filledCheckbox, likeButton, likeButtonChecked, logo } from "./assets";
import {notifications} from "../../../blocks/dashboard/src/assets";
import HamburgerMenuFull from "../../customisableuserprofiles/src/HamburgerMenuFull.web";

interface Styles {
  [key: string]: any;
}

// Customizable Area End

import MultilevelApprovalController, {
  Props,
  EventList,
} from "./MultilevelApprovalController.web";

export default class MultilevelApproval extends MultilevelApprovalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  demoEvent: EventList = {
    id: 1,
    attributes: {
      name: "Annual Tech Conference",
      description: "A conference focusing on the latest in technology and innovation.",
      status: "Scheduled",
      comment: "Registration opens next week."
    }
  };
  unusedComponent = (event : EventList) => {
    return (
      <>
      <Dialog open={false}>
        <IconButton />
        <CloseIcon />
        <Typography>{event}</Typography>
      </Dialog>
      </>
    )
  }
  headerr = () => {
    return (
      <Box sx={webStylesHeader.header}>
        <Box sx={webStylesHeader.headerLeftSide}>
          <HamburgerMenuFull navigation={this.props.navigation} id={""} data-test-id="hamburgerMenuTestId" />
 
          <Box sx={webStylesHeader.logo} style={{marginLeft:'4px'}}>
            <img style={{ marginBottom: '5px', height: '24px', width: '52px' }} src={logo} alt="vitu logo" />
          </Box>
        </Box>
 
        <Box sx={webStylesHeader.headerRightSide}>
          <Box
            data-test-id="notifications"
            sx={webStylesHeader.notificationsButton}
          >
            <img src={notifications} alt="notifications button" />
          </Box>
 
          <ProfileDropdown navigation={this.props.navigation} id={""} data-test-id="profileDropdownTestId"/>
        </Box>
      </Box>
    )
  }

  getBackgroundColor = (item : string, selectedItems : string[]) => {
    return selectedItems.includes(item) ? '#013D4F' : 'transparent';
  };

  getItemWidth = (item : string) => {
    switch (item) {
      case 'Speed':
        return '85px';
      case 'Accuracy':
        return '106px';
      default:
        return '158px';
    }
  };

  renderCompleteDeal = () => {
    return (
      <Box style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', minWidth:'901px', height:'100%'}}>
            <Box className="mainContent">
              <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: '104px', padding: '0 20px', boxSizing: 'border-box', overflow: 'hidden' }}>
                <Typography className="gothamLightText" style={{ fontWeight: 300, fontSize: "30px", color: "#013D4F" }}>Complete the Deal</Typography>
                <Typography className="gothamText" style={{marginLeft:'-14px', marginRight:'-14px', fontWeight: 400, fontSize: "18px", color: "#4B4C4B", textAlign:'center' }}>Please confirm to finalize the deal and release the pending fees to holding. Fees will be released to the service provider upon completion of the deal.</Typography>
              </Box>
              <Box style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: '140px', alignItems: 'center', border: '1px solid #F1F4F3', borderRadius: '10px', padding: '0 6%', boxSizing: 'border-box', overflow: 'hidden' }}>
                <Box style={{ width: '40%', marginTop:'8px' }}>
                  <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography style={{ color: '#939F9B', fontWeight: 500, fontSize: '16px' }}>ID:</Typography>
                    <Typography style={{ color: '#939F9B', fontWeight: 500, fontSize: '16px' }}>328493</Typography>
                  </Box>
                  <Box>
                    <Typography style={{fontSize:'18px', fontWeight:500, color:'#4B4C4B'}}>{this.state.spName}</Typography>
                  </Box>
                  <Box style={{ display: 'flex', backgroundColor: '#F0FAFA', borderRadius: '5px', width: '88px', height: '25px', justifyContent: 'space-around', alignItems: 'center', padding: '0px 10px', marginTop:'4px' }}>
                    <ThumbUpAltOutlinedIcon style={{ color: '#4B4C4B', width: '15px', height: '15px' }} />
                    <Box style={{display:'flex'}}>
                    <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight:500 }}>86</Typography>
                    <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight:500 }}>%</Typography>
                    </Box>
                    <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight:500 }}>Positive</Typography>
                  </Box>
                  <Typography></Typography>
                </Box>
                <Box style={{ display: 'flex', width: '200%', justifyContent: "space-between", alignItems: 'flex-start', height: '50%' }}>
                  <Box style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ color: '#939F9B', fontWeight: 500, fontSize: '14px' }}>CUSTOMER LAST NAME</Typography>
                    <Typography style={{ color: '#4B4C4B', fontWeight: 600, fontSize: '20px' }}>{this.state.lastName}</Typography>
                  </Box>
                  <Box>
                    <Typography style={{ color: '#939F9B', fontWeight: 500, fontSize: '14px' }}>STATES</Typography>
                    <Typography style={{ color: '#4B4C4B', fontWeight: 500, fontSize: '18px' }}>{this.state.fromState} {'>'} {this.state.toState}</Typography>
                  </Box>
                  <Box>
                    <Typography style={{ color: '#939F9B', fontWeight: 500, fontSize: '14px' }}>APPLICATION</Typography>
                    <Typography style={{ color: '#4B4C4B', fontWeight: 500, fontSize: '18px' }}>{this.state.application}</Typography>
                  </Box>
                  <Box>
                    <Typography style={{ color: '#939F9B', fontWeight: 500, fontSize: '14px' }}>SERVICES</Typography>
                    <Typography style={{ color: '#4B4C4B', fontWeight: 500, fontSize: '18px' }}>{this.state.service}</Typography>
                  </Box>
                  <Box>
                    <Typography className="gothamText" style={{ color: '#4B4C4B', fontWeight: 400, fontSize: '14px' }}>VEHICLE TYPE</Typography>
                    <Typography style={{ color: '#4B4C4B', fontWeight: 500, fontSize: '18px' }}>{this.state.vehicleType}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
                <Box style={{ width: '100%', height: '263px', border: '1px solid #F0FAFA', borderBottom: '1px dashed #F0FAFA', borderTopLeftRadius: '12px', borderTopRightRadius: '12px', borderBottomStyle: 'none', background: 'linear-gradient(to right, #F0FAFA 50%, transparent 50%) bottom repeat-x', backgroundSize: '18px 1px', padding: '0 6%', boxSizing: 'border-box', overflow: 'hidden' }}>
                  <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', height: '90%' }}>
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '128px' }}>
                      <Box>
                        <Box>
                          <Typography style={{ color: '#013D4F', fontWeight: 500, fontSize: '24px' }}>Total Fees To Release</Typography>
                        </Box>
                      </Box>
                      <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography style={{ color: '#4B4C4B' }}>Registration Fee</Typography>
                        <Box style={{ display: 'flex' }}>
                          <Box style={{ display: 'flex', color: '#C5CBC9', textDecoration: 'line-through', marginRight:'4px'}}>
                            <Typography>$</Typography>
                            <Typography>{this.state.prevRegFee}</Typography>
                          </Box>
                          <Box style={{ display: 'flex' }}>
                            <Typography>$</Typography>
                            <Typography>{this.state.regFee}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography style={{ color: '#4B4C4B' }}>Service Fee</Typography>
                        <Box style={{ display: 'flex' }}>
                          <Typography>{this.state.serviceFee}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center', marginLeft:'-10px' }}>
                      <Checkbox checked={this.state.termsAndConditionCheck} data-test-id="termsAndConditionCheckboxId" color="primary" onChange={this.handleTermsAndCondition} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                      <Typography style={{ color: '#0F172A' }}>I agree to the <span style={{ color: '#013D4F', textDecoration: 'underline', cursor:'pointer'}} onClick={this.navigateToTermsConditions} data-test-id="termsAndConditionTestId">Terms and Conditions</span></Typography>
                    </Box>
                  </Box>
                  <Box></Box>
                </Box>
                <Box style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', height: '127px', border: '1px solid #F0FAFA', borderTop: '0px dotted #F1F4F3', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px', padding: '0 6%', boxSizing: 'border-box', overflow: 'hidden' }}>
                  <Box style={{ width: '100%' }}>
                    <Button data-test-id="RejectAndTellUsWhyTestId" onClick={this.setRejectedReview} style={{ border: '1px solid #4C4D4C', borderRadius: '8px', width: '180px', height: '56px' }}><Typography style={{ fontSize: '14px', fontWeight: 700, color: '#4C4D4C', textTransform:'none' }}>Reject & Tell Us Why</Typography></Button>
                  </Box>
                  <Box style={{ width: '209.16%', display: 'flex', justifyContent: 'center', marginRight:'30px' }}>
                    <Button data-test-id="submitButtonTestId" onClick={this.setSubmittedTrue} disabled={!this.state.termsAndConditionCheck} style={{ borderRadius: '8px', width: '432px', height: '56px', backgroundColor:`${this.state.termsAndConditionCheck ? '#4FC8EC' : '#E8F8FD' }` }}><Typography style={{ fontSize: '14px', fontWeight: 700, color: `${this.state.termsAndConditionCheck ? '#FFFFFF' : '#A3E2F5' }`, textTransform:'none' }}>Confirm & Release Fees</Typography></Button>
                  </Box>
                  <Box style={{ width: '50%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <Box>
                      <Typography style={{fontWeight:500, fontSize:'12px', color:'#4B4C4B'}}>Total Fee</Typography>
                    </Box>
                    <Box>
                    <Box style={{ display: 'flex', marginTop:'4px', color:'#013D4F' }}>
                      <Typography style={{fontSize:'28px', fontWeight:600}}>$</Typography>
                      <Typography style={{fontSize:'28px', fontWeight:600}}>{this.state.totalFee}</Typography>
                    </Box>
                    <Box style={{ display: 'flex' , marginTop:'-6px', textDecoration: 'line-through', fontSize:'20px', fontWeight:400, color:'#C5CBC9', justifyContent:'flex-end'}}>
                      <Typography>$</Typography>
                      <Typography>{this.state.prevTotalFee}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              </Box>
              <Box style={{ width:'100%', height: '150px', display: 'flex', border: '1px solid #F0FAFA', borderRadius: '10px', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', padding: '0 6%', boxSizing: 'border-box', overflow: 'hidden' }}>
                <Box style={{ height: '54px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <Typography style={{ fontWeight: 400, fontSize: '18px', color:'#013D4F' }}>Attention Please</Typography>
                  <Typography style={{ color: '#C5CBC9', fontWeight: 400, fontSize: '14px' }}>This deal will automatically be closed after 15 days. You may go to <span style={{ color: '#4FC8EC' }}>Deal Details</span> to find more information about this deal.</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
    )
  }

  renderReviewDealerSide = () => {
    const { selectedItems } = this.state;
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '703px', width: '100%', height: '100%' }}>
        <Box style={{
          margin: "55px 0",
          height: `${this.state.showTextArea ? '663px' : '459px'}`,
          minWidth: '703px',
          width:'730px',
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: 'space-between',
        }}>
          <Box style={{width: "100.45%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: '104px', padding: '0 10px', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Typography className="gothamLightText" style={{ fontWeight: 300, fontSize: "36px", color: "#013D4F" }}>Deal completed successfully </Typography>
            <Typography className="gothamText" style={{ marginLeft: '-14px', marginRight: '-14px', fontWeight: 400, fontSize: "18px", color: "#4B4C4B", textAlign: 'center' }}>Thank you for choosing Vitu Marketplace! We value your feedback and would love to hear about your experience with your hired service provider.</Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', border: '1px solid #F0FAFA', borderRadius: '24px', width: '701px', height: `${this.state.showTextArea ? '526px' : '322px'}`, justifyContent: 'space-around', alignItems: 'center' }}>
            <Box>
              <Typography style={{ color: '#013D4F', fontWeight: 400, fontSize: '18px' }}>How was your experience with {this.state.spName} Services? </Typography>
            </Box>
            <Box style={{marginBottom:'20px'}} data-test-id="dealerGroup">
              <RadioGroup
                aria-label="dealer-group"
                name="dealerGroup"
                className="radioClass"
                value={this.state.likeButton.toString()}
                onChange={this.handleRadioChange}
                row={true}
                style={{ color: "black", width: '126px', display: 'flex', justifyContent: 'space-between' }}
              >
                <StyledFormControlLabel
                  value="true"
                  data-test-id="radiotest"
                  control={
                    <CustomRadio
                      disableRipple
                      color="default"
                      checkedIcon={<img src={likeButtonChecked} />}
                      icon={<img src={likeButton} />}
                      {...this.props}
                    />} label="Good" />
                <StyledFormControlLabel
                  value="false"
                  control={<CustomRadio
                    disableRipple
                    color="default"
                    checkedIcon={<img src={dislikeButtonChecked} />}
                    icon={<img src={dislikeButton} />}
                    {...this.props}
                  />} label="Bad" />
              </RadioGroup>
            </Box>
            {this.state.showTextArea && (
              <>
                <Box style={{ width: '389px', height: '44px', display: 'flex', justifyContent: 'space-between' }} data-test-id="selectedItemsButton">
                  {['Speed', 'Accuracy', 'Communication'].map((item) => (
                    <Button
                      // key={item}
                      onClick={() => this.handleButtonClick(item)}
                      style={{
                        width: this.getItemWidth(item),
                        height: '44px',
                        backgroundColor: this.getBackgroundColor(item, selectedItems),
                        border: selectedItems.includes(item) ? 'none' : '1px solid #C5CBC9',
                        borderRadius: '10px',
                        color: selectedItems.includes(item) ? '#FFFFFF' : 'black',
                        fontWeight: 400,
                        fontSize: '16px',
                        textTransform: 'none',
                      }}
                    >
                      {item}
                    </Button>
                  ))}
                </Box>
                <Box>
                  <TextField
                    label="Tell us more..."
                    style={{ width: '549px', height: '100px' }}
                    variant="outlined"
                    data-test-id = "textBoxDealerReviewTestId"
                    multiline
                    value={this.state.dealerReviewText}
                    onChange={(event) => { this.handleDealerReviewTextChange(event) }}
                    rows={4}
                  ></TextField>
                </Box>
              </>
            )
            }
            <Box style={{ display: 'flex', width: '549px', justifyContent: 'space-between', alignItems: 'center', height: '56px' }}>
              <Box style={{ width: '100%' }}>
                <Button style={{ border: '1px solid #4C4D4C', borderRadius: '8px', width: '162px', height: '56px' }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: '#4C4D4C', textTransform:'none' }}>Maybe Later</Typography></Button>
              </Box>
              <Box>
                <Button data-test-id="submitReviewButtonTestId" onClick={this.setSubmitReview} disabled={this.state.selectedItems.length == 0} style={{ borderRadius: '8px', width: '371px', height: '56px', backgroundColor: `${this.state.selectedItems.length != 0 ? '#4FC8EC' : '#E8F8FD'}` }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: `${this.state.selectedItems.length != 0 ? '#FFFFFF' : '#A3E2F5'}`, textTransform:'none' }}>Submit Review</Typography></Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  renderFeedbackSent = () => {
    return (
      <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: '703px', width: '100%', height: '100%'}}>
        <Box style={{
          margin: "55px 0",
          height: '287px',
          minWidth: '703px',
          width:'730px',
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: 'space-between',
        }}>
          <Box style={{width: "100.45%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: '104px', padding: '0 10px', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Typography className="gothamLightText" style={{ fontWeight: 300, fontSize: "36px", color: "#013D4F" }}>Feedback Sent</Typography>
            <Typography className="gothamText" style={{ fontWeight: 400, fontSize: "18px", color: "#4B4C4B", textAlign: 'center' }}>Thank you for providing your feedback. Your input helps us improve our Marketplace services.</Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', borderRadius: '24px', width: '701px', height: '150px', justifyContent: 'space-around', alignItems: 'center' }}>
            <Box style={{ display: 'flex', width: '590px', justifyContent: 'space-between', alignItems: 'center', height: '56px' }}>
              <Box style={{ width: '100%' }}>
                <Button onClick={this.navigateToDealDashboard} style={{ border: '1px solid #4C4D4C', borderRadius: '8px', width: '210px', height: '56px' }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: '#4C4D4C', textTransform:'none' }}>View Deal Dashboard</Typography></Button>
              </Box>
              <Box>
                <Button onClick={this.navigateToDashboard} data-test-id="backToHomeButtonTestId" style={{ borderRadius: '8px', width: '346px', height: '56px', backgroundColor: '#4FC8EC' }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: '#FFFFFF', textTransform:'none' }}>Back to Home</Typography></Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  renderRejectedReview = () => {
    return (
      <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Box style={{ margin:"55px 0",
          width:'100%',
          maxWidth:'901px',
          display:"flex",
          height:'800px',
          flexDirection:"column",
          justifyContent:"space-between",
          alignItems:'space-between',
          position:"relative",
          
        }}>
          <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", minHeight: '104px', padding: '0 20px', boxSizing: 'border-box', overflow: 'hidden', minWidth:'901px' }}>
            <Typography className="gothamLightText" style={{ fontWeight: 300, fontSize: "30px", color: "#013D4F" }}>Tell Us Why</Typography>
            <Typography className="gothamText" style={{ marginLeft: '-14px', marginRight: '-14px', fontWeight: 400, fontSize: "18px", color: "#4B4C4B", textAlign: 'center' }}>Please let us know why you believe this deal is incomplete. Well notify your Service Provider of the issue so they can resolve it.</Typography>
          </Box>
          <Box style={{display:'flex', flexDirection:'column', justifyContent:'space-around', alignItems:'center', width:'901px', height:'487px', border: '1px solid #F0FAFA', borderRadius: '10px',}}>
            <Box style={{display:'flex',flexDirection : 'column', justifyContent:'space-between', alignItems:'space-between', width:'805px', height:'287px'}}>
              <StyledLabelTextField
                label="Subject"
                style={{ width: '805px', height: '56px', paddingLeft:'4px', fontSize:400, color:'rgba(75, 76, 75, 1)' }}
                variant="outlined"
                data-test-id="tellUsWhySubjectTestId"
                value = {this.state.tellUsWhySubject}
                onChange={(event) => this.handleTellUsWhySubject(event)}
              />
              <StyledTextField
                label="Details of the issue" 
                variant="outlined" 
                data-test-id="tellUsWhyDetailsTestId"
                multiline 
                value={this.state.tellUsWhyDetails}
                onChange={(event) => this.handleTellUsWhyDetails(event)}
              />
            </Box>
            <Box style={{ display: 'flex', width: '781px', justifyContent: 'space-between', alignItems: 'center', height: '56px' }}>
              <Box style={{ width: '100%' }}>
                <Button data-test-id="cancelButtonTestid" onClick={this.cancelRejectedReview} style={{ border: '1px solid #4C4D4C', borderRadius: '8px', width: '380px', height: '56px' }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: '#4C4D4C', textTransform:'none' }}>Cancel</Typography></Button>
              </Box>
              <Box>
                <Button data-test-id="sendButtonTestid" onClick={this.setIssueResolved} disabled={!this.handleTellUsWhySentCond} style={{ borderRadius: '8px', width: '380px', height: '56px', backgroundColor: `${this.handleTellUsWhySentCond() ? '#4FC8EC' : '#E8F8FD'}`}}><Typography style={{ fontSize: '16px', fontWeight: 700, color: `${this.handleTellUsWhySentCond() ? '#ffffff' : '#A3E2F5'}`, textTransform:'none' }}>Send</Typography></Button>
              </Box>
            </Box>
          </Box>
          <Box style={{ height: '172px', display: 'flex', border: '1px solid #F0FAFA', borderRadius: '10px', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', padding: '0 6%', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Box style={{ height: '76px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Typography style={{ fontWeight: 400, fontSize: '18px', color: '#013D4F' }}>Attention Please</Typography>
              <Typography style={{ color: '#C5CBC9', fontWeight: 400, fontSize: '14px' }}>The deal status will automatically be changed to Dealer Flags Incomplete. The Service Provider will continue the work and resend to you for confirmation. Fees will not be released to the Service Provider until you confirm the deal is complete.</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  renderIssueReceived = () => {
    return (
      <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
        <Box style={{
          margin: "55px 0",
          height: '287px',
          width:'100%',
          maxWidth: '703px',
          display: "flex",
          position: "relative",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: 'space-between',
        }}>
          <Box style={{width: "701px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: '0 10px', boxSizing: 'border-box', overflow: 'hidden' }}>
            <Typography className="gothamLightText" style={{ fontWeight: 300, fontSize: "36px", color: "#013D4F" }}>Issue Received</Typography>
            <Typography className="gothamText" style={{ fontWeight: 400, fontSize: "18px", color: "#4B4C4B", textAlign: 'center' }}>Thank you for reaching out and providing this information. We will work with your service provider to notify them of the issue and get this resolved.</Typography>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', width: '701px', height: '150px', justifyContent: 'space-around', alignItems: 'center' }}>
            <Box style={{ display: 'flex', width: '590px', justifyContent: 'space-between', alignItems: 'center', height: '56px' }}>
              <Box style={{ width: '100%' }}>
                <Button data-test-id="backToDashboardButtonTestId" onClick={this.navigateToDashboard} style={{ border: '1px solid #4C4D4C', borderRadius: '8px', width: '210px', height: '56px' }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: '#4C4D4C', textTransform:'none' }}>Back to Home</Typography></Button>
              </Box>
              <Box>
                <Button data-test-id="navigateToDealDashboardButtonTestId" onClick={this.navigateToDealDashboard} style={{ borderRadius: '8px', width: '346px', height: '56px', backgroundColor: '#4FC8EC' }}><Typography style={{ fontSize: '16px', fontWeight: 700, color: '#FFFFFF', textTransform:'none' }}>Back to Deal Dashboard</Typography></Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <Box>
        <Box style={{position:'absolute', display:"none"}}>
          {this.unusedComponent(this.demoEvent)}
        </Box>
        <CustomisableUserProfilesStyle>
          {this.headerr()}
          <Box style={{ width: "99.9%", position: "absolute", left: "0", borderTop: "2px solid #F0FAFA", marginTop: "10px" }} />
          {!this.state.submittedConfFees && !this.state.submitReview && !this.state.rejectedReview && !this.state.issueResolved && this.renderCompleteDeal()}
          {this.state.submittedConfFees && this.renderReviewDealerSide()}
          {this.state.submitReview && this.renderFeedbackSent()} 
          {this.state.rejectedReview && this.renderRejectedReview()}
          {this.state.issueResolved && this.renderIssueReceived()}
        </CustomisableUserProfilesStyle>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomisableUserProfilesStyle = styled(Box)({
  padding: "24px",
  boxSizing: "border-box",
  "& .dealerInfoForm": {
    padding: "0px 20px",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
    },
  },
  "& .header": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems:"center"
  },
  "& .logo": {
    width: "54px",
    height: "24px",
  },
  "& .MuiAutocomplete-root": {
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: "6px", 
      height:"56px",
      "& .MuiAutocomplete-input": {
        padding: "0px", 
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "9px", 
      },
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '9px',
      },
      '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
        backgroundColor: "#013D4F",
      },
      "& .MuiAutocomplete-popper": {
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option": {
            "&[data-focus='true'], &:hover": {
              backgroundColor: "##013D4F",
            },
            "&[aria-selected='true']": {
              backgroundColor: "#013D4F",
            },
          },
        },
      },
    },
  },
  "& .logout, .skip": {
    width: "98px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    color: "#4B4C4B",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
  },
  "& .w9FormButton": {
    marginTop:"20px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    color: "#4B4C4B",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
  },
  "& .collapsibleContent": {
    transition:"max-height 0.3s ease-in-out",
  },
  "& .heading": {
    width:"100%",
    fontWeight: 600,
  },
  "& .title": {
    fontSize: "30px",
    lineHeight: "40px",
    color: "#013D4F",
    fontWeight: 300
  },
  "& .subtitle": {
    fontSize: "18px",
    lineHeight: "18px",
    color: "#4B4C4B",
    fontWeight: 300,
    marginTop:"10px",
    zIndex: 40,
    position:'relative'
  },
  "& .headingForm": {
    fontFamily: "Gotham, sans-serif",
    fontWeight: 300,
  },
  "& .titleForm": {
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    fontWeight: 300
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
    }
  },
  '& label.Mui-focused': {
    color: '#4B4C4B',
    fontWeight:500
  },
  '& .MuiInputLabel-root': {
    paddingLeft: '6px',
    color: '#C5CBC9',
  },
  '& label.MuiInputLabel-shrink': {
    marginLeft: 0,
    paddingLeft: 0,
    color: '#4B4C4B',
    fontWeight:500
  },
  "& .radioStyle": {
    width:"20px", 
    height:"20px"
  },
  "& .dealerGroupBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px 20px",
    border: "2px solid #F0FAFA",
    borderRadius : "16px"
  },
  "& .dealerGroupText": {
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "2px",
    fontSize: "20px",
    fontWeight: 500,
    marginBottom: "20px",
  },
  "& .custom-placeholder": {
     paddingLeft:"10px",
     fontSize:"16px",
     fontWeight:400,
    },
  "& .MuiFormGroup-root": {
    display: "inline",
  },
  "& .MuiFormControlLabel-label": {
    marginTop:"3.5px"
  },
  "& .dealershipDetailsBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px",
    border: "2px solid #F0FAFA",
    borderRadius : "16px",
    height:"auto"
  },
  "& .dealershipDetailsInnerBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius : "16px",
    height:"auto",
  },
  "& .dealerGrouptText": {
    color:"#013D4F",
    fontWeight:400,
    marginBottom:"5px",
    fontSize:"16px",
    lineHeight:"24px"
  },
  "& .SubtitleForm":{
      color:"#4B4C4B",
      fontWeight:500,
      lineHeight:"22px",
      fontSize:"14px"
  },
  "& .subtitleForm": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#4B4C4B",
  },
  "& .mainContent": {
    margin: "55px 0",
    height:'856px',
    width:'901px',
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent:"space-between",
    alignItems: 'space-between',
  },
  "& .steps": {
    display:"flex",
    flexDirection:"column",
    marginRight: "24px",
    marginTop:"26px",
    padding:"20px 0px",
    width:"250px"
  },
  "& .stepContent": {
    flex: "3",
    marginTop:"24px",
    width:"400px",
    padding:"20px 0px",
  },
  "& .stepItem": {
    marginBottom: "24px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "16px",
    color: "#C5CBC9",
  },
  "& .stepItem.active .stepTitle": {
    color: "#013D4F",
    "& .MuiTypography-body1": {
      color:"#013D4F"
    }
  },
  "& .stepItem.done .stepTitle": {
    color: "#26BBB3",
    "& .MuiTypography-body1": {
      color:"#26BBB3"
    }
  },
  "& .stepTitle": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "2px",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
  },
  "& .stepSubtitle": {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  "& .stepText": {
    textAlign: "end",
  },
  "& .stepNumber": {
    width: "48px",
    height: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    borderRadius:'50%',
    backgroundColor:"rgba(240, 250, 250, 0.78)"
  },
  "& .stepItem.skipped .stepTitle": {
    color: "#F05E22",
    "& .MuiTypography-body1": {
      color:"#F05E22"
    }
  },
  "& .stepItem.clickable": {
    cursor: "pointer",
  },
  "& .stepItem:not(.clickable)": {
    cursor: "not-allowed",
  },
  "& .pac-container.pac-logo": {
    width: "800px !important",
    backgroundColor: "red !important", 
    padding: "10px !important",
    fontSize: "16px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)", 
    border: "1px solid #ccc", 
    borderRadius: "4px", 
  },
  "& .pac-item": {
    padding: "10px",
    fontSize: "14px",
    cursor: "pointer",
  },
  "& .pac-item:hover": {
    backgroundColor: "red",
  },
  "& .pac-item-query": {
    fontSize: "14px",
    color: "#red",
  },
  "& .pac-matched": {
    fontWeight: "bold",
  },
  "& .pac-icon": {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  "& .headerForm": {
    marginBottom: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  "& .photoForm": {
    maxWidth: "640px",
    height: "321px",
    backgroundSize: "100% 191px",
    border: "2px solid #F0FAFA",
    borderRadius: "30px",
  },
  "& .photoFormContent": {
    padding: "48px",
    marginTop: "81px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },
  "& .profilePhoto": {
    width: "120px",
    height: "120px",
    borderRadius: "30px",
  },
  "& .updatePhoto": {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  "& .uploadPhoto": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .upload, .delete": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    borderRadius: "8px",
  },
  "& .upload, .uploadLogo": {
    color: "#013D4F",
    border: "1px solid #013D4F",
  },
  "& .delete": {
    border: "1px solid #C43937",
    color: "#C43937",
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#C43937',
  },
  "& .uploadLogo": {
    position: "absolute",
    top: "12px",
    right: "24px",
    height: "32px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textTransform: "none",
    borderRadius: "8px",
  },
  "& .hints": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "10px",
    color: "#C5CBC9",
  },
  "& .nextStep": {
    marginTop: "24px",
    textAlign: "center",
  },
  "& .nextStep button": {
    width: "89%",
    height: "56px",
    marginBottom: "8px",
    backgroundColor: "#4FC8EC",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: "none",
    borderRadius:"8px"
  },
  "& .nextStep p": {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#4B4C4B",
  },
  "& .form": {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  "& .formControl": {
    position: "relative",
    height: "56px",
    padding: "0 24px 10px 24px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .Mui-checked": {
    color:"#4FC8EC",
  },
  "& .info": {
    position: "absolute",
    top: "-35px",
    right: "0",
  },
  "& .infoTextFirst": {
    position: "absolute",
    top: "53px",
    right: "24px",
  },
  "& .infoTextVoidedCheck": {
    position: "absolute",
    top: "0px",
    right: "4px",
  },
  "& .infoTextLast": {
    position:"absolute",
    right:"2px",
    top: "0px",
  },
  '& .MuiInputBase-input': {
    '&::placeholder': {
      color: '#C5CBC9',
      opacity: 1,
    },
  },
  "& .infoText": {
    position: "absolute",
    top: "-23px",
    right: "2px",
  },
  "& .infoTextStep3": {
    position: "absolute",
    top: "72px",
    right: "26px",
  },
  "& .infoTextBankAddress": {
    position:"absolute",
    top:"0px",
    right:"2.5px",
  },
  "& .same": {
    marginTop: "-14px",
    marginLeft: "7px",
    fontWeight:500,
  },
  "& .nextStep button:disabled": {
    color: "#75D3F0",
    backgroundColor: "#E8F8FD",
  },
  "& .MuiAutocomplete-groupUl": {
    backgroundColor:"red"
  },
}
);
const webStyle = {
  firstWrapper: { display: "flex", justifyContent: 'space-between', },
  filterAlign: {
    justifyContent: 'center', alignIitem: 'center', display: 'flex', gap: '2px'
  },

  firstWrapper1: { color: "#ff8500", height: "55px", width: '50px' },
  secondWrapper: {
    display: "flex",
    marginTop: "30px",
    backgroundColor: "#ff8500",
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px 10px 20px',
  },
  thirdWrapper: { color: "black", fontSize: "20px", maxWidth: "200px", minWidth: "100px", fontWeight: 500 },
  thirdWrapper1: { color: "white", fontSize: "18px", justifyContent: 'space-between', maxWidth: "200px", minWidth: "100px", fontWeight: 500 },
  fourthWrapper: { backgroundColor: "white", fontSize: "15px", width: "100%", margin: "4px 0px 4px 0px" },
  fifthWrapper: {
    fontSize: "16px",
    fontWeight: 500,
  },
  sixthWrapper: {
    display: "flex",
    backgroundColor: "#720e9e",

  },
  seventhWrapper: {
    marginBottom: '20px',
    display: "flex",
    justifyContent: "space-around",

  },
  eighthWrapper: {
    marginTop: '20px',
    maxWidth: "500px",
    margin: "auto",
    backgroundColor: "lightgray",
    padding: "10px",
    maxHeight: "600px",
  },
  ninethWrapper: {
    backgroundColor: "white",
    borderRadius: "5px",
    border: "1px black",

  },
  tenthWrapper: {
    fontSize: "16px",
    fontWeight: 700,
    marginTop: "10px",

  },
  elevenThWrapper: {
    padding: "8px 30px 8px 30px",
    borderRadius: "10px",
    backgroundColor: "purple",
    color: "white",
    fontSize: "13px",
    fontWeight: 600,
    marginTop: "20px",

  },
  closeButton: {
    position: 'absolute',
    right: 2,
    top: 2,
    color: '#FFFFFF'
  },
  dialogBox: {

  },
  modalContent: {
    borderTop: '1px solid gray',
    margin: 0,
    display: 'flex',
    justifyContent: 'space-arround',
    width: '450px',
    padding: '22px'
  }
}
const webStylesHeader : Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    minWidth: '870px',
  },
 
  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 0px",
    minWidth: '870px',
    "@media (max-width: 375px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
 
  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "0px",
  },
 
  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
  },
 
  logo: {
    width: "52px",
  },
 
  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px",
    "@media (max-width: 375px)": {
      width: "100%",
      justifyContent: "space-between",
      padding: "0 10px",
    },
  },
 
 
  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
 
  userAvatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
 
  main: {
    display: "flex",
    flexWrap: "nowrap",
    position: "relative",
  },
 
 
  buttonText: {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "14px",
    fontWeight: 500,
  },
 
  content: {
    width: "100%",
    marginLeft: "120px",
  },
 
}

const StyledFormControlLabel = styled(FormControlLabel)({
  margin: 0,
  flexDirection: 'column',
  '& .MuiFormControlLabel-label': {
    marginTop: '16px',
  },
});

const CustomRadio = styled(Radio)({
  padding: 0,
});

const StyledTextField = styled(TextField) ({
  width: '805px', 
  '& .MuiOutlinedInput-root': {
    height: '202px',
    alignItems: 'start',
  },
  '& .MuiOutlinedInput-input': {
    padding: '6px',
    color:'rgba(75, 76, 75, 1)',
    fontSize:'16px',
    fontWeight:400
  },
});

const StyledLabelTextField = styled(TextField) ({
  '& label.Mui-focused': {
    color: '#4B4C4B',
    fontWeight:500
  },
  '& .MuiInputLabel-root': {
    paddingLeft: '6px',
    color: '#C5CBC9',
  },
  '& label.MuiInputLabel-shrink': {
    marginLeft: 0,
    paddingLeft: 4,
    color: '#4B4C4B',
    fontWeight:500
  },
})


// Customizable Area End


