import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  createStyles,
  FormControl,
  Input,
  InputAdornment,
  Typography,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CreateIcon from "@material-ui/icons/Create";
import CustomInfoComponent from "../../../../packages/components/src/CustomInfoComponent";
import { ServiceProvider } from "./ProjectTemplatesDealDashboardController.web";
import { ArrowForwardIosOutlined, Done } from "@material-ui/icons";
const classes = createStyles({
  stateFee: {
    padding: "35px 24px",
    borderRadius: "10px",
    background: "#FFFFFF",
    boxShadow: "0px 0px 4px 0px #8989895C",

    "& .MuiAccordionSummary-root": {
      padding: 0,
      height: "20px",
      minHeight: "0",
    },

    "& .MuiAccordionDetails-root": {
      padding: "0",
    },
    "& button": {
      marginTop: "18px",
      height: "56px",
      padding: "16px",
      gap: "8px",
      borderRadius: "8px",

      "& > span": {
        fontWeight: 700,
        textTransform: "initial",
        "& > span": {
          display: "flex",
          gap: "10px",
          alignItems: "center",
          fontFamily: "Roboto",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "24px",
        },
      },
    },
  },

  stateFeeIcon: {
    width: "24px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  stateFeeInput: {
    marginTop: "20px",
    height: "56px",
    padding: "10px 24px 10px 24px",
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #C5CBC9",
    background: "#FFFFFF",

    "&::before": {
      content: "none",
    },

    "&::after": {
      content: "none",
    },

    "&:hover": {
      content: "none",
    },
  },

  inputInfoIcon: {
    width: "28px",
    height: "28px",
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    right: "30px",
    top: "10%",
    transform: "translateY(-50%)",
  },

  stateFeeSaveBtn: {
    width: "100%",
    background: "#E8F8FD !important",

    "& > span": {
      color: "#A3E2F5",
    },
  },

  stateFeeSaveEnabledBtn: {
    width: "100%",
    background: "rgba(79, 200, 236, 1) !important",

    "& > span": {
      color: "rgba(255, 255, 255, 1)",
    },
  },

  calculateNow: {
    color: "#4FC8EC",
    fontWeight: 700,
    cursor: "pointer",
  },

  registrationFeeContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "20px",
    height: "56px",
    alignItems: "center",
  },

  registrationFee: {
    fontFamily: "Roboto",
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "38px",
    textAlign: "left",
    color: "rgba(75, 76, 75, 1)",
  },

  cancelButton: {
    gap: "8px",
    borderRadius: "8px",
    border: "1px solid #013D4F",
    width: "40%",
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "18px",
  },

  addedManually: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "26px",
    color: "#C5CBC9",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },

  boxInfoText: {
    fontSize: 16,
    color: "#4B4C4B",
    lineHeight: "20px",
    textAlign: "left",
    fontWeight: 500,
    fontFamily: "Roboto",
  },
});

export interface Props extends WithStyles {
  role: string;
  hiredSPInfo: ServiceProvider;
  calculateNowHandler: () => void;
  sendRegistrationFee: (role: string, fee: string) => void;
}
export class RegistrationFee extends React.Component<
  Props,
  {
    isRegistrationFeeSaved: boolean;
    registrationFee: string;
  }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isRegistrationFeeSaved: false,
      registrationFee: "",
    };
  }

  handleSaveRegistrationFee = (buttonValue: any) => {
    switch(buttonValue.key){
      case "Save":
        this.setState({
          isRegistrationFeeSaved: true,
        });
        break;
      case "Edit Fee":
      case "Cancel":
        this.setState({
          isRegistrationFeeSaved: false,
        });
        break;
      case "Send to Service Provider":
        this.props.sendRegistrationFee('service_provider', this.state.registrationFee);
        this.setState({
          isRegistrationFeeSaved: true,
        });
        break;
      case "Send to Dealer":
        this.props.sendRegistrationFee('dealer', this.state.registrationFee);
        this.setState({isRegistrationFeeSaved: true});
        break;
      default:
        return;
    }
    
  };

  changeRegistrationFeeHandler = (event: any) => {
    const input = event.target.value;
    let value = String(input || "");

    value = value.match(/[0-9.]+/g)?.join("") || "";

    if (value.length > 9) {
      value = value.slice(0, 9);
    }

    this.setState({
      registrationFee: value,
    });
  };

  cancelEnterFeeHandler = () => {
    this.setState({
      registrationFee: "",
    });
  };

  getButtonValue = (isRegistrationFeeSaved: boolean, isHiredSPExist: boolean, role: string) => {
    let buttonValue = undefined;
    if (isRegistrationFeeSaved) {
      if (isHiredSPExist) {
        buttonValue  = {
          key: "View Details",
          value: (
            <span>
              View Details{" "}
              <ArrowForwardIosOutlined style={{ width: 12, height: 12 }} />{" "}
            </span>
          ),
        };
      } else {
        buttonValue = {
          key: "Edit Fee",
          value: (
            <span>
              <CreateIcon /> Edit Fees
            </span>
          ),
        };
      }
    } else {
      if (isHiredSPExist) {
        if (role?.includes("dealer")) {
          buttonValue = {
            key: "Send to Service Provider",
            value: "Send to Service Provider",
          };
        } else {
          buttonValue = {
            key: "Send to Dealer",
            value: "Send to Dealer"
          };
        }
      } else {
        buttonValue = {
          key: "Save",
          value: "Save"
        };
      }
    }
    return buttonValue;
  }

  render(): React.ReactNode {
    const { classes, calculateNowHandler, role, hiredSPInfo } = this.props;
    const { isRegistrationFeeSaved, registrationFee } = this.state;
    const formatMoney = (number: number) => {
      return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    };
    const isHiredSPExist = Object.keys(hiredSPInfo).length > 0;
    let buttonValue = this.getButtonValue(isRegistrationFeeSaved, isHiredSPExist, role);
    
    return (
      <Box sx={{ marginTop: "25px" }}>
        <Accordion className={classes.stateFee} defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h1">Registration Fee</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <FormControl fullWidth>
              {isRegistrationFeeSaved ? (
                <Box className={classes.registrationFeeContainer}>
                  <Typography className={classes.registrationFee}>
                    ${formatMoney(+registrationFee)}{" "}
                    {isHiredSPExist && (
                      <CreateIcon
                        onClick={() => this.handleSaveRegistrationFee({key: 'Cancel', value: 'Cancel'})}
                        style={{ color: "#4FC8EC" }}
                      />
                    )}
                  </Typography>
                  {isRegistrationFeeSaved && isHiredSPExist && (
                    <Typography className={classes.addedManually}>
                      <Done
                        style={{ color: "#C5CBC9", width: 16, height: 16 }}
                      />{" "}
                      Added Manually
                    </Typography>
                  )}
                </Box>
              ) : (
                <>
                  <Input
                    id="standard-adornment-amount"
                    data-test-id="registration-fee-input"
                    startAdornment={
                      <InputAdornment
                        position="start"
                        className={classes.stateFeeIcon}
                      >
                        $
                      </InputAdornment>
                    }
                    className={classes.stateFeeInput}
                    placeholder="Enter Registration Fee"
                    value={registrationFee}
                    onChange={this.changeRegistrationFeeHandler}
                  />
                  <Box className={classes.inputInfoIcon}>
                    <CustomInfoComponent>
                      <p className={classes.boxInfoText}>
                        The vehicle registration fee charged by the state to
                        legally register a new or used vehicle. (Cost varies by
                        state)
                      </p>
                    </CustomInfoComponent>
                  </Box>
                </>
              )}
              <Box className={classes.buttonContainer}>
                <Button
                  data-test-id="save-fee-btn"
                  variant="contained"
                  color="primary"
                  disableElevation
                  disabled={registrationFee === ""}
                  className={
                    registrationFee === ""
                      ? classes.stateFeeSaveBtn
                      : classes.stateFeeSaveEnabledBtn
                  }
                  onClick={() => this.handleSaveRegistrationFee(buttonValue)}
                >
                  {buttonValue.value}
                </Button>
                {isHiredSPExist &&
                  registrationFee !== "" &&
                  !isRegistrationFeeSaved && (
                    <Button
                      onClick={this.cancelEnterFeeHandler}
                      className={classes.cancelButton}
                      data-test-id="cancel-btn"
                    >
                      Cancel
                    </Button>
                  )}
              </Box>
              <Typography
                variant="body1"
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  // fontSize: "16px",
                }}
              >
                {!isRegistrationFeeSaved
                  ? "Don't have the Registration Fee yet?"
                  : "Not sure?"}{" "}
                &nbsp;
                <span
                  onClick={calculateNowHandler}
                  className={classes.calculateNow}
                >
                  Calculate now
                </span>
              </Typography>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
}

export default withStyles(classes)(RegistrationFee);
