import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Grid,
  InputBase,
  Avatar
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";
import ViewChat from "./ViewChat.web";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>

        <IconButton onClick={this.openChats} data-test-id="chat_icon">
          <ChatBubbleOutlineIcon style={webStyles.chatButton} />
        </IconButton>
        {this.state.openChats &&
          <>
            <Box style={webStyles.chatBoxPreview as React.CSSProperties}>
              <Box>
                <Box style={webStyles.chatHeadingLayout}>
                  <Box>
                    <Typography component={'h6'} style={webStyles.headingText}>{configJSON.chatHeading}</Typography>

                  </Box>
                  <Box>
                    <Grid item xs={12}>
                      <Grid container alignItems="center">
                        <Grid item >
                          {this.state.minimizeChats ? <IconButton onClick={() => this.closeChatsList('list')} data-test-id="minimize_chat">
                            <ExpandMoreIcon style={webStyles.iconButton} />
                          </IconButton> :
                            <IconButton onClick={() => this.openChatsList('list')} data-test-id="maxmize_chat">
                              <ExpandLessIcon style={webStyles.iconButton} />
                            </IconButton>}
                        </Grid>
                        <Grid item >
                          <IconButton onClick={() => this.closeChats("list")} data-test-id="close_chat">
                            <ClearIcon style={webStyles.iconButton} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
              {this.state.minimizeChats && <Box style={webStyles.chatContentLayout as React.CSSProperties}>
                <Box display={'flex'} alignItems={'center'}
                  style={{ background: "#F1F4F3" }} height={"52px"} borderRadius={12}
                >
                  <SearchIcon style={webStyles.searchIcon} />
                  <InputBase
                    data-test-id="search_chat"
                    onChange={this.handleChatNameChange}
                    placeholder="Search Chats"
                    inputProps={{ 'aria-label': 'Search Chats' }}
                  />
                </Box>
                <Box style={{
                  marginTop: "20px", overflowY: 'auto',
                  overflowX: 'hidden'
                }}>
                  {this.state.chatList.map((list: IChat) => {
                    return (
                      <>
                        <Grid item xs={12} key={list.id}
                          style={{ cursor: 'pointer' }}
                          data-test-id="selectedChat"
                          onClick={() => this.handleSelectChat(list)}>
                          <Grid container direction="row" spacing={2}>
                            <Grid item xs={2}>
                              {list.userImage ?

                                <Avatar src={list.userImage} style={webStyles.messageChatAvatar} /> : <Avatar

                                  style={webStyles.messageChatAvatar}>
                                  {list.name
                                    .split(' ')
                                    .map((word) => word.charAt(0))
                                    .join('')
                                    .slice(0, 2)
                                    .toUpperCase()}
                                </Avatar>}
                            </Grid>
                            <Grid item xs={10}>
                              <Box>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                  <Typography component={'div'} style={webStyles.userName}>{list.name}</Typography>
                                  <Typography component={'div'} style={webStyles.userName}>{this.convertformatTime(list.lastMessageDate)}</Typography>

                                </Box>
                                <Box>
                                  <Typography component={'div'} style={webStyles.dealText}>{list.dealNumber}-{list.dealName}</Typography>
                                </Box>
                                <Box>
                                  <Typography component={'div'} style={webStyles.messageText}>{list.senderName}-{list.lastMessage}</Typography>
                                </Box>

                              </Box>

                            </Grid>

                          </Grid>

                        </Grid>
                        <Grid item xs={12} style={{

                          borderBottom: "1px solid #F0FAFA",
                          margin: "5px 0px"
                        }} />
                      </>
                    )

                  })}


                  {this.state.chatList.length === 0 &&
                    <>
                      <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '230px' }}>
                          <Grid item xs={12}>
                            <Typography component={'div'} align="center" style={webStyles.dealTextSelected}>{configJSON.noChatsFound}</Typography>

                          </Grid>

                        </Grid>

                      </Grid>
                    </>
                  }
                </Box>
              </Box>}
            </Box>
            {this.state.chatWindow &&
              <ViewChat navigation={undefined} id={""} chatBoxType={"box"}
                closeWindow={this.closeChats}
                chatDetails={this.state.selectedChat}
              />
            }
          </>}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  chatBoxPreview: {
    position: 'fixed',
    right: 10,
    width: '400px',
    color: '#fff',
    zIndex: 99,
    bottom: '0px',
    boxShadow: "0px 8px 32px 0px #0000000F"

  },
  chatHeadingLayout: {
    backgroundColor: "#013D4F",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '10px 10px 0px 0px',
  },
  chatContentLayout: {
    background: '#fff',
    height: '430px',
    overflowY: 'auto',
    padding: "15px 10px"
  },
  headingText: {
    fontFamily: "Roboto",
    fontSize: 24,
    fontWeight: 500,
    lineHeight: "28px",
    color: '#ffffff'

  },
  iconButton: {
    color: '#fff'
  },
  chatButton: {
    color: '#013D4F'
  },
  searchIcon: {
    color: "#989A98",
    margin: '0px 10px 0px 25px'
  },
  mutedChat: {
    backgroundColor: "lightgray"
  },
  messageChatAvatar: {
    width: '57px',
    height: "57px",
    border: "2px dashed #013D4F",
    backgroundColor: 'white',
    color: "#013D4F",
    fontWeight: 500 as const
  },
  userNameSelected: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    color: "#4B4C4B"
  },
  userName: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 500,
    color: "#4B4C4B"
  },
  dealText: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 400,
    color: "#4B4C4B"
  },
  dealTextSelected: {
    fontFamily: "Roboto",
    fontSize: 20,
    fontWeight: 600,
    color: "#4B4C4B"
  },
  messageText: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: 400,
    color: "#4B4C4B"
  },
  messageTextSelected: {
    fontFamily: "Roboto",
    fontSize: 15,
    fontWeight: 500,
    color: "#4B4C4B"
  }
};
// Customizable Area End
