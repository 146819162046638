// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";


import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
}

interface S {
  dealInfo: DealInfo
}

interface SS {
 
}

interface DealInfo{
  id: string,
  serviceProviderName: string,
  positivePercentage: string,
  customerLastName: string,
  fromState: string,
  toState: string,
  application: string,
  service: string,
  vehicleType: string,
  registrationFee: string
}

export default class ConfirmFeesDealerController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDealInformationId: string = '';

  getDealInformation = () =>{
    const webHeader = {
      'Content-Type': 'application/json',
      token: localStorage.getItem('authToken'),
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetDealInformationId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_posts/deals/' + localStorage.getItem("confirmFeeDealId")
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  mapToDealInfo = (response: any) => {
    const dealInfo : DealInfo = {
      id: response.id,
      serviceProviderName: response.service_provider.data.attributes.business_name,
      positivePercentage: '0',
      customerLastName: response.owner_last_name,
      fromState: response.from_state,
      toState: response.to_state,
      application: response.application_type,
      service: response.service,
      vehicleType: response.vehicle_info_type,
      registrationFee: response.fees_data.fee_amount
    }

    return dealInfo;
  }
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.state = {
      dealInfo: {} as DealInfo,
    };

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getDealInformation();
  }


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (response && !response.errors) {
        if(apiId === this.apiGetDealInformationId){
          this.setState({
            dealInfo: this.mapToDealInfo(response.data.attributes),
          })
        }
      }
    }
  }
}
// Customizable Area End