import React from "react";
// Customizable Area Start
import ConfirmFeesDealerController, {
  Props,
} from "./ConfirmFeesDealerController.web";
import {
    Box,
    Avatar,
    withStyles,
    createStyles,
    Button,
    Badge,
    ThemeProvider,
    createTheme,
    Typography,
    Card,
  } from "@material-ui/core";
import { vitu, notifications, menu } from "./assets";
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
const styles = createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      minWidth: '1768px',
      height: '100%',
      overflow: 'scroll'
    },
  
    header: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "12px 32px",
      borderBottom: "1px solid #F0FAFA"
    },
  
    headerLeftSide: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: "48px"
    },
  
    menuLogo: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: "8px"
    },
  
    hamburgerButton: {
      backgroundColor: "transparent",
      width: "24px",
      height: "24px",
      cursor: "pointer",
      padding: "unset",
      border: "none",
      minWidth: "0",
  
      "& > img": {
        width: "100%",
        height: "100%"
      }
    },
  
    logo: {
      width: "52px",
  
      "& > img": {
        width: "100%"
      }
    },
  
    headerRightSide: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: "24px"
    },
  
    notificationIcon: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "24px",
      height: "24px",
      cursor: "pointer",
  
      "& .MuiBadge-anchorOriginTopRightRectangle": {
        transform: "scale(1) translate(-40%, 30%)"
      }
    },
  
    avatarContainer: {
      width: "40px",
      height: "40px",
      borderRadius: "50%",
  
      "& > img": {
        width: "100%"
      }
    },
  
    avatar: {
      width: "100%",
      height: "100%",
      borderRadius: "24px"
    },
  
    main: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "50px",
      height: '100%'
    },
  
    formFee: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    },
  
    formFeeHeader: {
      width: '900px',
      display: "flex",
      alignItems: "center",
      flexDirection: "column"
    },
  
    cardContainer: {
      boxShadow: "0px 0px 4px 0px #8989895C",
      borderRadius: "10px",
      width: "810px",
      padding: "30px 45px 30px 45px",
    },

    cardPanelContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  
    cardPanel: {
      boxShadow: "none"
    },
  
    progressMenu: {
      padding: "6px 2px 6px 2px",
      borderRadius: "26px"
    },
  
    inProgress: {
      color: "#346472",
      border: "1px solid #346472"
    },
  
    feeDetailsContainers: {
      padding: "40px 70px",
      gap: "40px",
      width: "760px",
      borderRadius: "10px",
      border: "1px solid #F1F4F3",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: "20px"
    },
  
    feeDetailsHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%"
    },
  
    editFeeBtn: {
      padding: "10px 16px 10px 16px",
      borderRadius: "8px",
      border: "1px solid #4C4D4C",
      "& span": {
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        textTransform: "initial"
      }
    },
  
    feeDetailsFooter: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      paddingTop: "23px",
      borderTop: "1px solid #F1F4F3"
    },
   
    rejectBtn: {
      width: '183px',
      borderRadius: "8px",
      padding: '16px 0px',
      border: "1px solid #4C4D4C",
      "& span": {
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textTransform: "initial"
      },
      "&:hover": {
        backgroundColor: "#4C4D4C",
      }
    },
    acceptBtn: {
      width: '355px',
      padding: '16px 0px',
      borderRadius: "8px",
      background: "#4FC8EC",
      "& span": {
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#FFFFFF",
        textTransform: "initial"
      },
      "&:hover": {
        backgroundColor: "#4FC8EC",
        color: "#FFFFFF"
      }
    },
    totalFee: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gap: "10px"
    },
    totalFeeLabel: {
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "18px",
      color: "#4B4C4B"
    },
    totalFeeValue: {
      fontFamily: "Roboto",
      fontSize: "28px",
      fontWeight: 600,
      lineHeight: "24px",
      color: "#013D4F"
    },
    disabledBorder: {
      borderColor: "#C5CBC9"
    },
});

const theme = createTheme({
  typography: {
    h1: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "30px",
      fontWeight: 300,
      lineHeight: "40px",
      color: "#013D4F",
      letterSpacing: 0
    },
    h2: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "18px",
      fontWeight: 300,
      lineHeight: "26px",
      textAlign: "center",
      color: "#4B4C4B",
      letterSpacing: 0
    },
    h3: {
      fontFamily: "Gotham, sans-serif",
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "26px",
      textAlign: "center",
      color: "#4B4C4B",
      letterSpacing: 0
    },
    h4: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "26px",
      textAlign: "center",
      color: "#4B4C4B",
      letterSpacing: 0
    },
    body1: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "31px",
      color: "#939F9B"
    },
    body2: {
      fontFamily: "Roboto",
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "31px",
      textAlign: "left"
    }
  }
});
export class ConfirmFeesDealer extends ConfirmFeesDealerController {
  constructor(props: Props) {
    super(props);
    
  }
 
  render() {
    const { classes } = this.props;

    return  <ThemeProvider theme={theme}>
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Box className={classes.headerLeftSide}>
          <Box className={classes.menuLogo}>
            <Button className={classes.hamburgerButton}>
              <img src={menu} alt="menu button" />
            </Button>

            <Box className={classes.logo}>
              <img src={vitu} alt="vitu logo" />
            </Box>
          </Box>
        </Box>

        <Box className={classes.headerRightSide}>
          <Box
            data-test-id="notifications"
            className={classes.notificationIcon}
          >
            <Badge
              color="secondary"
              variant="dot"
              invisible={false}
              overlap="rectangular"
            >
              <img src={notifications} alt="notifications button" />
            </Badge>
          </Box>

          <Box className={classes.avatarContainer}>
            <Avatar variant="square" className={classes.avatar}>
              V
            </Avatar>
          </Box>
        </Box>
      </Box>
      <Box className={classes.main}>
        <Box className={classes.formFee}>
          <Box className={classes.formFeeHeader}>
            <Typography variant="h1">Confirm Fees</Typography>
            <Box sx={{ marginTop: "12px", marginBottom: "60px" }}>
              <Typography variant="h2">
                Here are the fees requested by the Service Provider to complete this deal. Upon your acceptance, we will hold this amount until you agree the Service Provider has completed the work.
              </Typography>
            </Box>
          </Box>
          <Box className={classes.cardContainer}>
            <Box className={classes.cardPanelContainer}>
              {Object.entries(this.cardPanelInfos).map(([key, value]) => (
                <Card className={classes.cardPanel} key={key}>
                  <Typography variant="body1">{key}</Typography>
                  <Typography variant="body2">{value}</Typography>
                </Card>
              ))}
            </Box>
            <Box style={{ display: 'flex', backgroundColor: '#F0FAFA', borderRadius: '5px', width: '88px', height: '25px', justifyContent: 'space-around', alignItems: 'center', padding: '0px 10px', marginTop:'4px' }}>
              <ThumbUpAltOutlinedIcon style={{ color: '#4B4C4B', width: '15px', height: '15px' }} />
              <Box style={{display:'flex'}}>
              <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight:500 }}>86</Typography>
              <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight:500 }}>%</Typography>
              </Box>
              <Typography style={{ color: '#4B4C4B', fontSize: '12px', fontWeight:500 }}>Positive</Typography>
            </Box>
          </Box>
          <Box className={classes.feeDetailsContainers}>
            <Box className={classes.feeDetailsHeader}>
              <Typography variant="h1" style={{ fontSize: "32px" }}>
                Registration Fee
              </Typography>
            </Box>
            <Box sx={{display:'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                <Typography variant="h3">Registration Fee</Typography>
                <Typography variant="h4">$2,000.00</Typography>
            </Box>
            <Box sx={{display:'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              <Button className={classes.rejectBtn}>Reject</Button>
              <Button className={classes.acceptBtn}>Accept & Hold Fees</Button>
              <Box className={classes.totalFee}>
                <Typography className={classes.totalFeeLabel}>
                  Total Fee
                </Typography>
                <Typography className={classes.totalFeeValue}>
                  $2,000.00
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  </ThemeProvider>
  }
}

export default withStyles(styles)(ConfirmFeesDealer);
// Customizable Area End
