import React from "react";
// Customizable Area Start
import {
  TextField,
  Checkbox,
  FormControl,
  FormControlLabel,
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  InputAdornment,
  Grid,
  Avatar,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Modal,
  Dialog,
  DialogContent,
  DialogActions,
  Drawer,
  Link,
  Chip,
  Divider} from "@material-ui/core";
import { MuiThemeProvider, styled, createTheme } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import { arrow, infoIcon, logo, mapIcon, profileBackground, searchIcon, emptyProfile, emptyCheckbox, filledCheckbox, flagUsa, arrowTop, fileUpload, pdfFile, closeBtn, finalbg, jpegFile, pngFile, blackClose,closeIcon, notifications, arrowRight} from "./assets";
export const configJSON = require("./config");
import 'react-phone-input-2/lib/style.css';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import FastForwardOutlinedIcon from '@material-ui/icons/FastForwardOutlined';
import CreateIcon from '@material-ui/icons/Create';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import Dropzone from 'react-dropzone';
import SignatureCanvas from 'react-signature-canvas';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import PDFViewer from './../../../components/src/PDFViewer.web';
import CloseIcon from '@material-ui/icons/Close';

interface CityType {
  value: string;
  displayValue: string;
}

const tooltipTheme = createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        width: "254px",
        padding: "16px",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 18px 32px 0 rgba(0, 0, 0, 0.25)",

        "& .heading": {
          fontSize: "14px",
          color: "013D4F",
          fontWeight:500
        },
        "& .text": {
          fontSize: "12px",
          color: "#4B4C4B",
          fontWeight:500
        },
        "@media only screen and (max-width: 768px)": {
          maxWidth: "254px",
        }
      },
    },
    MuiCheckbox: {
      root: {
        "&$checked": {
          "color": "#4FC8EC"
        }
      },
    }
  }
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

import ServiceProviderProfilesController, {
  ICreateServiceableLocations,
  Props,
} from "./ServiceProviderProfilesController";
import { Autocomplete } from "@material-ui/lab";
import { Form, Formik } from "formik";
import LicenseDropzone from "../../../../packages/components/src/LicenseDropzone.web";
import ProfileDropdown from "./ProfileDropdown.web";
import HamburgerMenuFull from "./HamburgerMenuFull.web";
import Chat from "../../chat/src/Chat.web";

const commonInputLabelProps = {
  style: { marginLeft: "6px",lineHeight:"18px"},
};

interface Styles {
  [key: string]: any;
}


// Customizable Area End

export default class ServiceProviderProfiles extends ServiceProviderProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
isDragActiveFunction = (isDragActive: boolean,isW9Form:boolean=false) => {
  return (
    isDragActive ?
      <Typography data-test-id="dragTextTest">Drop the file here ...</Typography> :
      <Box style={{ height: "151px", width: "100%", display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "space-between" }}>
        <img src={fileUpload} style={{marginLeft:"10px"}}/>
        <Box style={{ height: "77px", paddingBottom: "10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center" }}>
          <Typography className="gothamText" style={{ fontWeight: 300, fontSize: "24px", color: "#013D4F", marginLeft: '10px',lineHeight:"32px" }}>Drag & Drop</Typography>
          <Typography style={{ marginTop: "10px", fontSize: "14px", fontWeight: 500, color:"#4B4C4B",lineHeight:"22px"}}>or click here to browse your files</Typography>
        </Box>
        <Typography style={{ fontWeight: 500, fontSize: "12px", color:"#4B4C4B",lineHeight:"18px"}}>{isW9Form ? "(Please upload the file in only .pdf formats)":"(Please upload the file in only .jpg & .png formats)"}</Typography>
      </Box>
  )
}

isDragActiveFunctionVoidedCheck = (isDragActive: boolean) => {
  return (
    isDragActive ?
      <Typography data-test-id="dragTextTest">Drop the file here ...</Typography> :
      <Box style={{ height: "151px", width: "100%", display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: "space-between" }}>
        <img src={fileUpload} style={{marginLeft:"10px"}}/>
        <Box style={{ height: "77px", paddingBottom: "10px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center" }}>
          <Typography className="gothamText" style={{ fontWeight: 300, fontSize: "24px", color: "#013D4F", marginLeft: '10px' }}>Drag & Drop</Typography>
          <Typography style={{ marginTop: "10px", fontSize: "14px", fontWeight: 500, color:"#4B4C4B",lineHeight:"22px"}}>or click here to <span style={{color:"#013D4F"}}>browse</span> your files</Typography>
        </Box>
        <Typography style={{ fontWeight: 500, fontSize: "12px", color:"#4B4C4B"}}>(You may upload .jpg, .png, or .pdf formats)</Typography>
      </Box>
  )
}

BpIcon = styled('span')({
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  boxShadow: 'inset 0 0 0 1px gray, inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#fff',
});

 BpCheckedIcon = styled(this.BpIcon)({
  backgroundColor: '#4FC8EC',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  boxShadow: 'inset 0 0 0 2px #4FC8EC, inset 0 -2px 0 rgba(16,22,26,.1)',
  '&::before': {
    display: 'block',
    width: '20px',
    height: '20px',
    backgroundImage: 'radial-gradient(#FFFFFF,#FFFFFF 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#4FC8EC',
  },
});

getDate() {
  const date = new Date();
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const hour12 = hours % 12 || 12;

  return `${month} ${day}, ${year} on ${hour12}:${minutes}`;
}

handleDisableFinishButton() {
  const { isSignCheck, uploadFullSignature, uploadIntitalSignture, drawSignature } = this.state.taxInfoDetails;
  const { activeTab } = this.state;
  if (!isSignCheck) return true;

  const isTab3Ready = uploadFullSignature.length > 0 && uploadIntitalSignture.length > 0 && activeTab === 'tab3';
  const isTab2Ready = drawSignature.length > 0 && activeTab === 'tab2';

  return !(isTab3Ready || isTab2Ready);
}

clearSignature = () => {
  this.spDrawSignCanvasRef.current?.clear();
  this.setState((prevState) => ({
    taxInfoDetails: {
      ...prevState.taxInfoDetails,
      drawSignature: ""
    }
  }));
};

saveSignature = () => {
  const { activeTab } = this.state;
  
  this.setState((prevState) => {
    const updatedDetails = { ...prevState.taxInfoDetails };
    
    if (activeTab === 'tab2') {
      updatedDetails.drawSignature = this.state.taxInfoDetails.drawSignature;
      updatedDetails.uploadFullSignature = this.state.taxInfoDetails.drawSignature;
      updatedDetails.uploadIntitalSignture = '';
    } else if (activeTab === 'tab3') {
      updatedDetails.drawSignature = '';
    }
    this.setState({isEsignFinish:true})
    return { taxInfoDetails: updatedDetails };
  });
  this.handleSignatureClose();

};

endDrawSignature = () => {
  if (!this.spDrawSignCanvasRef.current) return;

  const dataURL = this.spDrawSignCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');

  this.setState((prevState) => ({
    taxInfoDetails: {
      ...prevState.taxInfoDetails,
      drawSignature: dataURL,
    },
  }));
};

renderStepContent = (stepNumber:number) => {
  const isActive = this.state.step === stepNumber;
  const isDone = this.state.doneSteps.includes(stepNumber) && !isActive;
  const isSkipped = this.state.skippedSteps.includes(stepNumber) && !isActive;
  if (isActive) return <img src={arrow} alt="arrow" />
      else if (isDone || isSkipped) {
        return <CreateIcon style={{ width: '24px', height: '24px', color:"#DEE4E2" }} />
        } else {
        return stepNumber
      }
}

renderNavItem = (stepNumber: number, title: string, subtitle: string) => {
  const isActive = this.state.step === stepNumber;
  const isDone = this.state.action === 'add-serviceable-location' || this.state.doneSteps.includes(stepNumber) && !isActive;
  const isSkipped = this.state.skippedSteps.includes(stepNumber) && !isActive;
  let isClickable = stepNumber <= (1 + Math.max(...this.state.doneSteps, ...this.state.skippedSteps));
  if((this.state.action === 'add-serviceable-location' || this.state.action === 'edit-serviceable-location') && stepNumber !== 2){
    isClickable = false;
  }
  const boxStyle = () => {
    let style = 'stepItem';
    if (isActive) {
      style += ' active';
    }
    else if (isDone) {
      style += ' done'
    }
    else if (isSkipped) style += ' skipped';
    if (isClickable) style += ' clickable';
    return style;
  };
  return (
    <Box
      key={stepNumber}
      className={boxStyle()}
      onClick={() => isClickable && this.changeStep(stepNumber)}
      id={`step${stepNumber}`}
    >
      <Box className="stepText">
        <Box className="stepTitle">
          <Typography style={{
            fontSize:"20px", 
            fontWeight:500, 
            textWrap:"nowrap"
          }}>
              {title}</Typography>
          {isDone && <CheckCircleOutlinedIcon style={{ color: '#26BBB3', width:"20px", height:"20px", marginBottom:"4px" }} />}
          {isSkipped && <FastForwardOutlinedIcon style={{ color: '#F05E22', marginBottom:"4px" }} />}
        </Box>
        <Typography className="stepSubtitle">{subtitle}</Typography>
      </Box>
      <Box style={{display:"flex" ,justifyContent:"center" , alignItems:"center" , width:"100%" , maxWidth:"48px" , height:"48px" }}>
      <Box className="stepNumber">
      {this.renderStepContent(stepNumber)}
      </Box>
      </Box>
    </Box>
  );
};
renderContent = () => {
  switch (this.state.step) {
    case 1:
      return this.step1();
    case 2:
      return this.step2();
    case 3:
      return this.step3();
    case 4:
      return this.step4();
    case 5:
      return this.step5();
   
  }
};

renderDrawer = ( ) => {
  return (
    <Box className="spInfoForm">
         <Drawer anchor={"right"} open={this.state.isOpen}>
            <Box style={{ width: "768px", height: "100%", display: 'flex', justifyContent: "center", alignItems: "center" }}>
              <Box style={{ width: `${this.state.serviceProviderProDetails ? '512px' : '460px'}`, height: "308px", display: 'flex', justifyContent: "space-between", alignItems: "center", flexDirection: 'column' }}>
                <Box style={{ width: "140px", height: "140px" }}>
                  <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item>
                      <Box style={{ width: '140px', height: '140px', borderRadius: '24px', textAlign: 'center', backgroundImage: `url(${finalbg})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {this.state.serviceProviderProDetails.profilePhoto
                          ?
                          <Avatar
                            variant="square"
                            className="avatar"
                            src={this.state.serviceProviderProDetails.profilePhoto}
                            style={{ width: '100%', height: '100%', backgroundColor: "white", borderRadius: '24px' }} />
                          :
                          (
                            <>
                              {this.state.serviceProviderProDetails.initials
                                ?
                                (
                                  <Avatar variant="square"
                                    style={{ width: '130px', height: '130px', borderRadius: '24px', backgroundColor: 'rgba(240,250,250)', fontWeight: 500, color: '#013D4F', fontSize: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                  >
                                    <Typography style={{ fontSize: '45px' }}>
                                      {this.state.serviceProviderProDetails.initials}
                                    </Typography>
                                  </Avatar>
                                )
                                :
                                null
                              }
                            </>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', marginTop: "35px" }}>
                  <Typography style={{ fontSize: "18.75px", fontWeight: 300, color: "#013D4F" }} className="gothamtext" data-test-id="serviceProviderCreatedTextId">{this.state.action === 'add-serviceable-location' ? 'Serviceable Location Created' : 'Service Provider Created'}</Typography>
                  <Typography style={{ fontWeight: 500, fontSize: `${this.state.serviceProviderProDetails ? "14px" : "12px"}`, color: "#4B4C4B", textAlign: 'center', marginTop: "6px" }}>
                    {this.state.action === 'add-serviceable-location'? `You have added ${this.state.serviceableLocationsList.length - this.state.oldServiceableLocationsList.length} more serviceable locations.` : `Setup complete! Invite other users, create more serviceable locations, or visit your home dashboard.`}
                  </Typography>
                </Box>
                <Box style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "35px" }}>
                  <Box style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <Button onClick={this.navigateSpToDashboard} data-test-id="goToDashboardButtonTestId" style={{ width: "47.5%", height: "56px", border: "0.78px solid #C5CBC9", borderRadius: "8px" }}><Typography style={{ fontWeight: 500, fontSize: "12.5px", color: "#4B4C4B", textTransform: "none" }}>{this.state.action === 'add-serviceable-location' ? 'Invite User' : 'Go to Dashboard'}</Typography></Button>
                    <Button style={{ width: "47.5%", height: "56px", backgroundColor: "#4FC8EC", borderRadius: "8px" }} onClick={this.navigateSpToCreateDeal} data-test-id="createDealButtonTestId"><Typography style={{ fontWeight: 500, fontSize: "12.5px", color: "white", textTransform: "none" }}>
                    {this.state.action === 'add-serviceable-location' ? 'Create Serviceable Location' : 'Invite User'}</Typography></Button>
                  </Box>
                  {this.state.action === 'add-serviceable-location' &&
                    <Button style={{ marginTop: "5px", height: "56px", width: "244px" }}>
                      <Typography style={{ fontWeight: 500, fontSize: "14px", color: "#013D4F", textTransform: "none" }} onClick={this.navigateToDashboard} data-test-id="goToDashboardButtonTestId">Go to Dashboard</Typography>
                    </Button>
                  }
                </Box>
              </Box>
            </Box>
          </Drawer>
          <div style={{ display: "flex",  alignItems: "center", justifyContent: "center", width: "100%" }}>
            <Typography
              style={{ color: "#C43937", fontSize: "14px" }}>
              {this.state.errorsTaxInfoBackend}
            </Typography>
          </div>
            </Box>
  )
}

renderAutoCompleteAddress = () => {
  return (
    <Autocomplete
      fullWidth
      options={this.state.spAddressPredictions}
      getOptionLabel={(option) => option.description}
      data-test-id="sp_address"
      popupIcon={null}
      onInputChange={(event, newInputValue) => {
        this.spFetchPredictions(newInputValue);
      }}
      onChange={(event, newValue) => {
        if (newValue) {
          this.spHandlePlaceSelect(newValue.place_id);
        }
      }}
      PaperComponent={(props) => (
        <StyledPaper data-test-id="paperStyledId" {...props} elevation={8} style={{ fontWeight: 400, fontSize: "16px" }} />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          placeholder="Search Service Provider Address"
          data-test-id="sp_address_text"
          style={{ width: "100%" }}
          value={this.state.serviceProviderProDetails.sp_address}
          onChange={(event) => this.handleAddressChange(event)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="end" style={{ cursor: "pointer" }}>
                  <img src={searchIcon} alt="map" id="searchIcon" />
                </InputAdornment>
              </>
            ),
            endAdornment: (
              <>
                <InputAdornment position="end" style={{ cursor: "pointer" }}>
                  <img src={mapIcon} alt="map" id="mapIcon" />
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
    />
  )
}

renderAutocompleteAddressShipping = () => {
  return (
    <Autocomplete
      fullWidth
      options={this.state.spShippingAddressPredictions}
      getOptionLabel={(option) => option.description}
      disabled={this.state.isSameAddress}
      data-test-id="sp_shipping_address"
      popupIcon={null}
      onInputChange={(event, newInputValue) => {
        this.fetchPredictionsShipping(newInputValue);
      }}
      onChange={(event, value) => {
        if (value) {
          this.handlePlaceSelectShipping(value.place_id);
        }
      }}
      PaperComponent={(props) => (
        <StyledPaper data-test-id="paperStyledShippingId" {...props} elevation={8} style={{ fontWeight: 400, fontSize: "16px" }} />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search Service Provider Shipping Address"
          data-test-id="sp_shipping_address_text"
          onChange={this.handleServiceProviderShippingAddressChange}
          value={this.state.serviceProviderProDetails.sp_shipping_address}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="end" style={{ cursor: "pointer" }}>
                  <img src={searchIcon} alt="map" id="searchIcon" />
                </InputAdornment>
              </>
            ),
            endAdornment: (
              <>
                <InputAdornment position="start" style={{ cursor: "pointer" }}>
                  <img src={mapIcon} alt="map" id="mapIcon" />
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
    />
  )
}

renderAutocompleteAddressBank = () => {
  return (
    <Autocomplete
      fullWidth
      options={this.state.spBankAddressPredictions}
      getOptionLabel={(option) => option.description}
      data-test-id="bank_address"
      popupIcon={null}
      onInputChange={(event, newInputValue) => {
        this.fetchPredictionsBank(newInputValue);
      }}
      onChange={(event, value) => {
        if (value) {
          this.handlePlaceSelectBank(value.place_id);
        }
      }}
      PaperComponent={(props) => (
        <StyledPaper data-test-id="paperStyledBankId" {...props} elevation={8} style={{ fontWeight: 400, fontSize: "16px" }} />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search Bank Address"
          data-test-id="bank_address_text"
          onChange={this.handleBankAddressChange}
          value={this.state.spThirdFormAddressDetails.address}
          InputProps={{
            ...params.InputProps,
            startAdornment: (<>
                <InputAdornment position="end" 
                  style={{ cursor: "pointer" }}>
                  <img 
                    src={searchIcon} 
                    alt="map" 
                    id="searchIcon" 
                  />
                </InputAdornment>
              </>
            ),
            endAdornment: (
              <>
                <InputAdornment position="start" 
                style={{ cursor: "pointer" }}>
                  <img 
                    src={mapIcon} 
                    alt="map" 
                    id="mapIcon" />
                </InputAdornment>
              </>
            ),}}
        />
      )}
    />
  )
}

renderThirdFormVoidedCheck = () => {
  return (
    <Box style={{ position: "relative", marginTop: "20px" }}>
      <Box className="serviceDetailsBox">
        <Box>
          <Typography className="ServiceProviderText" style={{ marginBottom: "10px" }}>Voided check</Typography>
          <Typography style={{ fontWeight: 500, fontSize: "14px", color: "#4B4C4B" }}>Please upload a voided check for bank verification</Typography>
        </Box>
        <Box style={{ position: "relative" }}>
          <Box style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: 'center', position: "relative", marginTop: "10px" }}>
            <Box style={{ width: "100%", height: "196px", marginTop: "20px" }}>
              <Dropzone onDrop={this.onDropVoidedCheck} multiple={false} data-test-id="dropZoneVoidedCheckTestId"
                accept={{
                  'image/jpeg': ['.jpg', '.jpeg'],
                  'image/png': ['.png'],
                  'application/pdf': ['.pdf']
                }}
              >
                {({ getRootProps, isDragActive, getInputProps, }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      height: "196px",
                      backgroundColor: isDragActive ? "#f0f0f0" : "transparent",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      border: "1px solid #C5CBC9",
                      borderRadius: "8px",
                      cursor: "pointer",
                      color: "black",
                    }}
                  >
                    <input {...getInputProps()} />
                    {this.state.voidedCheckFile ? (
                      <Box className="test12" style={{ height: "171px", width: "100%", display: "flex", flexDirection: 'column' }}>
                        <Box className="test123" style={{ width: "100%", height: "11.67px", marginTop: "10px" }}>
                          <div data-test-id="removeTaxPhotoDiv" style={{ textAlign: 'right' }}>
                            <button data-test-id="removeTaxPhotoVoidedCheck" onClick={this.removeFileVoidedCheck} 
                            style={{ width: "20px", height: "20px", marginRight: "20px", border: "0px", backgroundColor: 'white' }}><img src={closeIcon} style={{ width: "11.67px", height: "11.67px", color:"#4B4C4B" }} /></button>
                          </div>
                        </Box>
                        <Box style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        {this.voidedCheckDisplayImage()}
                          <Typography style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", marginTop: "10px" }}>{this.state.voidedCheckFile.name}</Typography>
                        </Box>
                      </Box>
                    ) : this.isDragActiveFunctionVoidedCheck(isDragActive)}
                  </div>
                )}
              </Dropzone>
              <Box style={{ position: "absolute", top: 4, left: 17, backgroundColor: "white", padding: "0px 4px" }}><Typography style={{ color: "#4B4C4B", fontWeight: 500, fontSize: "12px" }}>Upload Voided check</Typography></Box>
            </Box>
          </Box>
          <Tooltip title={<><p className="heading">Upload Voided check</p><p className="text">Upload an image of a voided check or supporting bank documents.</p></>} className="infoTextVoidedCheck" arrow>
            <IconButton aria-label="info" style={{ top:-2}}>
              <img src={infoIcon} alt="info" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  )
};

errorBox = () => {
  if (this.state.photoErrorMessage) {
    return (
      <Typography style={{ color:'#C43937', marginTop:'7px', marginLeft:'4px', fontSize:'12px' }}>
        {this.state.photoErrorMessage}
      </Typography>
    );
  } else {
    return null;
  }
}

areObjectsEqual = (obj1:ICreateServiceableLocations ,obj2:CityType )  => {
  return obj1.stateName===obj2.displayValue
};

  getServicibleLocationStateName = () => {
    return this.state.serviceProviderProDetails.test.filter((serviceLocation1) => !this.state.serviceableLocationsList.some(serviceLocation2 => this.areObjectsEqual(serviceLocation2, serviceLocation1))
    ).map(({ displayValue, stateISOCode }) => ({
      label: displayValue,
      stateISOCode,
    }));
  };

  voidedCheckDisplayImage = () => {
    const mimeToExtensionMap: { [key: string]: string } = {
      'application/pdf': pdfFile,
      'image/jpeg': jpegFile,
      'image/jpg': jpegFile,
      'image/png': pngFile,
      'NA': jpegFile
    };
  
    const { voidedCheckFile } = this.state;
    const type = (voidedCheckFile && voidedCheckFile.type) || 'NA';
    const fileToDisplay = mimeToExtensionMap[type] || mimeToExtensionMap['NA'];
    return voidedCheckFile ? <img src={fileToDisplay} /> : null;
  };

  step1 = () => {
    return (
      <Box className="spInfoForm">
        <Box className="headerForm">
          <Box className="headingForm">
            <Typography className="titleForm gothamText">Provider Infomation</Typography>
            <Typography className="subtitleForm" style={{fontFamily: "Roboto, sans-serif"}}>
            Tell us about your company
            </Typography>
          </Box>
          {this.state.action !== 'edit-service-provider' && <Button className="skip" style={{marginTop:"10px"}} data-test-id="skip" variant="outlined" onClick={this.handleSkip}>
            Skip
          </Button>}
        </Box>
        <Box className="form" component="form">
          <MuiThemeProvider theme={tooltipTheme}>
            <Box className="serviceDetailsBox">
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography className="ServiceProviderText">Service Provider Details</Typography>
                <Box style={{ width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={this.toggleExpand} data-test-id="accordion">
                  <img src={arrowTop} style={{ width: "24px", height: "24px", transform: this.state.isExpanded ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'transform 0.5s ease-in-out' }} />
                </Box>
              </Box>
              <Box className="collapsibleContent" style={{ maxHeight: this.state.isExpanded ? "2000px" : "0px", overflow: "hidden", transition: 'max-height 0.5s ease-in-out' }}>
              <Grid style={{ display: 'flex', flexDirection: 'row', marginBottom: "20px" }}>
                <Grid item>
                  <Box style={{ height: '98px', width: '98px', borderRadius: '24px', textAlign: 'center', backgroundImage: `url(${emptyProfile})`, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {this.state.serviceProviderProDetails.profilePhoto ?
                      <Avatar className="avatar" variant="square" src={this.state.serviceProviderProDetails.profilePhoto} style={{ width: '100%', height: '100%', borderRadius: '24px', backgroundColor: 'rgba(240,250,250)' }} />
                      :
                      (
                        <>
                          {this.state.serviceProviderProDetails.initials ?
                            (
                              <Avatar
                                variant="square"
                                style={{ width: '90px', height: '90px', backgroundColor: 'rgba(240,250,250)', borderRadius: '24px', color: '#013D4F', fontSize: '32px', fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                              >
                                {this.state.serviceProviderProDetails.initials}
                              </Avatar>
                            ) :
                            null
                          }
                        </>
                      )
                    }
                  </Box>
                </Grid>
                <Grid item style={{ marginLeft: '20px', width: '400px' }}>
                  <Box>
                    {this.state.serviceProviderProDetails.profilePhoto ?
                      (<Box>
                        <Button component="label" variant="outlined" style={{ color: '#013D4F', marginTop: '10px', border: '1px solid #013D4F', borderRadius: '8px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>{configJSON.spUploadphoto}</Typography>
                          <input
                            type="file"
                            hidden
                            onChange={(event) => { this.handlePhotoChange(event) }}
                            accept=".jpg,.jpeg,.png"
                          />
                        </Button>

                        <Button data-test-id="delete-picture-button" onClick={this.handleDeletePicture} variant="outlined" style={{ color: '#C43937', border: '1px solid #C43937', borderRadius: '8px', marginTop: '10px', marginLeft: '10px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>{configJSON.removephoto}</Typography></Button>
                      </Box>)
                      : (
                        <Box>
                          <Button data-test-id="photoChangeTestId" component="label" variant="outlined" style={{ color: '#013D4F', border: '1px solid #013D4F', borderRadius: '8px', marginTop: '10px' }}><Typography style={{ textTransform: 'none', fontWeight: 700, fontSize: '16px' }}>{configJSON.spUploadphoto}</Typography>
                            <input
                              type="file"
                              hidden
                              onChange={(event) => this.handlePhotoChange(event)}
                              accept=".jpg,.jpeg,.png"
                            />
                          </Button>
                        </Box>)
                    }
                    {this.errorBox()}
                  </Box>
                  <DetailBox >
                    <Typography style={{ fontSize: '12px', color: '#C5CBC9', marginTop: '12px', fontWeight: 400 }} color="secondary">{configJSON.spPhotodetail}</Typography>
                  </DetailBox>
                </Grid>
              </Grid>

              <TextField name="business_name" className="business_name" data-test-id="business_name" id="name" label="Business Name" variant="outlined" fullWidth onChange={this.spHandleNameChange} value={this.state.serviceProviderProDetails.sp_business_name}
                inputProps={{
                  style: { borderRadius: "5px" },
                  maxLength: 50
                }}
                InputLabelProps={{
                  style:{marginLeft:"6px"}
                }}
              />
              <Box style={{ marginTop: "20px" }}>
                <Typography className="ServiceProviderText">Business Address</Typography>
                <Box style={{ position: "relative", marginTop: "20px", height: "78px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                  {this.renderAutoCompleteAddress() }
                  <Tooltip title={<><p className="heading">Search Service Provider Address</p><p className="text">Registered business address</p></>} className="infoText" arrow>
                    <IconButton aria-label="info">
                      <img src={infoIcon} alt="info" />
                    </IconButton>
                  </Tooltip>
                </Box>
                  <TextField
                    name="street_address"
                    id="street_address"
                    label="Street Address"
                    data-test-id="sp_street_address"
                    variant="outlined"
                    className="textfield-input-label"
                    fullWidth onChange={this.handleStreetAddressChange}
                    value={this.state.serviceProviderProDetails.sp_street_address}
                    InputLabelProps={{
                      style:{marginLeft:"6px"}
                    }}
                  />
                  <Box style={{ display: "flex", marginTop: "20px", flexDirection: "column", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                    <FormControl variant="outlined" fullWidth style={{ width: "48%" }}>
                      <InputLabel id="state-label" style={{marginLeft:"6px",fontWeight:500}}>State</InputLabel>
                      <Select
                        labelId="state-label"
                        id="state"
                        data-test-id="sp_state"
                        name="state"
                        value={this.state.serviceProviderProDetails.sp_state}
                        onChange={(event) => this.handleStateChange(event)}
                        label="State"
                        IconComponent={ExpandMoreRoundedIcon}
                      >
                        {this.state.serviceProviderProDetails.test.map((state) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.displayValue}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth style={{ width: "48%" }}>
                      <InputLabel id="city-label" style={{marginLeft:"6px"}}>City</InputLabel>
                      <Select
                        labelId="city-label"
                        id="city"
                        data-test-id="sp_city"
                        name="city"
                        value={this.state.serviceProviderProDetails.sp_city}
                        onChange={(event) => this.handleCityChange(event)}
                        label="City"
                        IconComponent={ExpandMoreRoundedIcon}
                      >
                        {this.state.serviceProviderProDetails.testCities.length > 0 ? (
                          this.state.serviceProviderProDetails.testCities.map((city: CityType) => (
                            <MenuItem key={city.value} value={city.value}>
                              {city.displayValue}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled>
                            No cities available
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                    <TextField
                      name="zipCode"
                      id="zipCode"
                      label="Zip Code"
                      data-test-id="sp_zipCode"
                      variant="outlined"
                      fullWidth onChange={this.handleZipCodeChange}
                      value={this.state.serviceProviderProDetails.sp_zipCode}
                      style={{ width: "48%" }}
                      InputProps={{
                        style: { borderRadius: "8px" }
                      }}
                      inputProps={{
                        maxLength: 5
                      }}
                      InputLabelProps={commonInputLabelProps}
                      error={this.returnZipCodeErrors()}
                      helperText={this.returnZipCodeHelperText() ? 'Zip Code must be 5 digits' : ''}
                    />
                    <TextField
                      name="ZipCodeEXT"
                      id="ZipCodeEXT"
                      data-test-id="sp_zipCodeEXT"
                      label="Zip Code EXT (optional)"
                      variant="outlined"
                      fullWidth onChange={this.handleZipCodeEXTChange}
                      value={this.state.serviceProviderProDetails.sp_zipCodeEXT}
                      style={{ width: "48%" }}
                      InputProps={{
                        style: { borderRadius: "8px" }
                      }}
                      inputProps={{
                        maxLength: 4
                      }}
                      InputLabelProps={commonInputLabelProps}
                      error={this.returnZipCodeEXTErrors()}
                      helperText={this.returnZipCodeEXTHelperText() ? 'Zip Code EXT must be 4 digits' : ''}
                    />
                  </Box>
                </Box>
              </Box>

              <Box style={{ marginTop: "20px" }}>
                <Typography className="ServiceProviderText">Business Shipping Address</Typography>
                <Box style={{ position: "relative", marginTop: "20px", height: "78px", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                  {this.renderAutocompleteAddressShipping() }
                  <Tooltip title={<><p className="heading">Search Service Provider Shipping Address</p><p className="text">Address you want to receive documents and indicia</p></>} className="infoText" arrow>
                    <IconButton aria-label="info">
                      <img src={infoIcon} alt="info" />
                    </IconButton>
                  </Tooltip>
                </Box>
                <TextField
                  name="street_address"
                  id="street_address"
                  disabled={this.state.isSameAddress}
                  label="Street Address"
                  data-test-id="sp_shipping_street_address"
                  variant="outlined"
                  fullWidth 
                  onChange={this.handleSpShippingStreetAddressChange}
                  value={this.state.serviceProviderProDetails.sp_shipping_street_address}
                  inputProps={{
                    style: { borderRadius: "8px" }
                  }}
                  InputLabelProps={commonInputLabelProps}
                />
                <Box style={{ display: "flex", marginTop: "20px", flexDirection: "column", justifyContent: "space-between" }}>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                    <FormControl variant="outlined" fullWidth style={{ width: "48%" }}>
                      <InputLabel id="state-label" style={{marginLeft:"6px"}}>State</InputLabel>
                      <Select
                        labelId="state-label"
                        id="state"
                        disabled={this.state.isSameAddress}
                        name="state"
                        data-test-id="sp_shipping_state"
                        value={this.state.serviceProviderProDetails.sp_shipping_state}
                        onChange={(event) => this.handleSpShippingStateChange(event)}
                        label="State"
                        IconComponent={ExpandMoreRoundedIcon}
                      >
                        {this.state.serviceProviderProDetails.test.map((state) => (
                          <MenuItem key={state.value} value={state.value}>
                            {state.displayValue}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth style={{ width: "48%" }}>
                      <InputLabel id="city-label" style={{marginLeft:"6px"}}>City</InputLabel>
                      <Select
                        labelId="city-label"
                        id="city"
                        disabled={this.state.isSameAddress}
                        data-test-id="sp_shipping_city"
                        name="city"
                        value={this.state.serviceProviderProDetails.sp_shipping_city}
                        onChange={(event) => this.handleSpShippingCityChange(event)}
                        label="City"
                        IconComponent={ExpandMoreRoundedIcon}
                      >
                        {this.state.serviceProviderProDetails.testShippingCities.length > 0 ? (
                          this.state.serviceProviderProDetails.testShippingCities.map((city: CityType) => (
                            <MenuItem key={city.value} value={city.value}>
                              {city.displayValue}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="" disabled>
                            No cities available
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                    <TextField
                      name="zipCode"
                      id="zipCode"
                      label="Zip Code"
                      data-test-id="sp_shipping_zipCode"
                      disabled={this.state.isSameAddress}
                      variant="outlined"
                      fullWidth onChange={this.handleSpShippingZipCodeChange}
                      value={this.state.serviceProviderProDetails.sp_shipping_zipCode}
                      error={this.returnShippingZipCodeErrors()}
                      helperText={this.returnShippingZipCodeHelperText() ? 'Must be at least 5 digits' : ''}
                      style={{ width: "48%" }}
                      inputProps={{
                        style: { borderRadius: "8px" },
                        maxLength: 5
                      }}
                      InputLabelProps={{
                        style:{marginLeft:"6px"}
                      }}
                    />
                    <TextField
                      name="ZipCodeEXTShipping"
                      id="ZipCodeEXTShipping"
                      label="Zip Code EXT (optional)"
                      disabled={this.state.isSameAddress}
                      variant="outlined"
                      error={this.returnShippingZipCodeEXTErrors()}
                      helperText={this.returnShippingZipCodeEXTHelperText() ? 'Zip Code EXT must be 4 digits' : ''}
                      data-test-id="sp_shipping_zipCodeEXT"
                      fullWidth onChange={this.handleSpShippingZipCodeEXTChange}
                      value={this.state.serviceProviderProDetails.sp_shipping_zipCodeEXT}
                      style={{ width: "48%" }}
                      inputProps={{
                        style: { borderRadius: "8px" },
                        maxLength: 4
                      }}
                      InputLabelProps={{
                        style: {marginLeft: "6px"}
                      }}
                    />
                  </Box>
                </Box>

                <FormControlLabel
                  style={{ marginTop: "8px", fontWeight: 400, fontSize: "14px" }}
                  control={
                    <Checkbox checked={this.state.isSameAddress} color="primary" onChange={this.handleIsSameAddress} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                  }
                  name="isSameAddress"
                  data-test-id="isSameAddress"
                  className="same gothamText"
                  label={<Typography style={{ fontWeight: 500, fontSize: "14px", color:"#4B4C4B" }}>Same as Business Address</Typography>}
                />

                <Box style={{ position: "relative", marginTop: "20px" }}>
                  <TextField
                    label="Business Credentials (e.g. License Number)"
                    variant="outlined"
                    fullWidth
                    data-test-id="business_credentials"
                    style={{ position: 'relative' }}
                    error={this.returnBusinessCredentialsErrors()}
                    helperText={this.returnBusinessCredentialsErrors() ? 'Business credentials is required' : ''}
                    value={this.state.serviceProviderProDetails.business_credentials}
                    name="business_credentials"
                    id="business_credentials"
                    onChange={this.handleBusinessChange}
                    inputProps={{
                      style: { borderRadius: "8px" },
                      maxLength: 25
                    }}
                    InputLabelProps={commonInputLabelProps}
                  />
                  <Tooltip title={<><p className="heading">Business Credentials (e.g. License Number)</p><p className="text">Credentials to process registration work, if applicable in your processing state</p></>} className="infoText" arrow>
                    <IconButton aria-label="info">
                      <img src={infoIcon} alt="info" id="mapIcon" />
                    </IconButton>
                  </Tooltip>
                </Box>

                <Box style={{ position: "relative" }}>
                  <TextField
                    label="What type of deals do you do?"
                    variant="outlined"
                    data-test-id="textcar"
                    className="textcar"
                    fullWidth
                    style={{ position: 'relative', color:'white', marginTop: "24px" }}
                    InputLabelProps={{
                      style : {fontWeight:500,marginLeft:"6px", }
                    }}
                    disabled={true}
                    InputProps={{
                      style: { color: "#4B4C4B", fontWeight:500 },
                      startAdornment: (
                        <InputAdornment position="start" style={{ cursor: "pointer", marginLeft:"8px" }}>
                          <FormControlLabel
                            value="New Cars"
                            style={{ color: "#4B4C4B",marginRight:"30px" }}
                            className="testt"
                            control=
                            {
                              <Checkbox checked={this.state.serviceProviderProDetails.checkedBoxes.includes("New Cars")} className="newCar" color="primary" onChange={this.handleCheckboxChangeNew} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                            }
                            label={<Typography style={{ fontWeight: 500, fontSize: "16px" }} >New Cars</Typography>}
                          />
                          <FormControlLabel
                            value="Used Cars"
                            className="usedtestt"
                            style={{ color: "#4B4C4B",marginLeft:"0px"}}
                            control={
                              <Checkbox checked={this.state.serviceProviderProDetails.checkedBoxes.includes("Used Cars")} className="usedCars" color="primary" onChange={this.handleCheckboxChangeUsed} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                            }
                            label={<Typography style={{ fontWeight: 500, fontSize: "16px" }} >Used Cars</Typography>}
                          />

                        </InputAdornment>
                      ),
                    }}
                    name="sp_address"
                    id="sp_address"
                  />
                  <Tooltip title={<><p className="heading">What type of deals do you do?</p><p className="text">"Transaction types your dealership performs"</p></>} className="infoTextLast" arrow>
                    <IconButton aria-label="info">
                      <img src={infoIcon} alt="info" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              </Box>
            </Box>
          </MuiThemeProvider>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Typography style={{ fontSize: "14px", color: "#C43937"}}>{this.state.errorServiceProviderBackend}</Typography>
        </div>
        </Box>
      </Box>
    );
  };

  step2 = () => {
    const getIcon = (fileExist: string) => {return fileExist ? <CheckCircleIcon style={{height:"16px",color:"#26BBB3"}}/> : <WarningIcon style={{height:"16px",color:"#F05E22"}}/>}
    return (
      <Box className="spInfoForm">
        <Box className="headerForm">
          <Box className="headingForm">
            <Typography className="titleForm gothamText" data-test-id="serviceableLoacation">Serviceable Locations</Typography>
            <Typography className="subtitleForm" style={{fontFamily:"Roboto"}}>
            States you are authorized to provide services in
            </Typography>
          </Box>
          {this.state.action !== 'edit-service-provider' && <Button className="skip" style={{marginTop:"10px"}} data-test-id="skipSecond" variant="outlined" onClick={this.handleSkip}>
            Skip
          </Button>}
        </Box>
        <Box className="form" component="form">
          <MuiThemeProvider theme={tooltipTheme}>
            <Box className="serviceDetailsBox">
              {!(this.state.action === 'edit-service-provider' && this.state.serviceableLocations.stateName === '') &&
                <>
                  <Box style={{ display: "flex", flexDirection: "column" }}>
                    <Typography className="ServiceProviderText">Provider State</Typography>
                    <FormControl variant="outlined" fullWidth style={{ width: '100%' }}>
                      <Autocomplete
                        data-test-id="searchTextBox"
                        options={this.getServicibleLocationStateName()}
                        value={{ label: this.state.serviceableLocations.stateName, stateISOCode: "" }}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={this.state.label}
                            placeholder="State(s)"
                            variant="outlined"
                            onFocus={this.handleFocus}
                            InputLabelProps={commonInputLabelProps}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <img
                                  src={arrowTop}
                                  style={{
                                    width: "24px",
                                    height: "22px",
                                    transform: 'rotate(180deg)',
                                    marginRight: "9px",
                                    color: "#C5CBC9"
                                  }}
                                  alt="arrow"
                                />
                              ),
                            }}
                          />
                        )}
                        onChange={(event, newValue) => {
                          this.setState((prevState) => ({
                            serviceableLocations: {
                              ...prevState.serviceableLocations,
                              stateName: newValue?.label || "",
                            },
                          }));
                          this.createServicableLocationFormData()
                        }}
                        disableClearable
                        renderOption={(props) => (
                          <li {...props}>
                            <MenuItem key={props.stateISOCode} value={props.label}>
                              {props.label}
                            </MenuItem>
                          </li>
                        )}
                      />
                      <Tooltip
                        title={
                          <>
                            <p className="heading">State</p>
                            <p className="text">State(s) you are licensed to provide registration services in</p>
                          </>
                        }
                        className="infoText"
                        arrow
                      >
                        <IconButton aria-label="info">
                          <img src={infoIcon} alt="info" />
                        </IconButton>
                      </Tooltip>
                    </FormControl>
                    {this.state.serviceableLocations.stateName && (
                      <>
                        <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "24px", gap: "38px" }}>
                          <LicenseDropzone
                            licenseType="businessLicense"
                            onDrop={this.onUploadDocument}
                            file={this.state.serviceableLocations.uploadBusinessLicense}
                            fileString={this.state.serviceableLocations.businessLicense}
                            removeFileData={(e) => this.removeDocumentFile(e,"businessLicense")}
                            fileLabel="Business License"
                          />
                          <LicenseDropzone
                            licenseType="dealerLicense"
                            onDrop={this.onUploadDocument}
                            file={this.state.serviceableLocations.uploadDealerLicense}
                            fileString={this.state.serviceableLocations.dealerLicense}
                            removeFileData={(e) => this.removeDocumentFile(e,"dealerLicense")}
                            fileLabel="Dealer License"
                          />
                        </Box>
                        <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "24px", gap: "38px" }}>
                          <LicenseDropzone
                            licenseType="DMVContract"
                            onDrop={this.onUploadDocument}
                            file={this.state.serviceableLocations.uploadDMVContract}
                            fileString={this.state.serviceableLocations.DMVContract}
                            removeFileData={(e) => this.removeDocumentFile(e,"DMVContract")}
                            fileLabel="DMV Contract"
                          />
                          <LicenseDropzone
                            licenseType="registrationServiceLicense"
                            onDrop={this.onUploadDocument}
                            file={this.state.serviceableLocations.uploadRegistrationServiceLicense}
                            fileString={this.state.serviceableLocations.registrationServiceLicense}
                            removeFileData={(e) => this.removeDocumentFile(e,"registrationServiceLicense")}
                            fileLabel="Registration Service License"
                          />
                        </Box>
                      <Box style={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "24px", gap: "38px" }}>

                      <LicenseDropzone
                            licenseType="bondingRegistration"
                            onDrop={this.onUploadDocument}
                            file={this.state.serviceableLocations.uploadBondingRegistration}
                            fileString={this.state.serviceableLocations.bondingRegistration}
                            removeFileData={(e) => this.removeDocumentFile(e,"bondingRegistration")}
                            fileLabel="Bonding/Insurance Requirements "
                          />
                      </Box>
                      </>
                    )}
                  </Box>
                  <Box style={{ marginTop: "20px" }}>
                    <Typography className="ServiceProviderText">Provider Services</Typography>
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                      <Box component="div"
                        style={{
                          display: 'flex',
                          justifyContent: "space-between",
                          width: '44%',
                          border: '1px solid #C5CBC9',
                          borderRadius: '8px',
                          height: '36px',
                          padding: '10px 10px',
                          alignItems: "center"
                        }}>
                        <Typography style={{ fontWeight: 500, fontSize: "14px" }} >
                          <Checkbox checked={this.state.serviceableLocations.isRunner} className="usedCars" color="primary" onChange={this.handleRunnerCheckBox} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                          Runner
                        </Typography>
                        <Box>
                          <TextField
                            data-test-id="runner"
                            hiddenLabel
                            placeholder="Enter Price"
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            style={{
                              width:"90px",
                              textAlign:"center"
                            }}
                            inputProps={{
                              maxLength:10
                            }}
                            disabled={!this.state.serviceableLocations.isRunner}
                            value={this.state.serviceableLocations.runnerPrice}
                            onChange={this.handleRunnerOnChange}
                          />
                        </Box>
                      </Box>
                      <Box component="div"
                        style={{
                          display: 'flex',
                          justifyContent: "space-between",
                          width: '44%',
                          border: '1px solid #C5CBC9',
                          borderRadius: '8px',
                          height: '36px',
                          padding: '10px 10px',
                          alignItems:"center"
                        }}>
                        <Typography style={{ fontWeight: 500, fontSize: "14px" }} >
                          <Checkbox checked={this.state.serviceableLocations.isFullService} className="usedCars" color="primary" onChange={this.handleFullServiceCheckBox} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                          Full Service
                        </Typography>
                        <Box>
                          <TextField
                            data-test-id="fullService"
                            hiddenLabel
                            placeholder="Enter Price"
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            style={{
                              width:"90px",
                              textAlign:"center"
                            }}
                            inputProps={{
                              maxLength:10
                            }}
                            disabled={!this.state.serviceableLocations.isFullService}
                            value={this.state.serviceableLocations.fullServicePrice}
                            onChange={this.handleFullServiceOnChange}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                      <Box component="div"
                        style={{
                          display: 'flex',
                          justifyContent: "space-between",
                          width: '44%',
                          border: '1px solid #C5CBC9',
                          borderRadius: '8px',
                          height: '36px',
                          padding: '10px 10px',
                          alignItems:"center"
                        }}>
                        <Typography style={{ fontWeight: 500, fontSize: "14px" }} >
                          <Checkbox checked={this.state.serviceableLocations.isWhiteGlove} className="usedCars" color="primary" onChange={this.handleWhiteGloveCheckBox} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                          White Glove
                        </Typography>
                        <Box>
                          <TextField
                            data-test-id="whiteGlove"
                            hiddenLabel
                            placeholder="Enter Price"
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            style={{
                              width:"90px",
                              textAlign:"center"
                            }}
                            inputProps={{
                              maxLength:10
                            }}
                            disabled={!this.state.serviceableLocations.isWhiteGlove}
                            value={this.state.serviceableLocations.whiteGlovePrice}
                            onChange={this.handleWhiteGloveOnChange}
                          />
                        </Box>
                      </Box>
                      <Box component="div"
                        style={{
                          display: 'flex',
                          justifyContent: "space-between",
                          width: '44%',
                          border: '1px solid #C5CBC9',
                          borderRadius: '8px',
                          height: '36px',
                          padding: '10px 10px',
                          alignItems:"center"
                        }}>
                        <Typography style={{ fontWeight: 500, fontSize: "14px" }} >
                          <Checkbox checked={this.state.serviceableLocations.isOnSite} className="usedCars" color="primary" onChange={this.handleOnSiteCheckBox} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                          On Site
                        </Typography>
                        <Box>
                          <TextField
                            data-test-id="onSite"
                            hiddenLabel
                            placeholder="Enter Price"
                            variant="standard"
                            InputProps={{
                              disableUnderline: true,
                            }}
                            style={{
                              width:"90px",
                              textAlign:"center"
                            }}
                            inputProps={{
                              maxLength:10
                            }}
                            disabled={!this.state.serviceableLocations.isOnSite}
                            value={this.state.serviceableLocations.onSitePrice}
                            onChange={this.handleOnSiteOnChange}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", flexDirection: "row", justifyContent: "end", width: "100%",marginTop:"20px" }}>
                    <Button
                      data-test-id="addAnotherState"
                      variant="outlined"
                      disabled={!this.isAddNewStateButton()}
                      onClick={this.addAnotherStateButtonClick}
                      style={{padding:"10px",lineHeight:"24px",fontWeight:700,fontSize:"16px",textTransform:"none",borderRadius:"8px",borderColor:this.isAddNewStateButton() ? "#013D4F" : "#C5CBC9",color:this.isAddNewStateButton() ? "#0F172A" : "#C5CBC9"}}
                    >
                      {this.getAddNewStateButtonText()}
                    </Button>
                  </Box>
                </>
              }
              <Box>
              {this.state.serviceableLocationsList && 
                  <Box style={{ marginTop: "35px"}}>
                  <Typography className="ServiceProviderText">Added States</Typography>
                    {this.state.serviceableLocationsList.map((serviceLocation, index) => (
                  !serviceLocation.isEditServiceable && <Box key={index} style={{ marginTop: "28px", padding: "20px", border: "1px solid #e0e0e0", borderRadius: "8px" }}>
                        <Box style={{display:"flex",justifyContent:"space-between"}}>
                          <Typography variant="h6" style={{ marginBottom: "15px" }}>{serviceLocation.stateName}</Typography>
                          {(this.state.action === 'edit-service-provider' || this.state.isNewServiceProvider) && <Box style={{ display: "flex"}}>
                            <Button data-test-id="editBtn" onClick={()=>this.onEditServiceLocation(serviceLocation.id)}>
                              <EditIcon style={{ height: "24px", color: "#DADADA" }} />
                            </Button>
                            <Button data-test-id="deleteBtn" onClick={()=> this.state.isNewServiceProvider ? this.deleteStateByName(serviceLocation.stateName) : this.deleteAddAnotherState(serviceLocation.id)}>
                              <DeleteOutlineIcon style={{ height: "24px", color: "#DADADA" }} />
                            </Button>
                          </Box>}
                        </Box>
                        <Typography variant="body2" style={{ color: "#C5CBC9",marginBottom:"10px" }}>Documents Uploaded</Typography>
                        <Box style={{width:"75%",display:"flex",flexWrap:"wrap" }}>
                            <Box component="div" key={serviceLocation.stateName}>
                            <Chip
                              icon={getIcon(serviceLocation.businessLicense)}
                              label="Business License"
                              style={{
                                background: "#fff",
                                fontFamily:"Roboto",
                                fontWeight:500,
                                lineHeight:"28px",
                                fontSize:"14px"
                              }}
                            />
                            <Chip
                              icon={getIcon(serviceLocation.dealerLicense)}
                              label="Dealer License"
                              style={{
                                background: "#fff",
                                fontFamily:"Roboto",
                                fontWeight:500,
                                lineHeight:"28px",
                                fontSize:"14px"
                              }}
                            />
                            <Chip
                              icon={getIcon(serviceLocation.DMVContract)}
                              label="DMV Contract"
                              style={{
                                background: "#fff",
                                fontFamily:"Roboto",
                                fontWeight:500,
                                lineHeight:"28px",
                                fontSize:"14px"
                              }}
                            />
                            <Chip
                              icon={getIcon(serviceLocation.registrationServiceLicense)}
                              label="Registration Service License"
                              style={{
                                background: "#fff",
                                fontFamily:"Roboto",
                                fontWeight:500,
                                lineHeight:"28px",
                                fontSize:"14px"
                              }}
                            />
                            <Chip
                              icon={getIcon(serviceLocation.bondingRegistration)}
                              label="Bonding/Insurance Requirements "
                              style={{
                                background: "#fff",
                                fontFamily:"Roboto",
                                fontWeight:500,
                                lineHeight:"28px",
                                fontSize:"14px"
                              }}
                            />
                            </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
              }
              </Box>
            </Box>
          </MuiThemeProvider>
        </Box>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Typography style={{ fontSize: "14px", color: "#C43937"}}>{this.state.errorsSpLocationBackend}</Typography>
        </div>
      </Box>
    );
  }

  step3 = () => {
        return (
      <Box className="spInfoForm">
        <Box className="headerForm">
          <Box className="headingForm">
            <Typography className="titleForm gothamText" data-test-id="contactInfoTestId">Contact Information</Typography>
            <Typography className="subtitleForm" style={{ marginTop: '5px' }}>
              Let us know the best way to contact you
            </Typography>
          </Box>
          {this.state.action !== 'edit-service-provider' && <Button className="skip"style={{marginTop:"10px"}} data-test-id="skipThird" variant="outlined" onClick={this.handleSkip}>
            Skip
          </Button>}
        </Box>
        <Formik
          initialValues={{ fullName: this.state.spContactInfoDetails.fullName, phoneNumber: this.state.spContactInfoDetails.phoneNumber, email: this.state.spContactInfoDetails.email }}
          validationSchema={this.validationSchema}
          validate={values => this.handleFormChange(values)}
          onSubmit={() => this.nextStep()}
          validateOnMount={true}
          key={1}
        >
          {({ errors, touched, handleChange, handleBlur, values, isValid}) => {
            if ((isValid) !== this.state.isContactFormFilled) {
              this.handleContactFormFilled(isValid);
            }

            return (
              <Form onChange={() => this.handleFormChange(values)}
                className="formi">
                <MuiThemeProvider theme={tooltipTheme}>
                  <Box style={{ position: "relative" }}>
                    <Box className="serviceDetailsBox">
                      <Box style={{ position: "relative", marginTop: "20px" }}>
                        <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          data-test-id="fullName"
                          style={{ position: 'relative' }}
                          value={values.fullName}
                          name="fullName"
                          id="fullName"
                          error={touched.fullName && !!errors.fullName}
                          helperText={touched.fullName && errors.fullName}
                          onChange={(event) => this.handleRegexAlpha(event, handleChange)}
                          inputProps={{
                            style: { borderRadius: "8px" },
                            maxLength: 50
                          }}
                          onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                            {
                              this.spHandleFocusCognitive(event)
                            }
                          }}
                          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                            if (this.handleSpBlurr(event)) { this.handleLabelsCss(event.target.labels, "0px") }
                            handleBlur(event);
                          }}
                          InputLabelProps={{
                            style: { color: "rgba(75, 76, 75, 1)", fontWeight: 500,marginLeft: "6px" },
                          }}
                        />
                        <Tooltip title={<><p className="heading">Name</p><p className="text">Your First and Last name.</p></>} className="infoText" arrow>
                          <IconButton aria-label="info">
                            <img src={infoIcon} alt="info" id="mapIcon" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box style={{ position: "relative", marginTop: "20px" }}>
                        <TextField
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          data-test-id="phoneNumber"
                          style={{ position: 'relative' }}
                          value={values.phoneNumber}
                          name="phoneNumber"
                          id="phoneNumber"
                          error={touched.phoneNumber && !!errors.phoneNumber}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          onChange={(event) => this.handleRegex(event, handleChange)}
                          inputProps={{
                            maxLength: 10
                          }}
                          onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                            { this.spHandleFocusCognitive(event) }
                          }}
                          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                            if (this.handleSpBlurr(event)) {
                              this.handleLabelsCss(event.target.labels, "0px")
                            }
                            handleBlur(event);
                          }}
                          InputLabelProps={{
                            style: { color: "rgba(75, 76, 75, 1)", fontWeight: 500,marginLeft:"6px" },
                          }}
                          InputProps={{
                            style: { borderRadius: "8px"},
                            startAdornment: (
                              <InputAdornment position="end" style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                                <img src={flagUsa} style={{ height: "14px", width: "18px" }} />
                                <p style={{ marginLeft: "8px", fontSize: "16px" }}>+</p>
                                <p style={{ fontSize: "16px", marginTop: "18px" }}>1</p>
                                <p style={{ marginLeft: "12px", marginRight: "-8px", fontSize: "16px" }}>|</p>
                              </InputAdornment>
                            )
                          }}
                        />
                        <Tooltip title={<><p className="heading">Phone Number</p><p className="text">Best contact phone number to reach you.</p></>} className="infoText" arrow>
                          <IconButton aria-label="info">
                            <img src={infoIcon} alt="info" id="mapIcon" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box style={{ position: "relative", marginTop: "20px" }}>
                        <TextField
                          label="Email"
                          variant="outlined"
                          fullWidth
                          data-test-id="email"
                          style={{ position: 'relative' }}
                          value={values.email}
                          name="email"
                          id="email"
                          error={touched.email && !!errors.email}
                          helperText={touched.email && errors.email}
                          onChange={handleChange}
                          inputProps={{
                            style: { borderRadius: "8px" },
                            maxLength:70
                          }}
                          InputLabelProps={{
                            style: { color: "rgba(75, 76, 75, 1)", fontWeight: 500,marginLeft: "6px" },
                          }}
                          onFocus={(event: React.FocusEvent<HTMLInputElement>) => {
                            { this.spHandleFocusCognitive(event) }
                          }
                          }
                          onBlur={
                            (event: React.FocusEvent<HTMLInputElement>) => {
                              if (this.handleSpBlurr(event)) {
                                this.handleLabelsCss(event.target.labels, "0px")
                              }
                              handleBlur(event);
                            }
                          }
                        />
                        <Tooltip title={<><p className="heading">Email</p><p className="text">Best contact email to reach you.</p></>} className="infoText" arrow>
                          <IconButton aria-label="info">
                            <img src={infoIcon} alt="info" id="mapIcon" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </MuiThemeProvider>
              </Form>
            );
          }}
        </Formik>
      </Box >
    );
  };

  step4 = () => {
    return (
      <Box className="spInfoForm">
        <Box className="headerForm">
          <Box className="headingForm">
            <Typography className="titleForm gothamText" style={{width:"92%"}} data-test-id="contactInfoTestId">Electronic Fund Transfer Authorization Agreement for Preauthorized Payments</Typography>
            <Typography className="subtitleForm" style={{ marginTop: '5px' }}>
            Provide account details for secure payment processing
            </Typography>
          </Box>
          {this.state.action !== 'edit-service-provider' && <Button className="skip" style={{ marginTop: "40px"}} data-test-id="skip4" variant="outlined" onClick={this.handleSkip}>
            Skip
          </Button>}
        </Box>
        <Formik
          initialValues={{
            bankAccountNumber: this.state.spBankInfoFormDetails.bankAccountNumber,
            confBankAccountNumber: this.state.spBankInfoFormDetails.confBankAccountNumber, 
            routingNumber: this.state.spBankInfoFormDetails.routingNumber, 
            confRoutingNumber: this.state.spBankInfoFormDetails.confRoutingNumber, 
            bankName: this.state.spBankInfoFormDetails.bankName, 
            branch: this.state.spBankInfoFormDetails.branch, 
            name: this.state.spContactInfoDetails?.fullName || this.state.spBankInfoFormDetails.name, 
            phoneNumber: this.state.spContactInfoDetails?.phoneNumber || this.state.spBankInfoFormDetails.phoneNumber, 
            jobTitle: this.state.spBankInfoFormDetails.jobTitle,   
          }}
          validationSchema={this.validationSchemaThirdForm}
          validate={(values)=>{
            this.handleBankInfoFormChange(values)}}
          onSubmit={() => this.nextStep()}
          validateOnMount={true}
          key={2}
        >
          {({ errors, touched, handleChange, handleBlur, values, isValid}) => {
            if ((isValid) !== this.state.isBankInfoFormValid) {
              this.handleBankInfoFormFilled(isValid);
            }
            return (
              <Form 
              onChange={() => this.handleBankInfoFormChange(values)}
              className="formiBank"
              data-test-id="formiBank"
              >
                <MuiThemeProvider theme={tooltipTheme}>
                  <Box style={{ position: "relative" }}>
                    <Box className="serviceDetailsBox" style={{marginBottom:"20px"}}>
                    <Box style={{ display: "flex", justifyContent: "space-between", height:"32px" }}>
                      <Typography className="ServiceProviderText" style={{marginTop:"15px"}}>Bank Details</Typography>
                    </Box>
                      <Box className="collapsibleContent" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", maxHeight: this.state.isExpandedBank ? "2000px" : "0px", overflow: "hidden", transition: 'max-height 0.5s ease-in-out'}}>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <Box style={{ position: "relative", width: "48%", marginTop:"20px" }}>
                            <TextField
                              label="Bank Account Number"
                              variant="outlined"
                              fullWidth
                              data-test-id="bankAccountNumber"
                              style={{ position: 'relative' }}
                              value={values.bankAccountNumber}
                              name="bankAccountNumber"
                              id="bankAccountNumber"
                              inputMode= 'numeric'
                              onBlur={handleBlur}
                              error={this.returnThirdFormBankAccountNumberErrors(touched, errors)}
                              helperText={this.returnThirdFormBankAccountNumberHelperText(touched, errors)}
                              onChange={(event) => this.handleRegex(event, handleChange)}
                              type="tel"
                              inputProps={{
                                style: { borderRadius: "8px", width:"430px" },
                                inputMode: 'numeric', 
                                pattern: "[0-9]",
                                maxLength: 12 
                              }}
                              InputLabelProps={{
                                style:{marginLeft:"6px"}
                              }}
                            />
                            <Tooltip title={<><p className="heading">Bank Account Number</p><p className="text">Enter your bank account number.</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Box style={{ position: "relative", width: "48%", marginTop:"20px" }}>
                            <TextField
                              label="Confirm Bank Account Number"
                              variant="outlined"
                              fullWidth
                              data-test-id="confBankAccountNumber"
                              style={{ position: 'relative' }}
                              value={values.confBankAccountNumber}
                              name="confBankAccountNumber"
                              id="confBankAccountNumber"
                              onBlur={handleBlur}
                              error={this.returnThirdFormConfBankAccountNumberErrors(touched, errors)}
                              helperText={this.returnThirdFormConfBankAccountNumberHelperText(touched, errors)}
                              onChange={(event) => this.handleRegex(event, handleChange)}
                              inputProps={{
                                style: { borderRadius: "8px" },
                                maxLength:12 
                              }}
                              InputLabelProps={{
                                style:{marginLeft:"6px"}
                              }}
                            />
                            <Tooltip title={<><p className="heading">Confirm Bank Account Number</p><p className="text">Confirm your bank account number.</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop:"20px" }}>
                          <Box style={{ position: "relative", width: "48%" }}>
                            <TextField
                              label="Routing Number"
                              variant="outlined"
                              fullWidth
                              data-test-id="routingNumber"
                              style={{ position: 'relative' }}
                              value={values.routingNumber}
                              name="routingNumber"
                              id="routingNumber"
                              onBlur={handleBlur}
                              error={this.returnThirdFormRoutingNumberErrors(touched, errors)}
                              helperText={this.returnThirdFormRoutingNumberHelperText(touched, errors)}
                              onChange={(event) => this.handleRegex(event, handleChange)}
                              inputProps={{
                                style: { borderRadius: "8px" },
                                maxLength: 9
                              }}
                              InputLabelProps={{
                                style:{marginLeft:"6px"}
                              }}
                            />
                            <Tooltip title={<><p className="heading">Routing Number</p><p className="text">Enter your bank routing number.</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Box style={{ position: "relative", width: "48%" }}>
                            <TextField
                              label="Confirm Routing Number"
                              variant="outlined"
                              fullWidth
                              data-test-id="confRoutingNumber"
                              style={{position: 'relative'}}
                              value={values.confRoutingNumber}
                              name="confRoutingNumber"
                              id="confRoutingNumber"
                              onBlur={handleBlur}
                              error={this.returnThirdFormConfRoutingErrors(touched, errors)}
                              helperText={this.returnThirdFormConfRoutingHelpertext(touched, errors)}
                              onChange={(event) => this.handleRegex(event, handleChange)}                              
                              inputProps={{
                                style: { borderRadius: "8px" },
                                maxLength: 9
                              }}
                              InputLabelProps={{
                                style:{marginLeft:"6px"}
                              }}
                            />
                            <Tooltip title={<><p className="heading">Confirm Routing Number</p><p className="text">Confirm your routing number.</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop:"20px" }}>
                          <Box style={{ position: "relative", width: "48%" }}>
                            <TextField
                              label="Bank Name"
                              variant="outlined"
                              fullWidth
                              data-test-id="bankName"
                              style={{ position: 'relative' }}
                              value={values.bankName}
                              name="bankName"
                              id="bankName"
                              onBlur={handleBlur}
                              error={this.returnThirdFormBankNameErrors(touched, errors)}
                              helperText={this.returnThirdFormBankNameHelperText(touched, errors)}
                              onChange={(event) => this.handleRegexAlpha(event, handleChange)}
                              inputProps={{
                                style: { borderRadius: "8px" },
                                maxLength: 100
                              }}
                              InputLabelProps={{
                                style:{marginLeft:"6px"}
                              }}
                            />
                            <Tooltip title={<><p className="heading">Bank Name</p><p className="text">Enter the name of your bank.</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>

                          <Box style={{ position: "relative", width: "48%" }}>
                            <TextField
                              label="Branch"
                              variant="outlined"
                              fullWidth
                              data-test-id="branch"
                              style={{ position: 'relative' }}
                              value={values.branch}
                              name="branch"
                              id="branch"
                              error={this.returnThirdFormBranchErrors(values.branch, touched.branch)}
                              helperText={this.returnThirdFormBranchHelperText(values.branch, touched.branch)}
                              onBlur={handleBlur}
                              onChange={(event) => this.handleRegexAlpha(event, handleChange)}
                              inputProps={{
                                style: { borderRadius: "8px" },
                                maxLength: 100
                              }}
                              InputLabelProps={{
                                style:{marginLeft:"6px"}
                              }}
                            />
                            <Tooltip title={<><p className="heading">Branch</p><p className="text">Enter the branch of your bank if applicable.</p></>} className="infoText" arrow>
                              <IconButton aria-label="info">
                                <img src={infoIcon} alt="info" id="mapIcon" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  
                  </Box>

                  <Box className="serviceDetailsBox">
                    <Box style={{ display: "flex", justifyContent: "space-between", height:"32px"}}>
                      <Typography className="ServiceProviderText" style={{marginTop:"15px"}}>Bank Address</Typography>
                      <Box style={{ width: "32px", height: "32px", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={this.toggleExpandBankAddress} data-test-id="accordionBankAddress">
                        <img src={arrowTop} style={{ transform: this.state.isExpandedBankAddress ? 'rotate(0deg)' : 'rotate(180deg)', width: "32px", height: "32px", transition: 'transform 0.5s ease-in-out'}} />
                      </Box>
                    </Box>

                    <Box className="collapsibleContent" style={{ transition: 'max-height 0.5s ease-in-out', maxHeight: this.state.isExpandedBankAddress ? "1000px" : "0px", overflow:"hidden"}}>
                      <Box style={{paddingTop:"0px"}}>
                        <Box style={{ position: "relative", marginTop: "8px",paddingTop:"16px", height: "78px", overflow: "hidden", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                          {this.renderAutocompleteAddressBank()}
                          <Tooltip title={<><p className="heading">Search Bank Address</p><p className="text">Enter the bank's address.</p></>} className="infoTextBankAddress" arrow>
                            <IconButton aria-label="info">
                              <img src={infoIcon} alt="info" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <TextField
                              label="Street Address"
                              variant="outlined"
                              fullWidth
                              data-test-id="streetAddressThird"
                              style={{ position: 'relative' }}
                              value={this.state.spThirdFormAddressDetails.streetAddress}
                              name="streetAddress"
                              id="streetAddress"
                              onBlur={handleBlur}
                              onChange={(event:any) => {
                                this.handleBankStreetAddressChange(event)
                                handleChange(event)
                              }}
                              inputProps={{
                                style: { borderRadius: "8px" }
                              }}
                              InputLabelProps={{
                                style:{marginLeft:"6px"}
                              }}
                            />
                      <Box style={{ display: "flex", marginTop: "20px", flexDirection: "column", justifyContent: "space-between" }}>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                          <FormControl variant="outlined" fullWidth style={{ width: "48%" }}>
                            <InputLabel id="state-label" style={{paddingLeft:"6px"}}>State</InputLabel>
                            <Select
                              labelId="thirdFormState"
                              id="thirdFormState"
                              label="State"
                              onChange={(event:any) => {
                                this.handleThirdFormStateChange(event)
                                handleChange(event)
                              }}
                              data-test-id="thirdFormState"
                              name="thirdFormState"
                              value={this.state.spThirdFormDetailsNew.thirdFormState}
                              IconComponent={ExpandMoreRoundedIcon}
                            >
                              {this.state.serviceProviderProDetails.test.map((state) => (
                                <MenuItem 
                                  key={state.value} 
                                  value={state.value}>
                                  {state.displayValue}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl 
                            variant="outlined" 
                            fullWidth style={{ width: "48%" }}>
                            <InputLabel id="city-label" style={{paddingLeft:"6px"}}>City</InputLabel>
                            <Select
                              labelId="city-label"
                              data-test-id="dealership_city"
                              id="city"
                              name="city"
                              value={this.state.spThirdFormDetailsNew.thirdFormCity}
                              onChange={(event:any) => {
                                this.handleThirdFormCityChange(event)
                                handleChange(event)
                              }}
                              label="City"
                              IconComponent={ExpandMoreRoundedIcon}
                            >
                              {(this.state.spThirdFormDetailsNew.thirdFormCities).length > 0 ? (
                                this.state.spThirdFormDetailsNew.thirdFormCities.map((city: CityType) => (
                                  <MenuItem 
                                    key={city.value} 
                                    value={city.value}>
                                    {city.displayValue}
                                  </MenuItem>
                                ))
                              ) : (
                                <MenuItem value="" disabled>
                                  No cities available
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                          <TextField
                            name="thirdFormZipCode"
                            id="thirdFormZipCode"
                            label="Zip Code"
                            data-test-id="thirdFormZipCode"
                            variant="outlined"
                            fullWidth
                          onChange={(event:any) => {
                            this.spHandleBankZipCodeChangeCognitive(event)
                            handleChange(event)
                          }}
                            onBlur={handleBlur}
                            value={this.state.spThirdFormAddressDetails.thirdFormZipCode}
                            error={this.returnThirdFormZipCodeErrors()}
                            helperText={this.returnThirdFormZipCodeHelperText()}
                            style={{ width: "48%" }}
                            InputProps={{
                              style: { borderRadius: "8px" }
                            }}
                            inputProps={{
                              maxLength: 5
                            }}
                            InputLabelProps={{
                              style:{marginLeft:"6px"}
                            }}
                          />
                          <TextField
                            name="thirdFormZipCodeEXT"
                            id="thirdFormZipCodeEXT"
                            data-test-id="thirdFormZipCodeEXT"
                            label="Zip Code EXT (optional)"
                            variant="outlined"
                            onBlur={handleBlur}
                            fullWidth
                          onChange={(event:any) => {
                            this.spHandleBankZipCodeEXTChangeCognitive(event)
                            handleChange(event)
                          }}
                            value={this.state.spThirdFormAddressDetails.thirdFormZipCodeEXT}
                            style={{ width: "48%" }}
                            InputProps={{
                              style: { borderRadius: "8px" }                            
                            }}
                            inputProps={{
                              maxLength: 4
                            }}
                            InputLabelProps={{
                              style:{marginLeft:"6px"}
                            }}
                            error={this.returnThirdFormZipCodeEXTErrors()}
                            helperText={this.returnThirdFormZipCodeEXTHelperText()}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box className="serviceDetailsBox"
                    style={{
                      transition: 'max-height 0.5s ease-in-out',
                      maxHeight: this.state.isExpandedBankAuth ? '1000px' : '88px',
                      overflow: 'hidden',
                      marginTop:"20px"
                    }}>
                    <Box
                      className="serviceProviderDetailsInnerBox"
                     >
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          <Typography className="ServiceProviderText" style={{lineHeight:"28px"}}>Authorized Signature</Typography>
                          <Typography className="SubtitleForm" style={{fontFamily:"Roboto, sans-serif"}}>
                            I (we) hereby authorize VITU to initiate debit entries to the checking
                            account indicated and the depository named to debit the same to such
                            account.
                          </Typography>
                        </Box>
                        <Box
                          style={{
                            width: '32px',
                            height: '32px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={arrowTop}
                            alt="Toggle"
                            style={{
                              transform: this.state.isExpandedBankAuth ? 'rotate(0deg)' : 'rotate(180deg)',
                              width: '32px',
                              height: '32px',
                              transition: 'transform 0.5s ease-in-out',
                            }}
                            onClick={this.toggleExpandBankAuth}
                          />
                        </Box>
                      </Box>
                    </Box>
                
                  <Box className="collapsibleContent" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%",marginTop:"24px" }}>
                    <Box style={{ position: "relative", width: "48%" }}>
                      <TextField
                          label="Name"
                          variant="outlined"
                          fullWidth
                          data-test-id="inputTestIdOne"
                          style={{ position: 'relative' }}
                          value={values.name}
                          name="name"
                          id="name"
                          onBlur={handleBlur}
                          error={this.returnThirdFormName(touched, errors)}
                          helperText={touched.name && errors.name}
                          type="text"
                          onChange={(event) => this.handleRegexAlpha(event, handleChange)}
                          inputProps={{
                            style: { borderRadius: "8px", width: "430px" },
                            maxLength:50
                          }}
                          InputLabelProps={{
                            style:{marginLeft:"6px"}
                          }}
                        />
                        <Tooltip title={<><p className="heading">Name</p><p className="text">Enter the name of the authorized signatory.</p></>} className="infoText" arrow>
                          <IconButton aria-label="info">
                            <img src={infoIcon} alt="info" id="mapIcon" />
                          </IconButton>
                        </Tooltip>
                      </Box>

                      <Box style={{ position: "relative", width: "48%" }}>
                        <TextField
                          label="Phone Number"
                          variant="outlined"
                          fullWidth
                          data-test-id="phoneNumber"
                          style={{ position: 'relative' }}
                          value={values.phoneNumber.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3')}
                          name="phoneNumber"
                          id="phoneNumber"
                          onBlur={handleBlur}
                          error={this.returnThirdFormPhoneNumber(touched, errors)}
                          helperText={touched.phoneNumber && errors.phoneNumber}
                          onChange={(event) => this.handleRegexNumber(event, handleChange)}
                          inputProps={{
                            style: { borderRadius: "8px" },
                            maxLength:12
                          }}
                          InputLabelProps={{
                            style:{marginLeft:"6px"}
                          }}
                        />
                        <Tooltip title={<><p className="heading">Phone Number</p><p className="text">Enter the phone number of the authorized signatory.</p></>} className="infoText" arrow>
                          <IconButton aria-label="info">
                            <img src={infoIcon} alt="info" id="mapIcon" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "20px" }}>
                      <Box style={{ position: "relative", width: "100%" }}>
                        <TextField
                          label="Job Title"
                          variant="outlined"
                          fullWidth
                          data-test-id="jobTitle"
                          style={{ position: 'relative' }}
                          value={values.jobTitle}
                          name="jobTitle"
                          id="jobTitle"
                          onBlur={handleBlur}
                          error={this.returnThirdFormJobTitle(touched, errors)}
                          helperText={touched.jobTitle && errors.jobTitle}
                          type="text"
                          onChange={(event) => this.handleRegexAlpha(event, handleChange)}
                          inputProps={{
                            style: { borderRadius: "8px"},
                            maxLength:100
                          }}
                          InputLabelProps={{
                            style:{marginLeft:"6px"}
                          }}
                        />
                        <Tooltip title={<><p className="heading">Job Title</p><p className="text">Job title of the authorized signatory.</p></>} className="infoText" arrow>
                          <IconButton aria-label="info">
                            <img src={infoIcon} alt="info" id="mapIcon" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>

                    <Box style={{ display: "flex", justifyContent: "space-between", borderRadius: "8px",padding:"24px",border:"1px solid #C5CBC9",marginTop:"24px" , alignItems:"center"}}>
                      {(
                        this.state.taxInfoDetails.uploadFullSignature &&
                        !this.state.activeSignatureView && this.state.isEsignFinish) 
                        ? (
                        <Box style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", position: "relative" }}>
                          <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <img src={closeBtn} style={{ width: "20px", height: "20px", cursor: "pointer" }} onClick={this.removeShowFullSignatureFile}/>
                          </Box>
                          <Box style={{ position: "absolute", top: "-34px", left: "0", backgroundColor: "white", padding: "0px 4px" }}>
                            <Typography style={{ color: "#4B4C4B", fontWeight: 500, fontSize: "12px", lineHeight: "18px" }}>Authorization Signature</Typography>
                          </Box>
                          <img
                            height="110px"
                            width="172px"
                            src={this.state.taxInfoDetails.uploadFullSignature }
                          />
                          <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Button onClick={this.handleOpenPdfDocSign} data-test-id="openDocumentDataTestId">
                              <Typography style={{fontSize:'14px', fontWeight:700, color:'#013D4F'}}>Open Document</Typography>
                              <img src={arrowRight} style={{width: '16px', height: '16px' }}/>
                            </Button>
                            <Typography style={{ fontWeight: 500, lineHeight: "18px", fontSize: "12px", color: "#C5CBC9" }}>Signed on {this.getDate()}</Typography>
                          </Box>
                        </Box>
                      ) : (
                        <>
                          <Box>
                            <Typography className="spText">Authorization Signature</Typography>
                            <Typography className="SubtitleForm" style={{ width: "92%",fontFamily:"Roboto, sans-serif" }}>
                              Signature needed for the Electronic Fund Transfer Authorization Agreement for Preauthorized Payments
                            </Typography>
                          </Box>
                          <Box style={{height:"50px", display:"flex" , justifyContent:"center" , alignItems:"center"}}>
                          <Button
                            data-test-id="openESign"
                            variant="outlined"
                            style={{
                              padding: "10px 24px",
                              color: "#4B4C4B",
                              lineHeight: "24px",
                              fontWeight: 700,
                              fontSize: "16px",
                              textTransform: "none",
                              borderRadius: "8px",
                              textWrap:"nowrap",
                              maxWidth:"131px",
                              alignSelf:"center"   
                            }}
                            onClick={this.handleopenPdfPopup('body')}
                          >
                            E-Sign Now
                          </Button>
                          </Box>
                        </>
                      )}
                      <Dialog
                        open={this.state.taxInfoDetails.isContractPdfView}
                        onClose={this.handleContractPDFViewClose}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                        fullWidth               
                        maxWidth={false}
                        PaperProps={{
                          style: {
                            borderRadius: '17px',
                          },
                        }}
                      >
                        <Box style={{
                          padding: "16px",
                          position: "absolute",
                          background: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "98.3%",
                        }}>
                          <img src={logo} style={{ width: "60px", height: "30px" }} />
                          <img src={blackClose} style={{ width: "18px", height: "18px", cursor: "pointer",marginRight:"15px" }} onClick={this.handleContractPDFViewClose} />
                        </Box>
                        <DialogContent  style={{ overflowY: "auto", padding: "0px",width:"100%" }} >
                          <PDFViewer navigation={undefined} id={""} />
                          <DialogActions style={ {padding:"20px 0px 20px 0px", display: "flex", justifyContent: "center", alignItems: "center", background:"#F0FAFA",gap:"8%" }}>
                          <FormControlLabel
                            data-test-id='taxInfoDetailsTestId'
                            control={ 
                              <Checkbox  checked={this.state.taxInfoDetails.isPdfCheck} color="primary" onChange={this.handleIsPdfBtn} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}/>
                            }
                            label={
                              <Typography style={{fontWeight:500, color:"#4B4C4B" , width:"100%",display:"flex", gap:"6px"}}>I agree to sign the <span style={{color:"#0F172A", position:"relative" , display:"block"}} >
                                  <div style={{...webStyles.underLIne , position:"absolute"}}></div>
                                  Electronic signature disclosure.
                                  </span></Typography>
                            }
                          />
                            <Button  style={{
                              background: this.state.taxInfoDetails.isPdfCheck ? "#4FC8EC" : "#E8F8FD",
                              color: this.state.taxInfoDetails.isPdfCheck ? "#FFFFFF" : "#75D3F0",
                              textTransform: "none",
                              border:"1px solid #C5CBC9",
                              boxShadow:"none",
                              borderRadius:"8px",
                              height:"44px",
                              width:"132px"
                            }} data-test-id='buttonTestID' variant="contained" onClick={this.handleSignatureOpen} disabled={!this.state.taxInfoDetails.isPdfCheck}>
                              E-Sign Now
                            </Button>
                          </DialogActions>
                        </DialogContent>
                      </Dialog>
                      <Dialog
                        open={this.state.taxInfoDetails.isContractPdfViewSign}
                        onClose={this.handleClosePdfDocSign}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                        fullWidth            
                        data-test-id="test"   
                        maxWidth={false}
                        PaperProps={{
                          style: {
                            borderRadius: '17px',
                          },
                        }}
                      >
                        <Box style={{
                          padding: "16px",
                          position: "absolute",
                          background: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "98.3%",
                        }}>
                          <img src={logo} style={{ width: "60px", height: "30px" }} />
                          <img src={blackClose} style={{ width: "18px", height: "18px", cursor: "pointer",marginRight:"15px" }} onClick={this.handleClosePdfDocSign} />
                        </Box>
                        <DialogContent  style={{ overflowY: "auto", padding: "0px",width:"100%" }} >
                          <div style={{ width: '100%', height: '90vh', overflow: 'auto' ,background:"white" }}>
                            <embed
                            src={this.state.taxInfoDetails.pdfSignFile}
                            type="application/pdf"
                            style={{ width: '100%', height: '100vh', background:"white",marginTop:"25px"}}
                            id='toolbarViewerCss'
                            />
                          </div>
                        </DialogContent>
                      </Dialog>
                    </Box>
                  </Box>
                  {this.renderThirdFormVoidedCheck() } 
                </MuiThemeProvider>
              </Form>
            )
          }}
        </Formik>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Typography style={{ fontSize: "14px", color: "#C43937"}}>{this.state.errorsBankInfoBackend}</Typography>
        </div>
      </Box >
    );
  };

  step5 = () => {
    return (
      <>
        <Box className="spInfoForm">
          <Box className="headerForm">
            <Box className="headingForm">
              <Typography className="titleForm gothamText" style={{ width: "75%" }} data-test-id="contactInfoTestId">Tax Info</Typography>
              <Typography className="subtitleForm" style={{ marginTop: '5px' }}>
              VITU requires pertinent information included in the W-9 for tax purposes.
              </Typography>
            </Box>
          </Box>

          <Box style={{ position: "relative" }}>
            <Box className="serviceDetailsBox">
              <Box>
                <Typography style={{ fontWeight: 400, fontSize: "16px", color: "#013D4F",lineHeight:"24px",marginBottom:"8px" }}>Instructions</Typography>
                <Typography style={{ fontWeight: 500, fontSize: "14px", color: "#4B4C4B",lineHeight:"22px" }}>Please visit the link, fill in the form, download, and upload the document into this step when you are done.</Typography>
              </Box>
              <Box style={{ marginTop: "20px" }}>
                <Button href={this.state.taxInfoDetails.taxInfoFormLink} target="blank" className="w9FormButton" style={{ marginTop: "10px" }} data-test-id="W9FormButton" variant="outlined">
                  Click to open a W-9 form
                </Button>
              </Box>
              <Box style={{ height: "200px", display: "flex", justifyContent: "center", alignItems: 'center', position: "relative", marginTop: "10px", marginBottom: "15px" }}>
                <Box style={{ width: "100%", height: "196px", marginTop: "20px" }}>
                  <Dropzone onDrop={this.onDropNew} multiple={false} data-test-id="dropZoneTestId"
                    accept={{
                      'application/pdf': ['.pdf']
                    }}
                  >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <div
                        {...getRootProps()}
                        style={{
                          height: "196px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          cursor: "pointer",
                          color: "black",
                          border: "1px solid #C5CBC9",
                          borderRadius: "8px",
                          backgroundColor: isDragActive ? "#f0f0f0" : "transparent",
                        }}
                      >
                        <input {...getInputProps()} />
                        {this.state.taxInfoDetails.taxInfoFile ? (
                          <Box className="test12" style={{ height: "171px", width: "100%", display: "flex", flexDirection: 'column' }}>
                            <Box className="test123" style={{ width: "100%", height: "11.67px", marginTop: "10px" }}>
                              <div data-test-id="removeTaxPhotoDiv" style={{ textAlign: 'right' }}>
                                <button data-test-id="removeTaxPhoto" onClick={this.removeFile} style={{ width: "20px", height: "20px", marginRight: "20px", border: "0px", backgroundColor: 'white',cursor:"pointer" }}><img src={closeBtn} style={{ width: "18.67px", height: "18.67px", color: "#4B4C4B",filter:"contrast(0%)",opacity:"0.6" }} /></button>
                              </div>
                            </Box>
                            <Box style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <img src={pdfFile} />
                              <Typography data-test-id='selectedFileName' style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", marginTop: "10px" }}>{this.state.taxInfoDetails.taxInfoFile.name}</Typography>
                            </Box>
                          </Box>
                        ) : this.isDragActiveFunction(isDragActive,true)}
                      </div>
                    )}
                  </Dropzone>
                  <Box style={{ position: "absolute", top: 5, left: 17, backgroundColor: "white", padding: "0px 4px" }}><Typography style={{ color: "#4B4C4B", fontWeight: 500, fontSize: "12px" }}>Upload W-9 Form</Typography></Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <Typography style={{ fontSize: "14px", color: "#C43937"}}>{this.state.errorsSpLocationBackend}</Typography>
        </div>
        </Box>
      </>
    );
  };

  backToDashboardModal = ()=>{
    return(
      <Modal
      open={this.state.isOpenBackToDashboardModal}
      onClose={this.toggleDashboardModalNewSp}
      aria-describedby="simple-modal-description"
      aria-labelledby="simple-modal-title"
      style={{  display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   }}
    >
      <StyledModalBox >
        <Box display={'flex'} style={{width:'545px'}} justifyContent={'end'} >
          <Button onClick={this.toggleDashboardModalNewSp} data-test-id="closebtn"><CloseIcon/></Button>
        </Box>
  
       <Box style={{width:'520px', height:'126px'}}>
        <Box><Typography style={{color:'#0F172A', fontWeight:500, fontSize:'24px',marginBottom:'12px', fontFamily:'Roboto'}}>Exit without saving?</Typography></Box>
        <Typography style={{color:'#013D4F',fontSize:'18px',fontWeight:300, fontFamily:'Gotham light'}}>Are you sure you want to exit the dealership creation process and return to the dashboard? Your progress will be lost.</Typography>
       </Box>
  
       <Divider style={{ height:'2px', width:'113%', marginLeft:'-33px',backgroundColor:'#F0FAFA', marginTop:'30px'}}/>
       
       <Box style={{display:'flex', marginTop:'20px',justifyContent:'end', }}>
        <Button data-test-id="cancellogout" variant="outlined" onClick={this.toggleDashboardModalNewSp} style={{height:'56px', border:'1px solid #013D4F', width:'156px', marginRight:'15px', borderRadius:'8px'}}>
          <Typography style={{color:'#013D4F',fontSize:'16px', fontWeight:700, textTransform:'none',fontFamily:'Roboto'}}>
            Cancel
          </Typography>
        </Button>
        <Button data-test-id="finalDashboard" variant="contained" onClick={this.navigateToDashboard} style={{ width:'230px',height:'56px', backgroundColor:'#4FC8EC', borderRadius:'8px'}}>
          <Typography style={{color:'white', textTransform:'none', fontSize:'16px', fontFamily:'Roboto', fontWeight:700, }}>
            Go to Dashboard
          </Typography>
        </Button>
       </Box>
      </StyledModalBox>
  </Modal>
    )
  }

  headerr = () => {
    return (
      <Box sx={webStylesHeader.header}>
        <Box sx={webStylesHeader.headerLeftSide}>
        <HamburgerMenuFull 
          navigation={this.props.navigation} 
          id={""} 
          data-test-id="hamburgerMenuTestId" />
          <Box sx={webStylesHeader.logo}>
            <img 
              style={{ marginBottom: '5px', height: '24px', width: '52px' }} 
              src={logo} 
              alt="vitu logo" />
          </Box>
        </Box>
        <Box sx={webStylesHeader.headerRightSide}>
        <Button className="logout" 
          data-test-id="backToDashboardButton" 
          style={{width:'66%', paddingLeft:"10px", paddingRight:"10px"}} 
          variant="outlined" 
          onClick={this.toggleDashboardModalNewSp}>
            Back to Dashboard
          </Button>

          <Box>
            <Chat navigation={this.props.navigation} id={""}/>
          </Box>
          <Box
            data-test-id="notifications"
            sx={webStylesHeader.notificationsButton}
          >
            <img 
              src={notifications} 
              alt="notifications button" />
          </Box>
          <ProfileDropdown 
            navigation={this.props.navigation} 
            id={""} 
            data-test-id="profileDropdownTestId"/>
        </Box>
      </Box>
    )
  }

  renderCognitiveHeader = () => {
    return (
      <>
      {this.state.isFirstSpLocation ?
            <Box>
              <Box className="header" style={{position:"relative", marginTop:"0px"}}>
                <img className="logo" src={logo} alt="logo"></img>
                <Button className="logout" variant="outlined" onClick={this.logout}>
                  Log Out
                </Button>
              </Box>
            </Box>
            : this.headerr()
          }
          <Box style={{ width: "99.9%", position: "absolute", left: "0", borderTop: "2px solid #F0FAFA", marginTop: "10px" }}></Box></>
    )
  }

  renderTab3 = () => {
    return (
      (
        <Box style={{ height: "auto", justifyContent: "center", alignItems: 'center', position: "relative", marginTop: "24px" }}>
          {['Full Signature', 'Initial Signature'].map((label, index) => {
            const isFullSignature = index === 0;
            const dropZoneData = isFullSignature ? this.state.taxInfoDetails.fullSignatureUploadView : this.state.taxInfoDetails.initialSignatureUploadView;
            const onDrop = isFullSignature ? this.onDropFullSignature : this.onDropInitialSignature;
            const removeFile = isFullSignature ? this.removeFullSignatureFile : this.removeInitialSignatureFile;
            const uploadSignature = isFullSignature ? this.state.taxInfoDetails.uploadFullSignature : this.state.taxInfoDetails.uploadIntitalSignture;

            return (
              <Box key={index} style={{ width: "100%", height: "196px", marginTop: index === 1 ? "24px" : "0", position: "relative" }}>
                <Dropzone onDrop={onDrop} multiple={false} data-test-id={`dropZoneTestIdTab3` + index}
                  accept={{
                    'image/png': ['.png'],
                    'image/jpeg': ['.jpg', '.jpeg'],
                    'image/svg+xml': ['.svg']
                  }}
                >
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                      {...getRootProps()}
                      style={{
                        height: "196px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        cursor: "pointer",
                        color: "black",
                        border: "1px solid #C5CBC9",
                        borderRadius: "8px",
                        backgroundColor: isDragActive ? "#f0f0f0" : "transparent",
                      }}
                    >
                      <input {...getInputProps()} />
                      {dropZoneData ? (
                        <Box style={{ height: "171px", width: "100%", display: "flex", flexDirection: 'column' }}>
                          <Box style={{ width: "100%", height: "17.67px", marginTop: "10px" }}>
                            <div data-test-id="removeSignatureDiv" style={{ textAlign: 'right' }}>
                              <button data-test-id={`remove-signature` + index} onClick={removeFile} style={{ width: "20px", height: "20px", marginRight: "20px", border: "0px", backgroundColor: 'white' }}>
                                <img src={closeBtn} style={{ width: "20px", height: "20px", color: "#C5CBC9" }} />
                              </button>
                            </div>
                          </Box>
                          <Box style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img
                              height="110px"
                              width="172px"
                              src={uploadSignature}
                            />
                            <Typography style={{ textAlign: "center", fontWeight: 500, fontSize: "12px", marginTop: "10px" }}>{dropZoneData.name}</Typography>
                          </Box>
                        </Box>
                      ) : this.isDragActiveFunction(isDragActive)}
                    </div>
                  )}
                </Dropzone>
                <Box style={{ position: "absolute", top: -9, left: 17, backgroundColor: "white", padding: "0px 4px" }}>
                  <Typography style={{ color: "#4B4C4B", fontWeight: 500, fontSize: "12px", lineHeight: "18px" }}>{label}</Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      )
    )
  }

// Customizable Area End

  render() {
    // Customizable Area Start
    const {activeTab, action} = this.state;
    let title = 'Setup Service Provider Profile';
    let subtitle = 'Set up your business profile to get started';
    if(action === 'add-serviceable-location'){
      title = 'Create Serviceable Locations';
      subtitle = 'Make the changes according to your need';
    } else if(action === 'edit-service-provider'){
      title = 'Edit Service Provider Profile';
      subtitle = 'Change the details for this Service Provider';
    } else if (action === 'edit-serviceable-location'){
      title = 'Edit Serviceable Location';
      subtitle = 'Change the details for this Serviceable Location';
    }
    return (
      <CustomisableUserProfilesStyle>
        {this.renderCognitiveHeader()}
        <Box className="heading">
          <Typography className="title gothamText">{title}</Typography>
          <Typography className="subtitle gothamText">
            {subtitle}
          </Typography>
        </Box>
        <Box className="mainContent">
        <Box style={{display:"flex", flexDirection:"row"}}>
          <Box className="steps">
            {this.renderNavItem(1, "Provider Info", "Your business info")}
            {this.renderNavItem(2, "Serviceable Locations", "Business Locations")}
            {this.renderNavItem(3, "Contact Info", "How to contact you")}
            {this.renderNavItem(4, "Banking Info", "How to make payments")}
            {this.renderNavItem(5, "Tax Info", "W-9")}
          </Box>
          <Box style={{borderLeft:"2px solid #F0FAFA", left:"26%", height:"560px", top:"0%"}}></Box>

          </Box>
          <Box className="stepContent">
            <Box className="content" style={{padding:"0px 20px"}}>{this.renderContent()}</Box>
            {this.renderDrawer()}
            <Box className="nextStep">
              <Button variant="contained" onClick={this.nextStep} disabled={!this.isNextButtonEnabled()} data-test-id="nextStepBtn" id="nextStepBtn">{this.state.step == 5 || this.state.action === 'add-serviceable-location' ? "Finish" : "Next Step"}</Button>
              <Typography>
                Profile progress will be automatically saved.
              </Typography>
            </Box>
          </Box>
        </Box>
        <Modal
          open={this.state.activeSignatureView}
          onClose={this.handleSignatureClose}
        >
          <Box style={webStyles.signatureContainer}>
            <Box sx={webStyles.signatureLeftContainer}>
              <Box sx={webStyles.signatureHeader}>
                <Box style={webStyles.signatureHeaderLeft}>
                  <Typography style={webStyles.signatureTitle}>Signature</Typography>
                  <Typography style={{...webStyles.signatureSubTitle,fontSize:"14px"}}>Your Information, Secured for Peace of Mind.</Typography>
                </Box>
                <Box style={webStyles.signatureHeaderRight}>
                  <img src={blackClose} onClick={this.handleSignatureClose} style={{ width: "18px", height: "18px", cursor: "pointer" }} />
                </Box>
              </Box>
              <Box style={webStyles.signatureNavigation}>
                <Box data-test-id="tab1Button" onClick={() => this.handleSpTabChange('tab1')} style={{ ...webStyles.navigationTab, ...(activeTab === 'tab1' && webStyles.activeTab) }} >
                  Type it in
                </Box>
                <Box data-test-id="tab2Button" onClick={() => this.handleSpTabChange('tab2')} style={{ ...webStyles.navigationTab, ...(activeTab === 'tab2' && webStyles.activeTab) }}>
                  Draw it in
                </Box>
                <Box data-test-id="tab3Button" onClick={() => this.handleSpTabChange('tab3')} style={{ ...webStyles.navigationTab, ...(activeTab === 'tab3' && webStyles.activeTab) }}>
                  Upload Image
                </Box>
              </Box>
              <Box style={webStyles.tabContent}>
                {activeTab === 'tab1' && (
                  <Box>
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", marginTop: "24px" }}>
                      <Box style={{ position: "relative", width: "48%" }}>
                        <TextField
                          label="Signature Name"
                          variant="outlined"
                          fullWidth
                          data-test-id="signeturName"
                          style={{ position: 'relative',borderRadius:"8px" }}
                          name="signatureName"
                          id="signatureName"
                          type="text"
                          inputProps={{
                            style: { borderRadius: "8px", width: "430px" },
                          }}
                        />
                      </Box>

                      <Box style={{ position: "relative", width: "48%" }}>
                        <TextField
                          label="Initials"
                          variant="outlined"
                          fullWidth
                          data-test-id="initials"
                          style={{ position: 'relative',borderRadius:"8px" }}
                          name="initials"
                          id="initials"
                          inputProps={{
                            style: { borderRadius: "8px" },
                            maxLength: 12
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {activeTab === 'tab2' && (
                  <Box>
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%", marginTop: "24px" }}>
                      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center",position:"relative" }}>
                        {this.state.taxInfoDetails.drawSignature !== "" ? (
                          <Button
                            data-test-id="clear-signature"
                            variant="text"
                            style={{
                              textTransform: "none",
                              position: "absolute",
                              top: 12,
                              right: 19,
                              border: "1px solid rgba(197, 203, 201, 1)",
                              padding: "6px 10px",
                              height: "32px",
                              width: "86px",
                              borderRadius: "8px"
                            }}
                            onClick={this.clearSignature}
                          >
                            Clear
                          </Button>
                        ) : null}
                        {this.state.taxInfoDetails.drawSignature.length > 0 ? (
                          <Box style={{ border: "1px solid #C5CBC9", borderRadius: "8px", width: "693px", height: "247.5px", display: "flex", justifyContent: "center", alignItems: "center" }} >
                            <img src={this.state.taxInfoDetails.drawSignature} alt="Signature"/>
                          </Box>
                        ) : <SignatureCanvas
                          ref={this.spDrawSignCanvasRef || this.state.taxInfoDetails.drawSignature}
                          penColor="black"
                          onEnd={this.endDrawSignature}
                          canvasProps={{ width: 700, height: 250, className: 'drawSignCanvasRef', style: { border: "1px solid #C5CBC9", borderRadius: "8px",width:"99%" } }}
                        />}

                      </Box>
                    </Box>
                  </Box>
                )}
                {activeTab === 'tab3' && this.renderTab3()}
                <FormControlLabel
                  data-test-id="formControlTestId"
                  control={
                    <Checkbox data-test-id="uploadInitialSignature" checked={this.state.taxInfoDetails.isSignCheck} color="primary" onChange={this.handleIsSigneturCheck} icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />} />
                  }
                  label={
                    <Typography style={{ fontWeight: 500, color: "#4B4C4B", width: "100%", display: "flex", gap: "6px" }}>I agree to sign the <span style={{ color: "#0F172A", position: "relative", display: "block" }} >
                      <div style={{ ...webStyles.underLIne, position: "absolute" }}></div>
                      Electronic signature disclosure.
                    </span></Typography>
                  }
                  style={{ marginTop: "10px",marginLeft:"-9px" }}
                />

              </Box>
              <Box
                css={webStyles.nextStep}
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: "112px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  left: 0
                }}
              >
                <Button
                  style={{
                    width: "88%",
                    height: "56px",
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "24px",
                    textTransform: "none",
                    borderRadius: "8px",
                    background: !this.handleDisableFinishButton() ? "#4FC8EC" : "#E8F8FD",
                    color: !this.handleDisableFinishButton() ? "#FFFFFF" : "#75D3F0",
                    border: "1px solid #C5CBC9",
                  }}
                  variant="contained"
                  data-test-id="esignFinishBtn"
                  id="esignFinishBtn"
                  onClick={this.saveSignature}
                  disabled={this.handleDisableFinishButton()}
                >
                  Finish
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
        {this.backToDashboardModal()}
      </CustomisableUserProfilesStyle>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomisableUserProfilesStyle = styled(Box)(({theme}) => ({
  padding: "24px",
  boxSizing: "border-box",
  "& .spInfoForm": {
    padding: "0px 20px",
    width :"100%"
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '8px',
    },
  },
  "& .header": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems:"center",
    position:"relative"
  },
  "& .logo": {
    width: "54px",
    height: "24px",
  },
  "& .MuiOutlinedInput-input":{
    padding:"18.5px 20px"
  },
  "& .MuiAutocomplete-input:first-child":{
    marginLeft:"11px"
  },
  "& .MuiOutlinedInput-root fieldset": {
    padding: "0 14px" 
   },
  "& .MuiAutocomplete-root": {
    "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      paddingRight: "6px", 
      height:"56px",
      "& .MuiAutocomplete-input": {
        padding: "0px", 
      },
      "& .MuiInputAdornment-root": {
        marginLeft: "9px", 
      },
      '& .MuiInputAdornment-positionEnd': {
        marginRight: '9px',
      },
      '& + .MuiAutocomplete-popper .MuiAutocomplete-option:hover': {
        backgroundColor: "#013D4F",
      },
      "& .MuiAutocomplete-popper": {
        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option": {
            "&[data-focus='true'], &:hover": {
              backgroundColor: "##013D4F",
            },
            "&[aria-selected='true']": {
              backgroundColor: "#013D4F",
            },
          },
        },
      },
    },
  },
  "& .logout, .skip": {
    width: "98px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    color: "#4B4C4B",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
    minWidth:"98px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  "& .w9FormButton": {
    marginTop:"20px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none",
    color: "#4B4C4B",
    border: "1px solid #C5CBC9",
    borderRadius: "8px",
  },
  "& .collapsibleContent": {
    transition:"max-height 0.3s ease-in-out",
  },
  "& .heading": {
    paddingTop: "112px",
    paddingBottom: "32px",
    margin: "0 14.27%",
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      margin: "0 8%",
    },
  },
  "& .title": {
    fontSize: "30px",
    lineHeight: "40px",
    color: "#013D4F",
    fontWeight: 300
  },
  '& .Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'black',
    }
  },
  '& label.Mui-focused': {
    color: '#4B4C4B',
    fontWeight:500
  },
  '& .MuiInputLabel-root': {
    paddingLeft: '6px',
    color: '#C5CBC9',
  },
  '& label.MuiInputLabel-shrink': {
    marginLeft: 0,
    paddingLeft: 0,
    color: '#4B4C4B',
    fontWeight:500
  },
  "& .subtitle": {
    fontSize: "18px",
    lineHeight: "18px",
    color: "#4B4C4B",
    fontWeight: 300,
    marginTop:"10px",
    zIndex: 40,
    position:'relative'
  },
  "& .headingForm": {
    fontFamily: "Gotham, sans-serif",
    fontWeight: 300,
  },
  "& .titleForm": {
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    fontWeight: 300,
  },
  "& .radioStyle": {
    width:"20px", 
    height:"20px"
  },
  "& .MuiAutocomplete-popupIndicator": {
   marginRight:"3px"
  },
  "& .spText": {
    color:"#013D4F",
    fontWeight:400,
    marginBottom:"5px",
    fontSize:"16px",
    lineHeight:"24px"
  },
  "& .ServiceProviderText": {
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "2px",
    fontSize: "20px",
    fontWeight: 500,
    marginBottom: "20px",
  },
  "& .custom-placeholder": {
     paddingLeft:"10px",
     fontSize:"16px",
     fontWeight:400,
    },
  "& .MuiFormGroup-root": {
    display: "inline",
  },
  "& .MuiFormControlLabel-label": {
    marginTop:"3.5px"
  },
  "& .serviceDetailsBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px",
    border: "2px solid #F0FAFA",
    borderRadius : "16px",
    height:"auto"
  },
  "& .serviceProviderDetailsInnerBox": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius : "16px",
    height:"auto",
  },
  "& .subtitleForm": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    color: "#4B4C4B",
  },
  "& .mainContent": {
    margin: "0 14.37%",
    display: "flex",
    position: "relative",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      margin: "0 8%",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "98.7%",
      borderTop: "2px solid #F0FAFA",
    }
  },
  "& .steps": {
    display:"flex",
    flexDirection:"column",
    marginRight: "24px",
    marginTop:"26px",
    padding:"20px 0px",
    width:"270px"
  },
  "& .stepContent": {
    marginTop:"24px",
    width:"100%",
    padding:"20px 0px",
    minWidth:"560px",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0",
    },
  },
  "& .stepItem": {
    marginBottom: "24px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "16px",
    color: "#C5CBC9",
  },
  "& .stepItem.active .stepTitle": {
    color: "#013D4F",
    "& .MuiTypography-body1": {
      color:"#013D4F"
    }
  },
  "& .stepItem.done .stepTitle": {
    color: "#26BBB3",
    "& .MuiTypography-body1": {
      color:"#26BBB3"
    }
  },
  "& .stepTitle": {
    display: "flex",
    flexDirection: "row",


    alignItems: "center",
    gap: "2px",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "28px",
  },
  "& .stepSubtitle": {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
  },
  "& .stepText": {
    textAlign: "end",
  },
  "& .stepNumber": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height:"100%",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    borderRadius:'50%',
    backgroundColor:"rgba(240, 250, 250, 0.78)",
    padding:"0px 14px"
  },
  "& .stepItem.skipped .stepTitle": {
    color: "#F05E22",
    "& .MuiTypography-body1": {
      color:"#F05E22"
    }
  },
  "& .stepItem.clickable": {
    cursor: "pointer",
  },
  "& .stepItem:not(.clickable)": {
    cursor: "not-allowed",
  },
  "& .pac-container.pac-logo": {
    width: "800px !important",
    backgroundColor: "red !important", 
    padding: "10px !important",
    fontSize: "16px",
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)", 
    border: "1px solid #ccc", 
    borderRadius: "4px", 
  },
  "& .pac-item": {
    padding: "10px",
    fontSize: "14px",
    cursor: "pointer",
  },
  "& .pac-item:hover": {
    backgroundColor: "red",
  },
  "& .pac-item-query": {
    fontSize: "14px",
    color: "#red",
  },
  "& .pac-matched": {
    fontWeight: "bold",
  },
  "& .pac-icon": {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  "& .headerForm": {
    marginBottom: "32px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  "& .photoForm": {
    maxWidth: "640px",
    height: "321px",
    background: `url(${profileBackground}) no-repeat top center`,
    backgroundSize: "100% 191px",
    border: "2px solid #F0FAFA",
    borderRadius: "30px",
  },
  "& .photoFormContent": {
    padding: "48px",
    marginTop: "81px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "16px",
  },
  "& .profilePhoto": {
    width: "120px",
    height: "120px",
    borderRadius: "30px",
  },
  "& .updatePhoto": {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  "& .uploadPhoto": {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  "& .upload, .delete": {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    borderRadius: "8px",
  },
  "& .upload, .uploadLogo": {
    color: "#013D4F",
    border: "1px solid #013D4F",
  },
  "& .delete": {
    border: "1px solid #C43937",
    color: "#C43937",
  },
  "& .uploadLogo": {
    position: "absolute",
    top: "12px",
    right: "24px",
    height: "32px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    textTransform: "none",
    borderRadius: "8px",
  },
  "& .hints": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "10px",
    color: "#C5CBC9",
  },
  "& .nextStep": {
    marginTop: "24px",
    textAlign: "center",
    paddingLeft:"45px"
  },
  "& .nextStep button": {
    width: "96%",
    height: "56px",
    marginBottom: "8px",
    backgroundColor: "#4FC8EC",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: "none",
    borderRadius:"8px",
    marginRight:"3px"
  },
  "& .nextStep p": {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#4B4C4B",
  },
  "& .form": {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  "& .formControl": {
    position: "relative",
    height: "56px",
    padding: "0 24px 10px 24px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  "& .Mui-checked": {
    color:"#4FC8EC",
  },
  "& .info": {
    position: "absolute",
    top: "-35px",
    right: "0",
  },
  "& .infoTextFirst": {
    position: "absolute",
    top: "53px",
    right: "24px",
  },
  "& .infoTextVoidedCheck": {
    position: "absolute",
    top: "-10px",
    right: "4px",
  },
  "& .infoTextLast": {
    position:"absolute",
    right:"2px",
    top: "0px",
  },
  "& .infoText": {
    position: "absolute",
    top: "-23px",
    right: "2px",
  },
  "& .infoTextStep3": {
    position: "absolute",
    top: "72px",
    right: "26px",
  },
  "& .infoTextBankAddress": {
    position:"absolute",
    right:"2px",
    top:"-7px",
  },
  "& .same": {
    marginTop: "-14px",
    marginLeft: "7px",
    fontWeight:500,
  },
  "& .nextStep button:disabled": {
    color: "#75D3F0",
    backgroundColor: "#E8F8FD",
  },
  "& .MuiAutocomplete-groupUl": {
    backgroundColor:"red"
  },
  "& .MuiFormLabel-root.Mui-disabled": {
    color:"#4B4C4B",
  },
})
);
const DetailBox = styled(Box)({
  "@media (max-width:960px)": {
     flexDirection:'row', justifyContent:'center', alignItems:'center', width:'215px'
    },
    "@media (min-width:720px)": {
      
    }
})
const StyledPaper = styled(Paper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    '& li': {
      height: '56px',
      display: 'flex', 
      alignItems: 'center', 
      '&:hover': {
        backgroundColor: '#F0FAFA', 
      },
    },
  },
}));

const webStyles = {
  signatureContainer: {
    display: "flex",
    width: "100%",
    height: "100vh",
    background: "rgba(1, 61, 79, 0.52)",
    top: 0,
    left: 0,
    zIndex: 123,
    justifyContent: "right",
    backdropFilter: "blur(3px)",
    overflow: "hidden",
  },
  signatureLeftContainer: {
    width: "100%",
    maxWidth: "700px",
    background: "#FFFFFF",
    height: "100vh",
    padding: "48px 48px",
    position:"relative",
    overflowX:"auto"
  },
  signatureHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "auto",
    alignItems: "center"
  },
  signatureTitle: {
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.005em",
    color: "#013D4F",
    fontWeight: 300,
    fontFamily: "Gotham, sans-serif"
  },
  signatureSubTitle: {
    fontWeight: 500,
    lineHoght: "22px",
    marginTop: "5px",
    fontFamily: "Roboto, sans-serif",
    color:"#4B4C4B"
  },
  signatureNavigation: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    borderBottom: "1px solid black",
    marginTop: "40px"
  },
  navigationTab: {
    width: "100%",
    maxWidth: "300px",
    height: "50px",
    display: "grid",
    placeItems: "center",
    fontFamily: "Roboto, sans-serif"
  },
  activeTab: {
    borderBottom: "2px solid #013D4F",
    fontWeight: 500,
    color:"#013D4F",
  },
  tabContent: {
    marginTop: "20px",
    height: "calc(100vh - 310px)",
    overflow:"auto"
  },
  nextStep: {
    marginTop: "-44px",
  },
  nextStepBtn: {
    width: "100%",
    height: "56px",
    marginBottom: "8px",
    backgroundColor: "#4FC8EC",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: "none",
    borderRadius: "8px"
  },
  finishBtnDisable: {
    color: "#75D3F0",
    backgroundColor: "#E8F8FD",
    width: "100%",
    height: "56px",
    marginBottom: "8px",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "none",
    borderRadius: "8px"
  },
  signatureHeaderLeft: {},
  signatureHeaderRight: {},
  underLIne : {
    width: "100%",
    height: "1px",
    backgroundColor: "#0F172A",
    bottom: "5px",
  }
}

const webStylesHeader : Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    minWidth : "924px",
  },
 
  header: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 0px",
    minWidth : "870px",
  },
 
  headerLeftSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "0px",
  },
 
  menuButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    width: "24px",
    height: "24px",
    cursor: "pointer",
    padding: "unset",
    border: "none",
  },
 
  logo: {
    width: "52px",
  },
 
  headerRightSide: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    gap: "24px",
  },
 
 
  notificationsButton: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
 
  userAvatar: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
  },
 
  main: {
    display: "flex",
    flexWrap: "nowrap",
    position: "relative",
  },
 
 
  buttonText: {
    fontFamily: "Roboto, sans-serif",
    color: "#013D4F",
    fontSize: "14px",
    fontWeight: 500,
  },
 
  content: {
    width: "100%",
    marginLeft: "120px",
  },
 
}

const StyledModalBox = styled(Box)({
  width: '512px',
  minWidth:'200px',
  border: '1px solid #FFFFFF',
  boxShadow: theme.shadows[5],
  backgroundColor:'#FFFFFF',
  padding: theme.spacing(2, 4, 3),
  borderRadius:'8px',
})

// Customizable Area End
