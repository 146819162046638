import React from "react";
// Customizable Area Start
import { Box,
  Button,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  Typography,
  styled,
  TextField,
  MenuItem,
  createTheme,
  Grid,
  AppBar,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Input,
  TablePagination,
  CircularProgress,
  Tooltip,
  MuiThemeProvider,
  Popover,
  List,
  ListItem,
  Divider,
  Chip,
  FormControlLabel,
  Checkbox,
  withStyles,
 } from "@material-ui/core";
 import SearchIcon from '@material-ui/icons/Search';
 import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
 import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
 import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
 import {settingIcon} from "../src/assets";
 import {filledCheckbox, emptyCheckbox} from '../../email-account-registration/src/assets'
 import CloseIcon from '@material-ui/icons/Close';
 import { DateRangePicker } from 'rsuite';
 import {stylee} from '../../../components/src/Loader.web'
 import {TableRows, TablePendingDataSp, DeletedDatatableSp, RejectedOrExpiredData, TableDataAcceptedSp, TablePendingDataRow, TableDataDeletedorCompleted, TableDraftDataRow, } from './AlldealsController'
 import {logo} from '../../customisableuserprofiles/src/assets'
 import HamburgerMenu from "../../customisableuserprofiles/src/HamburgerMenu.web";
 import ProfileDropdown from "../../customisableuserprofiles/src/ProfileDropdown.web";
 import CreateDealButton from "../../postcreation/src/CreateDealButton.web";
 import Pushnotifications from "../../pushnotifications/src/Pushnotifications.web";
 import Chat from "../../chat/src/Chat.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const tooltipTheme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          padding: "8px",
          paddingLeft:'15px',
          paddingRight:'15px',
          backgroundColor: "#FFFFFF",
          boxShadow: "0 18px 32px 0 rgba(0, 0, 0, 0.25)",
          borderRadius:'8px',
          textAlign:'center'
        },
        arrow: {
          color: "#FFFFFF", 
        },
      },
    }
  })

  type FilterOptions = {
    services: string[];
    states: string[];
    dealership: string[];
    serviceProvider: string[];
    dateCreated: string[];
    status: string[];
  };

  const ToolTip = withStyles({
    tooltip: {
      padding: '16px',
      radius: '8px',
      background: '#FFFFFF',
      boxShadow:"0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
      color: '#4B4C4B',
      fontSize: '15px'
    },
  })(Tooltip);
  
// Customizable Area End

import BlockController, {
  Props,
} from "./AlldealsController";

export default class Block extends BlockController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getService = (dealService: string | undefined) => {
    if(dealService === '-') return dealService;
    if(dealService){
      const uppercase = dealService.charAt(0).toUpperCase() + dealService.slice(1);
      return uppercase.replace(/_/g, ' ')
      .replace(/\b\w/g, (char) => char.toUpperCase());
    }
  }

  getPrice = (dealService: string | undefined) => {
    if(dealService === '-') return dealService;
    if(dealService){
      const match = dealService.match(/"=>\s*["']([^"']+)["']/);
      return match ? match[1] : "";
    }
  }

  truncateName = (name: string) => {
    return (
      <ToolTip title={name} placement="top-end">
          <Typography style={{whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "300px",
      display: "inline-block"}}>{name}</Typography>
      </ToolTip>
    )
  }
  
  headerr = () => {
    return (
      <Box sx={webStyles.header}>
        <Box sx={webStyles.headerLeftSide}>
          <HamburgerMenu navigation={this.props.navigation} id={""} data-test-id="hamburgerMenuTestId"/>
 
          <Box sx={webStyles.logo}>
            <img style={{ marginBottom: '5px', height: '24px', width: '52px' }} src={logo} alt="vitu logo" />
          </Box>
        </Box>

        <Box sx={webStyles.headerRightSide}>
          <Box data-test-id="createdealmain" style={{display: (this.state.role==='dealer_admin' || this.state.role==='dealer') ? 'block' : 'none'}}>
            <CreateDealButton fullWidth={false}  data-test-id="mainbtnn" navigation={this.props.navigation} dealerships={this.state.dealerships} savedDeals={this.state.saveddeals}>Create Deal</CreateDealButton>
          </Box>

          <Box data-test-id="">
            <Chat navigation={this.props.navigation} id=""/>
          </Box>

          <Box data-test-id="" sx={webStyles.notificationsButton}>
            <Pushnotifications navigation={this.props.navigation} id=""/>
          </Box>

          <ProfileDropdown navigation={this.props.navigation} id={""} data-test-id="profildDropdownTestId"/>
        </Box>
      </Box>
    )
  }

  tootltipStatus = ()=>{
    return(
      <MuiThemeProvider theme={tooltipTheme}>
        <Tooltip placement="right" title={<><p style={{color:"#4B4C4B", fontFamily:'Roboto', fontWeight:500, fontSize:'14px'}}>Current deal status</p></>} arrow>
          <InfoOutlinedIcon style={{height:'20px', color:'#C5CBC9'}}/>
        </Tooltip> 
      </MuiThemeProvider>
    )
  }

getStatusStyle = (status: string): React.CSSProperties => {
    switch (status) {
      case 'In Progress':
        return { display: 'inline-block', whiteSpace: 'nowrap',border:'1px solid #346472',color: '#346472', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
      case 'Issues Sent to Dealer':
        return { display: 'inline-block',whiteSpace: 'nowrap', border:'1px solid #FCCC0F',color: '#FCCC0F', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
      case 'Resolved In Dealer Review':
        return { display: 'inline-block',whiteSpace: 'nowrap', border:'1px solid #4B4C4B',color: '#4B4C4B', padding: '6px 12px',fontFamily:'Roboto', fontSize:'14px', borderRadius: '26px', fontWeight: 500 };
    case '-':
        return{paddingLeft:'50px'}
    case 'Dealer Flags Incomplete':
        return { display: 'inline-block', whiteSpace: 'nowrap',border:'1px solid #C43937',color: '#C43937', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
    case 'Completed':
        return { display: 'inline-block', whiteSpace: 'nowrap',border:'1px solid #26BBB3',color: '#26BBB3', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
    default:
        return {};
    }
  };

  getStatusPendingStyle = (status: string) => {
    if(status==='Rejected'){
        return { color: '#F05E22', fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
    }
    else if(status==='No Response'){
        return { color: '#475569',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 };
    }
  };


  TabPanel(props : TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

ongoingTable = ()=>{
    return(
        <TableContainer  component={Box} style={{ width:'100%', overflowX:'auto',}}>
        <Box style={{overflowX: 'auto', width: '100%',}}>
          <Table aria-label="sticky table" stickyHeader style={{ overflowX: 'auto'}}>
            <TableHead >
                <TableRow >
                  <TabCellHeader style={{borderLeft:'none'}}>Deal ID</TabCellHeader>
                  <TabCellHeader>Customer's Last Name </TabCellHeader>
                  <TabCellHeader>Dealership</TabCellHeader>
                  <TabCellHeader>States</TabCellHeader>
                <TabCellHeader>Date Created</TabCellHeader>

                <TabCellHeader>Date Accepted</TabCellHeader>
                <TabCellHeader>Service</TabCellHeader>
                <TabCellHeader>Fees</TabCellHeader>
                <TabCellHeader>Service Provider</TabCellHeader>
                <TabCellHeader style={{ borderRight:'none'}}> 
                    <Box 
                    style={{display:'flex', alignItems:'center'}}>
                        <Typography style={{fontFamily: 'Roboto', fontSize:'14px', color: '#C5CBC9',fontWeight:700,}}>Status </Typography>
                        {this.tootltipStatus()}
                    </Box> 
                </TabCellHeader>
            </TableRow>
            </TableHead>
          <TableBody>
            {this.state.tableData.map((deal: any, index:number) =>(
              <StyledTableRow  key={index}>
                <TabCells style={{borderLeft:'none', cursor: 'pointer'}} data-test-id="deal-id" onClick={() => this.navigateToDealDashboard(deal.id)}> #{deal.id}</TabCells>
                <TabCells>{this.truncateName(deal.customerLastName)}</TabCells>
                <TabCells>{deal.dealership}</TabCells>
                <TabCells>{deal.states}</TabCells>

                <TabCells>{deal.dateCreated}</TabCells>
                <TabCells style={(deal.dateAccepted==='-') ? {textAlign: 'center', color:'#C5CBC9'}:{}}> { deal.dateAccepted }</TabCells>
                <TabCells style={(deal.service==='-')?{textAlign: 'center', color:'#C5CBC9'}:{}}>{this.getService(deal.service)}</TabCells>
                <TabCells> { this.getPrice(deal.service) }</TabCells>
                <TabCells> 
                {deal.serviceProvider && deal.serviceProvider !== "-" ? (deal.serviceProvider.data.attributes.business_name) : (
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                    >
                        <Box style={{display:'flex', alignItems:'center', gap:'5px'}}>
                            <Typography data-test-id='hire-sp' onClick={() => this.hireSP(deal.states, deal.id)} style={{textTransform:'none', fontSize:'16px', color:'#4FC8EC', fontWeight:700, fontFamily:'Roboto'}}> Hire</Typography>
                            <ArrowForwardIosIcon style={{height:'15px', color:'#4FC8EC'}}/>
                        </Box>
                    </Button>
                    )}
                </TabCells>
                <TabCells  style={{borderRight:'none'}}>
                    <Typography style={this.getStatusStyle(deal.status)}>
                        {deal.status}
                    </Typography>
                </TabCells>
              </StyledTableRow>
            ))
            }
            </TableBody>

          </Table>
          </Box>
      </TableContainer>
    )
}  

pendingTable = ()=>{
    return(
        <TableContainer component={Box} style={{ width:'100%',  overflowX: 'auto',}}>
        <Box style={{overflowX: 'auto', width: '100%',  }}>
          <Table  aria-label="sticky table" stickyHeader style={{ overflowX: 'auto'}}>
            <TableHead>
            <TableRow>
              <TabCellHeader style={{borderLeft:'none'}}> Deal ID</TabCellHeader>
              <TabCellHeader> Customer's Last Name </TabCellHeader>
              <TabCellHeader>Dealership </TabCellHeader>
              <TabCellHeader> States</TabCellHeader>
              <TabCellHeader>Date Sent </TabCellHeader>
              <TabCellHeader> Service</TabCellHeader>
              <TabCellHeader> Fees</TabCellHeader>
              <TabCellHeader> Service Provider</TabCellHeader>
              <TabCellHeader style={{borderRight:'none'}}> 
                <Box style={{display:'flex', alignItems:'center'}}>
                    <Typography style={{fontFamily: 'Roboto', fontSize:'14px', color: '#C5CBC9', fontWeight:700, }}>Status</Typography>
                    {this.tootltipStatus()}
                </Box> 
            </TabCellHeader>
            <TabCellHeader>Actions</TabCellHeader>
            </TableRow>
            </TableHead>
          <TableBody>

            {this.state.tablePendingData.map((deal:TablePendingDataRow, index:number) => (
              <StyledTableRow className="tablehover" key={index}>
                <TabCells style={{borderLeft:'none', cursor: 'pointer'}} data-test-id="deal-id" onClick={() => this.navigateToDealDashboard(deal.id)}>#{deal.id}</TabCells>
                <TabCells>{this.truncateName(deal.customerLastName)}</TabCells>
                <TabCells> { deal.dealership} </TabCells>
                <TabCells> { deal.states } </TabCells>
                <TabCells> {deal.dateSent }</TabCells>
                <TabCells>{this.getService(deal.service)}</TabCells>
                <TabCells> {deal.fees}</TabCells>
                <TabCells> { deal.serviceProvider }</TabCells>
                <TabCells  style={{borderRight:'none'}}>
                <Typography style={this.getStatusPendingStyle(deal.status)}>
                    {deal.status}
                </Typography>
                </TabCells>
                <TabCells>
                    <Box style={{display:'flex', alignItems:'center', gap:'30px'}}>
                        <Button data-test-id="withdraw" onClick={() => this.withdrawDeal(deal.id)} disabled={this.checkDisablitiy(deal.status, 'withdraw')} variant="text"><Typography style={{textTransform:'none' , fontSize:'14px', fontWeight:700, fontFamily:'Roboto', color:this.checkDisablitiy(deal.status, 'withdraw') ? '#C5CBC9' : '#013D4F'}}>Withdraw</Typography></Button>
                        <Button data-test-id="hirenew" onClick={() => this.hireSP(deal.states, deal.id)} disabled={this.checkDisablitiy(deal.status, 'hirenew')} variant="text"><Typography style={{textTransform:'none', color:this.checkDisablitiy(deal.status, 'hirenew') ? '#C5CBC9' : '#4FC8EC', fontSize:'14px', fontWeight:700, fontFamily:'Roboto', }}>Hire New</Typography></Button>
                    </Box>
                </TabCells>
              </StyledTableRow>
            ))
            }
            </TableBody>
          </Table>
          </Box>
      </TableContainer>
    )
} 

deletedTable = ()=>{
  const tablevalues = this.state.tableDataDeleted
    return(
        <TableContainer style={{ width:'100%', overflowX: 'auto'}}  component={Box} >
        <Box
         style={{overflowX: 'auto', width: '100%'}}>
          <Table  
          aria-label="sticky table" 
          stickyHeader style={{ overflowX:'auto' }}>
        <TableHead>
            <TableRow>
              <TabCellHeader 
              style={{borderLeft:'none'}}> Deal ID </ TabCellHeader>
              <TabCellHeader> Customer's Last Name</ TabCellHeader>
              <TabCellHeader >Dealership </ TabCellHeader>
              <TabCellHeader> States </ TabCellHeader >
              <  TabCellHeader>Date Created </ TabCellHeader>
              <TabCellHeader> Date Cancelled</ TabCellHeader>
              <TabCellHeader> Service</ TabCellHeader >
              <TabCellHeader>Fees</ TabCellHeader >
              <TabCellHeader> Service Provider </ TabCellHeader>
              <TabCellHeader
               style={{borderRight:'none'}}> 
                <Box style={{display:'flex', alignItems:'center'}}>
                  <Typography style={{fontFamily: 'Roboto',   color: '#C5CBC9',fontWeight:700,fontSize:'14px',}}> Status</Typography>
                  { this.tootltipStatus( )}
                </ Box > 
            </ TabCellHeader >
        </TableRow >

           </TableHead>

          < TableBody >
            {tablevalues.map((deall:any, index:number) => (
              <StyledTableRow 
              key={index }>
              <TabCells style={{borderLeft:'none'}}> {deall.id}</TabCells>
              <TabCells>{this.truncateName(deall.customerLastName)}</TabCells>
              <TabCells>{ deall.dealership} </TabCells>
                    <TabCells>{ deall.states } </TabCells>
               <TabCells>{deall.dateCreated } </TabCells>
                   <TabCells>{deall.dateCancelled}</TabCells>
                <TabCells>{deall.service} </TabCells >
                <TabCells>{ deall.fees }</TabCells>
                <TabCells>{ deall.serviceProvider }</TabCells>
                <TabCells  style={{borderRight:'none'}}>
                { 
                    <Typography style={{color:'#C5CBC9', fontFamily:'Roboto', fontWeight:500, fontSize:'14px'}}>
                        Deleted
                    </Typography>
                }
                </TabCells>
              </StyledTableRow>
            ))
            }
            </TableBody>
          </Table>
          </Box>
      </TableContainer>
    )
}  

completedTable = ()=>{
  const tablevalues = this.state.tableDataCompleted
    return(
        <TableContainer  style={{ width:'100%', overflowX: 'auto',}} component={Box} >
        <Box style={{overflowX: 'auto', width: '100%'  ,}}>
          <Table  aria-label="sticky table" stickyHeader style={{ overflowX:'auto', }}>
        < TableHead>
            <TableRow>
              <TabCellHeader style={{borderLeft:'none'}}> Deal ID </TabCellHeader>
                <TabCellHeader> Customer's Last Name</TabCellHeader>
              <TabCellHeader >Dealership </TabCellHeader>
                <TabCellHeader> States</TabCellHeader >
              <  TabCellHeader>Date Created </TabCellHeader>
              <TabCellHeader> Date Completed</TabCellHeader>
              <TabCellHeader> Service</TabCellHeader >
              <TabCellHeader>Fees</TabCellHeader >
              <TabCellHeader> Service Provider </TabCellHeader>
              <TabCellHeader style={{borderRight:'none',}}> 
                <Box style={{display:'flex', alignItems:'center',}}>
                    <Typography style={{fontFamily: 'Roboto',  fontSize:'14px', color: '#C5CBC9',fontWeight:700, }}> Status</Typography>
                   {this.tootltipStatus()}
                </Box> 
            </TabCellHeader>
        </TableRow>
            </TableHead>
          <TableBody>

            {tablevalues.map((deal:any, index:number) => (
              <StyledTableRow  key={index}>
                <TabCells style={{borderLeft:'none', cursor: 'pointer'}} data-test-id="deal-id" onClick={() => this.navigateToDealDashboard(deal.id)}> #{deal.id}</TabCells>
                <TabCells>{this.truncateName(deal.customerLastName)}</TabCells>
                <TabCells>{ deal.dealership} </TabCells>
                <TabCells>{ deal.states } </TabCells>
                <TabCells>{deal.dateCreated } </TabCells>
                <TabCells>{deal.dateCompleted}</TabCells>
                <TabCells>{this.getService(deal.service)} </TabCells >
                <TabCells>{deal.fees}</TabCells>
                <TabCells>{ deal.serviceProvider }</TabCells>
                <TabCells  style={{borderRight:'none'}}>
                {
                <span style={{border:'1px solid #26BBB3', color:'#26BBB3', fontSize:'14px', padding:'6px 12px', fontWeight:400, fontFamily:'Roboto', borderRadius:'26px'}}>Completed</span>
                }
                </TabCells>
              </StyledTableRow>
            ))
            }
            </TableBody>
          </Table>
          </Box>
      </TableContainer>
    )
} 

draftTable = ()=>{
    return(
        <TableContainer component={Box} style={{ width:'100%',  overflowX: 'auto',}}>
        <Box style={{overflowX: 'auto', width: '100%',  }}>
          <Table  aria-label="sticky table" stickyHeader style={{overflowX:'auto'}}>
            <TableHead>
                <TableRow>
                    <TabCellHeader style={{ borderLeft:'none'}}> Deal ID</TabCellHeader >
                    <TabCellHeader>Customer's Last Name </TabCellHeader>
                    <TabCellHeader>Dealership </TabCellHeader>
                    <TabCellHeader > States</TabCellHeader>
                    <TabCellHeader >Date Created </TabCellHeader>
                    <TabCellHeader > Type of Vehicle</TabCellHeader>
                    <TabCellHeader > Application Type</TabCellHeader>
                    <TabCellHeader style={{borderRight:'none'}}>Actions</TabCellHeader>
                </TableRow>
             </TableHead>
           <TableBody>
            {this.state.tableDataDraft.map((deal:TableDraftDataRow, index:number) => (
              <StyledTableRow className="tablehover"  key={index}>
                <TabCells style={{borderLeft:'none', cursor: 'pointer'}} data-test-id="deal-id"  onClick={() => this.navigateToDealDashboard(deal.id)}> #{deal.id}</TabCells>
                <TabCells>{this.truncateName(deal.customerLastName)}</TabCells>
                < TabCells> { deal.dealership} </TabCells>
                < TabCells> { deal.states } </TabCells>
                < TabCells> {deal.dateCreated }</TabCells>
                <TabCells> { deal.typeOfVehicle }</TabCells>
                <TabCells> { deal.applicationType }</TabCells>
                <TabCells style={{borderRight:'none'}}> 
                    <Button variant="text">
                        <Box style={{display:'flex', alignItems:'center', gap:'5px'}}>
                            <Typography style={{textTransform:'none', fontSize:'16px', color:'#4FC8EC', fontWeight:700, fontFamily:'Roboto'}} onClick={()=> this.continueCreateDeal(deal.id)}> Continue</Typography>
                            <ArrowForwardIosIcon style={{height:'15px', color:'#4FC8EC'}}/>
                        </Box>
                    </Button>
                </TabCells>
              </StyledTableRow>
            ))
            }
            </TableBody>
          </Table>
          </Box>
      </TableContainer>
    )
}


acceptedTableSp = ()=>{
  return(
      <TableContainer component={Box }  style={{ width:'100%', overflowX:'auto',}}>
      <Box style={{overflowX: 'auto', width: '100%' }}>
        <Table aria-label="sticky table" stickyHeader style={{overflowX: 'auto'}}>
          < TableHead>
              <TableRow>
                  <TabCellHeader style={{borderLeft:'none'}}>Deal ID</TabCellHeader>
                  <TabCellHeader>Customer's Last Name </TabCellHeader>
                  <TabCellHeader>Dealership</TabCellHeader>
                  <TabCellHeader>States</TabCellHeader>
              <TabCellHeader>Date Received</TabCellHeader>
              <TabCellHeader>Date Accepted</TabCellHeader>
              <TabCellHeader>Service</TabCellHeader>
              <TabCellHeader>Registration fees</TabCellHeader>
              <TabCellHeader>Payout</TabCellHeader>
              <TabCellHeader style={{ borderRight:'none'}}> 
                  <Box 
                  style={{display:'flex', alignItems:'center'}}>
                      <Typography style={{fontFamily: 'Roboto', color: '#C5CBC9', fontWeight:700, fontSize:'14px'}}>Status</Typography>
                      {this.tootltipStatus()}
                  </Box> 
              </TabCellHeader>
          </TableRow>
          </TableHead>
        <TableBody>
          {this.state.tableDataAcceptedSp.map((deal:TableDataAcceptedSp, index:number) =>(
            <StyledTableRow  key={index}>
              <TabCells style={{borderLeft:'none', cursor: 'pointer'}} data-test-id="deal-id" onClick={() => this.navigateToDealDashboard(deal.id)}> #{deal.id}</TabCells>
              <TabCells>{this.truncateName(deal.customerLastName)}</TabCells>
              <TabCells>{deal.dealership}</TabCells>
              <TabCells>{deal.states}</TabCells>
              <TabCells>{deal.dateReceived}</TabCells>
              <TabCells> { deal.dateAccepted }</TabCells>
              <TabCells>{this.getService(deal.service)}</TabCells>
              <TabCells> {deal.fees}</TabCells>
              <TabCells>{ deal.payout }</TabCells>
              <TabCells  style={{borderRight:'none'}}>
                  <Typography style={this.getStatusStyle(deal.status)}>
                      {deal.status}
                  </Typography>
              </TabCells>
            </StyledTableRow>
          ))
          }
          </TableBody>
        </Table>
        </Box>
    </TableContainer>
  )
} 

pendingTableSp=()=>{
  return(

    <TableContainer style={{ 
     width:'100%', 
     overflowX: 'auto',
     }} 
    component={Box} >
    <Box style={{overflowX: 'auto', width: '100%',  } }>
      <Table  aria-label="sticky table" stickyHeader  style={{ overflowX: 'auto'}}>
        < TableHead >
          <TableRow >
          <TabCellHeader style={{borderLeft:'none'}}> Deal ID</ TabCellHeader>
          <TabCellHeader> Customer's Last Name</TabCellHeader>
          <TabCellHeader >Dealership </ TabCellHeader>
          < TabCellHeader >States</TabCellHeader>
          <TabCellHeader>Date Received </TabCellHeader>
          <TabCellHeader> Service</TabCellHeader>
          <TabCellHeader> Payout</TabCellHeader>
          <TabCellHeader style={{borderRight:'none'}}> 
            <Box style={{display:'flex', alignItems:'center'}}>
                <Typography style={{fontFamily: 'Roboto', color: '#C5CBC9',fontSize:'14px', fontWeight:700, }}>Status</Typography>
                {this.tootltipStatus()}
            </Box> 
        </TabCellHeader>
        <TabCellHeader>Actions</TabCellHeader>
        </TableRow>
        </TableHead>
      <TableBody>

        {this.state.tablePendingDataSp.map((deal:TablePendingDataSp, index:number) => (
          <StyledTableRow className="tablehover" key={index}>
            <TabCells style={{borderLeft:'none', cursor: 'pointer'}} data-test-id="deal-id" > #{deal.id}</TabCells>
            <TabCells>{this.truncateName(deal.customerLastName)}</TabCells>
            <TabCells> { deal.dealership} </TabCells>
            <TabCells> { deal.states } </TabCells>
            <TabCells> {deal.dateReceived }</TabCells>
            <TabCells>{this.getService(deal.service)}</TabCells>
            <TabCells> { deal.payout }</TabCells>
            <TabCells  style={{borderRight:'none'}}>
                <Typography style={{ display: 'inline-block',  backgroundColor:'#FCCC0F',whiteSpace: 'nowrap',color: 'white', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 }}>
                    {deal.status}
                </Typography>
            </TabCells>
            <TabCells>
                <Box style={{display:'flex', alignItems:'center', gap:'30px'}}>
                    <Button onClick={()=>this.acceptOrRejectdeal(deal.selected_service_provider_id, 'accepted')} variant="text"><Typography style={{textTransform:'none' , fontSize:'14px', fontWeight:700, fontFamily:'Roboto', color:'#26BBB3'}}>Accept</Typography></Button>
                    <Button onClick={()=>this.acceptOrRejectdeal(deal.selected_service_provider_id, 'rejected')} variant="text"><Typography style={{textTransform:'none', color: '#C43937', fontSize:'14px', fontWeight:700, fontFamily:'Roboto', }}>Reject</Typography></Button>
                </Box>
            </TabCells>
          </StyledTableRow>
        ))
        }
        </TableBody>
      </Table>
      </Box>
  </TableContainer>
)
}

rejectOrExpiredTableSp=()=>{
  return(
    <TableContainer style={{width:'100%',   overflowX: 'auto',}}  component={ Box }>
    <Box style={{overflowX: 'auto', width: '100%',  } }>
      <Table  aria-label="sticky table"  stickyHeader  style={{ overflowX: 'auto'}}>
        <TableHead >
          < TableRow >
              < TabCellHeader style={{borderLeft:'none'}}> Deal ID</TabCellHeader >
                < TabCellHeader> Customer's Last Name </TabCellHeader >
              < TabCellHeader>  Dealership </TabCellHeader >
              < TabCellHeader>States</TabCellHeader >
          <TabCellHeader>Date Created </TabCellHeader>
          <TabCellHeader>Date Rejected or Expired </TabCellHeader>
          <TabCellHeader> Service</TabCellHeader>
          <TabCellHeader> Payout</TabCellHeader>
          <TabCellHeader style={{borderRight:'none'}}> 
            <Box style={{display:'flex', alignItems:'center'}}>
                <Typography style={{fontFamily: 'Roboto', color: '#C5CBC9',fontSize:'14px', fontWeight:700, }}>Status</Typography>
                {this.tootltipStatus()}
            </Box> 
        </TabCellHeader>
        </TableRow>
        </TableHead>
      <TableBody>

        {this.state.tableRejectedorExpiredDataSp.map((deal:RejectedOrExpiredData, index:number) => (
          <StyledTableRow className="tablehover" key={index}>
            <TabCells style={{borderLeft:'none', cursor: 'pointer'}} data-test-id="deal-id" onClick={() => this.navigateToDealDashboard(deal.id)}> #{deal.id}</TabCells>
            <TabCells>{this.truncateName(deal.customerLastName)}</TabCells>
            <TabCells> { deal.dealership} </TabCells>
            <TabCells> { deal.states } </TabCells>
            <TabCells> {deal.dateCreated }</TabCells>
            <TabCells>  { deal?.dateRejectedOrExpired }</TabCells>
            <TabCells>{this.getService(deal.service)}</TabCells>
            <TabCells>{ deal.payout }</TabCells>
            <TabCells  style={{borderRight:'none'}}>
                <Typography style={{ color: deal.pending_tab_status==='Rejected'? '#F05E22' : '#C5CBC9', fontFamily:'Roboto', fontSize:'14px', fontWeight: 500 }}>
                    {deal.pending_tab_status}
                </Typography>
            </TabCells>
          </StyledTableRow>
        ))
        }
        </TableBody>
      </Table>
      </Box>
  </TableContainer>
)
}

deletedTableSp = ()=>{
  return(
      <TableContainer component={Box} style={{ width:'100%', overflowX: 'auto',}}>
      <Box style={{overflowX: 'auto', width: '100%'  }}>
        <Table  aria-label="sticky table" stickyHeader style={{ overflowX: 'auto', }}>
        <TableHead>
          <TableRow>
              <TabCellHeader style={{borderLeft:'none'}}>Deal ID</ TabCellHeader>
              <TabCellHeader>Customer's Last Name</ TabCellHeader>
              <TabCellHeader>Dealership </ TabCellHeader>
              <TabCellHeader>States</ TabCellHeader >
              <TabCellHeader>Date Created</ TabCellHeader>
            <TabCellHeader>Date Deleted</TabCellHeader>
            <TabCellHeader> Service</TabCellHeader >
            <TabCellHeader>Registration Fees</TabCellHeader >
            <TabCellHeader>Payout </TabCellHeader>
            <TabCellHeader style={{borderRight:'none',}}> 
              <Box style={{display:'flex', alignItems:'center',}}>
                  <Typography style={{fontFamily: 'Roboto', fontSize:'14px', color: '#C5CBC9',fontWeight:700, }}> Status</Typography>
                  {this.tootltipStatus()}
              </Box> 
          </TabCellHeader>
      </TableRow>
          </TableHead>
        <TableBody>

          {this.state.deletedDatatableSp.map((deal:DeletedDatatableSp, index:number) => (
            <StyledTableRow  key={index}>
              <TabCells style={{borderLeft:'none', cursor: 'pointer'}} data-test-id="deal-id" onClick={() => this.navigateToDealDashboard(deal.id)}> #{deal.id}</TabCells>
              <TabCells>{this.truncateName(deal.customerLastName)}</TabCells>
              <TabCells>{ deal.dealership} </TabCells>
              <TabCells>{ deal.states } </TabCells>
              <TabCells>{deal.dateCreated } </TabCells>
              <TabCells>{ deal.dateDeleted }</TabCells>
              <TabCells>{this.getService(deal.service)}</TabCells >
              <TabCells>{deal.fees}</TabCells>
              <TabCells>{ deal.payout }</TabCells>
              <TabCells  style={{borderRight:'none'}}>
                <Typography style={{ display: 'inline-block', whiteSpace: 'nowrap',border:'1px solid #C43937',color: '#C43937', padding: '6px 12px', borderRadius: '26px',fontFamily:'Roboto', fontSize:'14px', fontWeight: 500  }}>
                  {deal.status}
                </Typography>
              </TabCells>
            </StyledTableRow>
          ))
          }
          </TableBody>
        </Table>
        </Box>
    </TableContainer>
  )
}  


tabsHeaders = ()=>{
  if(this.checkRole()){
    return [
      `On Going (${this.state.ongoing_total})`,
      `Pending Hire (${this.state.pending_hire_total})`,
      `Deleted (${this.state.deleted_total})`,
      `Completed (${this.state.completed_total})`,
      `Drafts (${this.state.draft_total})`
  ];
  }
  else{
    return [
      `Accepted (${this.state.accepted_total})`,
      `Pending (${this.state.pending_hire_total})`,
      `Rejected or Expired (${this.state.rejected_or_expired_total})`,
      `Deleted (${this.state.deleted_total})`,
    ]
  }
}

renderOptions = () => {
  const { selectedTabFilter, selectedFilters, filterOptions, currentTab, filterSearchTerm } = this.state;
  if (!selectedFilters[currentTab]) {
    return []; 
  }

  if (!selectedFilters[currentTab][selectedTabFilter]) {
    return [];
  }

  let options = filterOptions[currentTab][selectedTabFilter];

  if (filterSearchTerm) {
    options = options.filter((option:string) =>
      option.toLowerCase().includes(filterSearchTerm.toLowerCase())
    );
  }


  const allSelected = selectedFilters[currentTab][selectedTabFilter].length === options.length;

  if(selectedTabFilter==='dateCreated' || selectedTabFilter==='dateAccepted' || selectedTabFilter==='dateReceived' || selectedTabFilter==='dateRejectedOrExpired' || selectedTabFilter==='dateDeleted'){
    return (<>
    <style>{stylee}</style>
    <style>
      {
        `
         .rs-picker-menu {
          z-index:100;
        }
        .rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
          color:grey;
        }
        `
      }
    </style>
        <Box style={{ fontFamily: 'Gotham', fontSize: '16px', fontWeight: 400, color: '#4B4C4B', marginTop:'10px'}}>Select Date</Box>
       <DateRangePicker
       data-test-id="datepicker"
        value={this.state.dateRange as unknown as undefined}
        onChange={this.handleDateChange}
        placeholder="Select Date"
        showOneCalendar
        ranges={[]}
        format="DD MMM YYYY"
        style={{width:'93%', marginTop:'10px'}}

      />
      </>
    )
  }
  
  return (
    <Box >
      {filterSearchTerm === "" && (
        <FormControlLabel
          data-test-id="midbox"
          control={
            <Checkbox
              checked={allSelected}
              data-test-id="selectall"
              className="selectall"
              onChange={() => this.handleSelectAll(currentTab, selectedTabFilter)}
              color="primary"
              icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: '20px', height: '20px' }} />}
              checkedIcon={<img src={filledCheckbox} alt="Checked" style={{ width: '20px', height: '20px' }} />}
            />
          }
          label={<span style={{ fontFamily: 'Gotham', fontSize: '16px', fontWeight: 400, color: '#4B4C4B' }}>Select all</span>}
        />
      )}

      <Box style={{display:'flex', flexDirection:'column', paddingLeft:'15px'}}>
      {options.map((option:any) => (
      <FormControlLabel
      data-test-id="optionall"
        key={option}
        control={
          <Checkbox
          data-test-id="optionone"
            checked={selectedFilters[currentTab][selectedTabFilter].includes(option)}
            onChange={() => this.handleFilterChange(currentTab,selectedTabFilter, option)}
            color="primary"
            style={{display:'flex', alignSelf:'start', paddingTop:'5px'}}
            icon={<img src={emptyCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
            checkedIcon={<img src={filledCheckbox} alt="Unchecked" style={{ width: "20px", height: "20px" }} />}
          />
        }
        label={<span style={{fontFamily:'Gotham', fontSize:'16px', fontWeight:400, color:'#4B4C4B'}}>{option}</span>}
      />
    ))}
    </Box>
  </Box>
  );
};

renderSummary = () => {
  const { selectedFilters,currentTab } = this.state;
  if (!selectedFilters[currentTab]) {
    return []; 
  }
  const hasSelectedFilters = Object.values(selectedFilters[currentTab]).some((filters:any) => filters.length > 0);
  if (!hasSelectedFilters) {
    return null; 
  }
  return Object.keys(selectedFilters[currentTab]).map((category) => {
    if (selectedFilters[currentTab][category].length > 0) {
      return (
        <Box key={category} style={{ marginBottom: 20 }}>
          <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <Typography  style={{ textTransform: 'capitalize',fontFamily:'Roboto', fontSize:'14px', fontWeight:500, color:'#4B4C4B'}}>
              {this.formatString(category)}
            </Typography>

              <Button data-test-id="chhotaclear" variant="text" onClick={() => this.handleClearCategory(currentTab ,category as keyof FilterOptions)}>
                  <Typography style={{fontSize:'14px', fontFamily:'Roboto', color:'#013D4F', fontWeight:500, textTransform:'none'}}>clear</Typography>
              </Button>
            </Box>
          <Box style={{marginTop:'10px'}}>
            {selectedFilters[currentTab][category].map((item:any) => (
              <Chip
              data-test-id="chip"
                key={item}
                label={<span style={{ color: '#013D4F', fontSize:'12px', fontFamily:'Roboto', fontWeight:500 }}>{item}</span>}
                onDelete={() => this.handleFilterChange(currentTab,category, item)}
                deleteIcon={<CloseIcon style={{color:'#013D4F', height:'15px', marginLeft:'-10px'}}/>}
                style={{ marginRight: 5, marginBottom: 5, backgroundColor:'#E8F8FD'}}
              />
            ))}
          </Box>
        </Box>
      );
    }
    return null; 
  });
};

filterPopOver = ()=>{
  const { anchorEl, selectedTabFilter,filterOptions } = this.state;
  const open = Boolean(anchorEl); 
  const currentFilterOptions = filterOptions[this.state.currentTab] || {};
  const tabs = Object.keys(currentFilterOptions);
  return( 
    <>
    <style>{`
    .MuiPopover-paper{
      overflow-x:auto;
    }
    `}</style>
  <Popover
    open={open}
    anchorEl={anchorEl}
    onClose={this.handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    style={{zIndex:20}}
  >
    <Box style={{ width:'750px', display:'flex', flexDirection:'column', maxHeight:'500px'}}>
      <Box className="topbarr" display={'flex' } alignItems={'center'} style={{borderBottom:'1px solid #F2F3F2'}}>
        <Box style={{ paddingLeft: 20,  flex:1, paddingTop:'15px', paddingBottom:'15px'}}>
          <Typography style={{color:'#013D4F', fontFamily:'Inter', fontWeight:400, fontSize:'16px'}}>Filters</Typography>
        </Box>

          <Divider orientation="vertical" flexItem style={{backgroundColor:'#F2F3F2'}}/>
          
          <Box style={{ paddingLeft: 20,  flex:1, borderRight:'1px solidblack'}}>
            <StyledTextField
              data-test-id="filtersearch"
              variant="standard"
              value={this.state.filterSearchTerm}
              onChange={(event)=>this.handlefilterSearchTerm(event)}
              placeholder={`Search in ${this.formatStringToLowerCase(this.state.selectedTabFilter)}`} 
              size="small"
              InputProps={{
                disableUnderline:true,
                startAdornment: (
                  <SearchIcon style={{marginRight:'7px', color:'#C5CBC9', height:'25px'}}/>
                ),
                }}
              />
          </Box>

            <Divider orientation="vertical" flexItem style={{backgroundColor:'#F2F3F2'}}/>

          <Box style={{ paddingLeft: 20,  flex:1, display:'flex', justifyContent:'end'}}>
              <Button data-test-id="clearall" onClick={this.clearAllFilters} variant="text" style={{marginRight:'10px'}}>
                <Typography style={{color:'#4FC8EC', textTransform:'none', fontWeight:500, fontFamily:'Roboto', fontSize:'16px'}}>clear all</Typography>
              </Button>
          </Box>
      </Box>

      <Box className="threesections" style={{display:'flex'}}>
        <Box style={{ paddingLeft: 20, display: 'flex',flex:1, paddingTop:'10px', overflow:'auto', maxHeight:'350px'}}>
          <List style={{width:'95%'}}>
            {tabs.map((tab) => (
              <ListItem
                button
                data-test-id={`tabFilter-${tab}`}
                selected={selectedTabFilter === tab}
                onClick={() => this.handleTabSwitch(tab)}
                key={tab}
                style={{
                  backgroundColor: selectedTabFilter === tab ? '#EDF5F7' : 'transparent',
                  borderRadius:'5px'
                }}
              >
                <Typography style={{ textTransform: 'capitalize', color:'#4B4C4B', fontWeight:400, fontFamily:'Gotham', fontSize:'16px'}}>
                  {this.formatString(tab)}
                </Typography>
                
                   {this.state.selectedFilters[this.state.currentTab][tab]?.length > 0 && (
                    <Typography
                      variant="body2"
                      style={{
                        marginLeft: '8px',
                        fontFamily: 'Roboto',
                        fontWeight: 600,
                        backgroundColor: '#013D4F', 
                        color: 'white',   
                        borderRadius: '50%',
                        display: 'flex',            
                        alignItems: 'center',       
                        justifyContent: 'center',
                        width: '20px',   
                        height: '20px',
                        fontSize:'14px',
                      }}
                    >
                      {this.state.selectedFilters[this.state.currentTab][tab]?.length}
                    </Typography>
                  )}
              </ListItem>
            ))}
          </List>
        </Box>

          <Divider orientation="vertical" flexItem style={{backgroundColor:'#F2F3F2'}}/>

        <Box style={{ paddingLeft: 20,  flex:1, paddingTop:'10px', overflow:'auto', maxHeight:'350px'}}>
            {this.renderOptions()}
        </Box>

          <Divider orientation="vertical" flexItem style={{backgroundColor:'#F2F3F2'}}/>

        <Box style={{ paddingLeft: 20, flex:1,  paddingTop:'10px', overflow:'auto', maxHeight:'350px'}}>
            {this.renderSummary()}
        </Box>
      </Box>
      
      <Divider style={{backgroundColor:'#F2F3F2'}}/>

      <Box className="bottompart" style={{display:'flex', justifyContent:'end', gap:'15px', padding:'15px'}}>
          <Button data-test-id="cancel" variant="text" onClick={this.handleClose}>
            <Typography style={{color:'#4B4C4B', fontFamily:'Inter', textTransform:'none',fontWeight:700, fontSize:'14px'}}>
              Cancel
            </Typography>
          </Button>

          <Button data-test-id="applyfilter" variant="contained" style={{backgroundColor:'#4FC8EC', borderRadius:'8px', padding:'16px 40px'}} onClick={this.handleApply}>
            <Typography style={{color:'white',textTransform:'none', fontFamily:'Inter', fontWeight:700, fontSize:'16px'}}>
              Apply
            </Typography>
          </Button>
      </Box>
    </Box>
  </Popover>
  </>)
}

tabel = ()=>{
    return(
    <>
     <style>
        {`
          .customTab {
            color: rgba(1, 61, 79, 1); 
            font-size: 16px;
            font-weight: 600;
            font-family:Roboto;
            text-transform:none;
            min-width: auto;
          }

          .Mui-selected.customTab {
            color: rgba(1, 61, 79, 1); 
          }

          .customIndicator {
            background-color:rgba(1, 61, 79, 1);
          }
          .MuiTabScrollButton-root.Mui-disabled{
            display:none;
          }
        `}
      </style>
    <AppBar position="static" color="default" style={{ backgroundColor:'white', boxShadow:'none', marginTop:'20px'}}>
        <Box style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 16px', marginBottom:'-33px'}}>
            <Tabs
                value={this.state.tabvalue}
                data-test-id="tabs"
                onChange={this.handleChange}
                indicatorColor="primary"
                textColor="primary"
                classes={{
                indicator: 'customIndicator', 
                }}
                aria-label="full width tabs example"
                variant="scrollable" 
                scrollButtons="auto"
                >
                {this.tabsHeaders().map((label, index) => (
                <Tab
                    key={index}
                    label={label}
                    {...this.a11yProps(index)}
                    classes={{ root: 'customTab' }}
                    style={{
                        color: this.state.tabvalue === index ? '#013D4F' : '#C5CBC9',
                        margin: '0 20px',
                        fontFamily: 'Roboto', 
                        fontWeight: this.state.tabvalue === index ? 600 : 700, 
                        fontSize:'16px',
                        paddingBottom: '25px'
                    }}
                />
                ))}
            </Tabs>

            <Box style={{ display: 'flex', alignItems: 'start', gap: 1, marginBottom:'10px'}}>
                <Box style={{marginTop:'10px'}}>
                    <StyledTextField
                    variant="standard"
                    value={this.state.searchValue}
                    data-test-id="searchField"
                    style={{width:'177px'}}
                    placeholder="Search in deals"
                    size="small"
                    onChange={this.handleSearch}
                    InputProps={{
                        disableUnderline:true,
                        startAdornment: (
                        <SearchIcon style={{marginRight:'7px', color:'#C5CBC9', height:'25px'}}/>
                    ),
                    }}
                    />
                </Box>
                
                <Box>
                    <FormControl style={{ marginTop:'5px'}}>
                        <Select
                        value={this.state.sortOption}
                        data-test-id="selectoption"
                        onChange={(event) => this.handleSort(event)}
                        input={<Input disableUnderline />}
                        displayEmpty
                        renderValue={(selected:any) => {
                          if (!selected) {
                            return <Box style={{color:"#013D4F", fontSize:'14px', fontWeight:500, marginRight:'5px'}}>Sort by</Box>
                          }
                          return selected; 
                        }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                          PaperProps: {
                            style: {
                              borderRadius: 8,
                              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
                            },
                          },
                        }}
                        IconComponent={(props) => (
                          <ExpandMoreIcon {...props} style={{ color: '#013D4F'}} />
                        )}
                      >
                        {this.getSortOptions(this.state.tabvalue).map((option) => (
                          <MenuItem
                            key={option}
                            value={option}
                            style={{
                              fontSize: '16px',
                              color: '#4B4C4B',
                              padding: '10px 16px',
                              fontFamily:'Roboto',
                              fontWeight:400,
                              backgroundColor: this.state.sortOption === option ? '#F0FAFA' : 'transparent',
                            }}
                            selected={this.state.sortOption === option}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                </Box>
                 

                <IconButton data-test-id="rrrr" aria-label="filter" onClick={(event)=>this.handleClick(event)} style={{backgroundColor:Boolean(this.state.anchorEl) ? '#E8F8FD' : 'white', borderRadius: '11px', marginLeft:'10px'}}>
                    <img src={settingIcon} style={{height:'20px', width:'auto'}}/>
                    {this.filterPopOver()}
                </IconButton>
                
                <style>
               {` .custom-pagination-actions .MuiIconButton-root {
                    padding: 4px; 
                    margin: 0 2px; 
                }`}`
                </style>
                <TablePagination
                data-test-id="pagination"
                style={{
                  marginTop:'-5px',
                  color: '#4B4C4B', 
                  fontSize: '14px',
                  fontWeight:500,
                  fontFamily:'Roboto'
                }}
                rowsPerPageOptions={[]}
                component="div"
                count={this.state.tables[this.state.tabvalue].totalRecords}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.tables[this.state.tabvalue].page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${this.state.tables[this.state.tabvalue].page + 1} of ${Math.ceil(count / this.state.rowsPerPage)}`}
                classes={{
                    actions: 'custom-pagination-actions'
                  }}
                />
          </Box>
        </Box>
    </AppBar>

    <this.TabPanel value={this.state.tabvalue} index={0}>
        {this.checkRole() ? this.ongoingTable() : this.acceptedTableSp()}
    </this.TabPanel>
    <this.TabPanel  value={this.state.tabvalue} index={1}>
        {this.checkRole() ? this.pendingTable() : this.pendingTableSp()}
    </this.TabPanel>
    <this.TabPanel value={this.state.tabvalue} index={2}>
        {this.checkRole() ? this.deletedTable() : this.rejectOrExpiredTableSp()}
    </this.TabPanel>
    <this.TabPanel data-test-id="trackingDetailsTab" value={this.state.tabvalue} index={3}>
        {this.checkRole() ? this.completedTable() : this.deletedTableSp()}
    </this.TabPanel>
    <this.TabPanel value={this.state.tabvalue} index={4}>
        {this.checkRole() && this.draftTable()}
    </this.TabPanel>
    </>
    )
  }
  // Customizable Area End

  render() {
    return (
        // Customizable Area Start
      <Box height={'100vh'} overflow={'auto'} style={{minWidth:'800px'}}>
        <Box className="headerrrr" >
            {this.headerr()}
        </Box>

        {this.state.loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
          >
            <CircularProgress />
          </Box>
        ) :
        <>
        <Grid className="miancontainer" style={{marginLeft:'150px', marginTop:'20px'}}>
            <Box className="title">
                <Typography style={{fontSize:'32px', fontFamily:'Gotham light', color:'#013D4F', fontWeight:300}}>All deals ({this.state.totaldeals})</Typography>
                <Typography style={{fontSize:'18px', fontFamily:'Gotham', color:'#4B4C4B', fontWeight:300}}>All deals with customizable filters to meet your needs.</Typography>
            </Box>

            <Grid className="table" style={{marginTop:'45px'}}>
                {this.tabel()}
            </Grid>
        </Grid>
        </>}
      </Box>
        // Customizable Area End
        );
  }
}

// Customizable Area Start
const StyledTableRow = styled(TableRow)({
    
  });

const TabCellHeader = styled(TableCell)({
    fontFamily: 'Roboto', color: '#C5CBC9', border:'1px solid #F0FAFA', fontWeight:700, fontSize:'14px', backgroundColor:'white'
})
 
const TabCells = styled(TableCell)({
    border:'1px solid #F0FAFA', fontFamily: 'Gotham',color: '#191A19', fontWeight:400, fontSize:'16px'
})
const StyledTextField = styled(TextField)({
    '& input::placeholder': {
      color: '#C5CBC9',
      fontSize: '14px',
      opacity: 1,
      fontWeight:500
    },
    '& input': {
      fontSize: '14px', 
    }
  });
interface Styles {
    [key: string]: any;
  }
  const webStyles: Styles = {
    container: {
    display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "100%",
    },
    headerLeftSide: {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: "0px",
    },
    header: {
        display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      borderBottom: "1px solid #F0FAFA",
      justifyContent: "space-between",
      padding: "12px 32px",
    },
    notificationsButton: {
      display: "flex",
      position: "relative",
      alignItems: "center",
      width: "24px",
      justifyContent: "center",
      height: "24px",
      cursor: "pointer",
    },
    headerRightSide: {display: "flex",
      flexWrap: "nowrap",
      alignItems: "center",
      gap: "24px",
      
    },
    userAvatar: {
      height: "40px",
      borderRadius: "50%",
      width: "40px",
    },
    main: {
      display: "flex",
      position: "relative",
      flexWrap: "nowrap",
    },
  
  }
// Customizable Area End
